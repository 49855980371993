import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import {
	Button,
	MenuItem,
	Paper,
	Select, Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import SaveButton from "../save-button/save-button";


export default class ConnectorFormAzureMedia extends Component {
	state = {
		configuration: this.props.configuration
	}

	componentDidMount() {
		// this.onConnectorPropChange("account_name", "");
    // this.onConnectorPropChange("ad_client_id", "");
    // this.onConnectorPropChange("ad_client_secret", "");
    // this.onConnectorPropChange("ad_tenant_id", "");
    // this.onConnectorPropChange("endpoint", "");
    // this.onConnectorPropChange("resource_group", "");
    // this.onConnectorPropChange("subscription_id", "");
	}

	onConnectorPropChange(prop, val) {
		var configuration = this.state.configuration;
		configuration[prop] = val;

		this.setState({
			"configuration": configuration
		});

		this.props.onChange(configuration);
	}

	render() {
		var configuration = this.state.configuration;
		return <div className="rdp-super-admin-component-connector-form-insights">
			{this.state.configuration &&
				<>
					<TextField
						required
						label="Account Name"
						style={{width: "100%", marginTop: "15px"}}
						value={configuration.account_name || ""}
						onChange={(e) => this.onConnectorPropChange("account_name", e.target.value)}
					/>
          <TextField
						required
						label="AD Client ID"
						style={{width: "100%", marginTop: "15px"}}
						value={configuration.ad_client_id || ""}
						onChange={(e) => this.onConnectorPropChange("ad_client_id", e.target.value)}
					/>
          <TextField
						required
						label="AD Client Secret"
						style={{width: "100%", marginTop: "15px"}}
						value={configuration.ad_client_secret || ""}
						onChange={(e) => this.onConnectorPropChange("ad_client_secret", e.target.value)}
					/>
          <TextField
						required
						label="AD Tenant ID"
						style={{width: "100%", marginTop: "15px"}}
						value={configuration.ad_tenant_id || ""}
						onChange={(e) => this.onConnectorPropChange("ad_tenant_id", e.target.value)}
					/>
          <TextField
						required
						label="Endpoint"
						style={{width: "100%", marginTop: "15px"}}
						value={configuration.endpoint || ""}
						onChange={(e) => this.onConnectorPropChange("endpoint", e.target.value)}
					/>
          <TextField
						required
						label="Resource Group"
						style={{width: "100%", marginTop: "15px"}}
						value={configuration.resource_group || ""}
						onChange={(e) => this.onConnectorPropChange("resource_group", e.target.value)}
					/>
          <TextField
						required
						label="Subscription ID"
						style={{width: "100%", marginTop: "15px"}}
						value={configuration.subscription_id || ""}
						onChange={(e) => this.onConnectorPropChange("subscription_id", e.target.value)}
					/>
					
				</>
			}
		</div>
	}
}