import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import './connector-form-direct-register.css';
import {Autocomplete} from "@mui/material";

export default class ConnectorFormDirectRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "configuration": null,
      "domains": this.props.domains || [],
    }
  }

  componentWillMount() {
    this.normalizeData();
  };

  componentDidMount() {
    this.setDefaultProps();
  }

  normalizeData() {
    var configuration = this.props.configuration;
    if (configuration) {
      configuration.validation_schema = {};

      if (configuration.schema_mail_recovery === null) {
        configuration.schema_mail_recovery = {
          "template_id": null,
          "from_mail": null,
          "from_name": null,
          "sendgrid_api_key": null
        }
      }
      if (configuration.schema_mail_confirm === null) {
        configuration.schema_mail_confirm = {
          "template_id": null,
          "from_mail": null,
          "from_name": null,
          "sendgrid_api_key": null
        }
      }

      this.props.onChange(configuration);

      this.setState({
        "configuration": configuration
      });
    };
  }

  setDefaultProps() {
    const {
      configuration
    } = this.state;
    if (configuration.url_callback === null) {
      let defaultDomain = "https://app.euconquisto.com";
      if (this.state.domains.length > 0) {
        const protocol = this.state.domains[0].domain.split(":")[0] === "https" ? "" : "https";
        defaultDomain = `${protocol}://${this.state.domains[0].domain}`;
      }
      this.onConnectorPropChange("url_callback", defaultDomain);
    }
    if (configuration.schema_mail_recovery.template_id === null) {
      this.onConnectorSubPropChange("schema_mail_recovery", "template_id", "d-336b77aefc2246b8aa82f75358a1c7ec");
    }
    if (configuration.schema_mail_recovery.from_mail === null) {
      this.onConnectorSubPropChange("schema_mail_recovery", "from_mail", "noreply@euconquisto.com");
    }
    if (configuration.schema_mail_recovery.from_name === null) {
      this.onConnectorSubPropChange("schema_mail_recovery", "from_name", "EuConquisto");
    }
    if (configuration.schema_mail_recovery.sendgrid_api_key === null) {
      this.onConnectorSubPropChange("schema_mail_recovery", "sendgrid_api_key", "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");
    }
    if (configuration.schema_mail_confirm.template_id === null) {
      this.onConnectorSubPropChange("schema_mail_confirm", "template_id", "d-4aa7eb65dfca4570bb9f042cd82b3345");
    }
    if (configuration.schema_mail_confirm.from_mail === null) {
      this.onConnectorSubPropChange("schema_mail_confirm", "from_mail", "noreply@euconquisto.com");
    }
    if (configuration.schema_mail_confirm.from_name === null) {
      this.onConnectorSubPropChange("schema_mail_confirm", "from_name", "EuConquisto");
    }
    if (configuration.schema_mail_confirm.sendgrid_api_key === null) {
      this.onConnectorSubPropChange("schema_mail_confirm", "sendgrid_api_key", "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");
    }
  }

  // "type": "UserCommunication_1",
  // "name": "SendGrid",
  // "internal_name": null,
  // "authorized_scopes": "Directory, Project",
  // "authorized_services": "Communication",
  // "service": "Communication",
  // "api_key": "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",
  // "origin_email": "noreply@sdenablement.com",
  // "origin_name": "SD Enablement",
  // "templates": {
  //     "NewUserInProject": "d-0f9cbb9b9f2f40bdb9138cf35369ec82",
  //     "UserResetPassword": "d-caa9e2ee74c14a67a4ce6963a5a43278"
  // },
  // "use_all_user_emails": true,
  // "default_variables": {
  //     "player_url": "https://sdenablement.com"
  // },

  onChange(configuration) {
    this.props.onChange(configuration);
  }

  onConnectorPropChange(prop, val) {
    var configuration = this.state.configuration;
    configuration[prop] = val;

    this.setState({
      "configuration": configuration
    });

    this.onChange(configuration);
  }

  onConnectorSubPropChange(prop, subprop, val) {
    var configuration = this.state.configuration;
    configuration[prop][subprop] = val;

    this.setState({
      "configuration": configuration
    });

    this.onChange(configuration);
  }

  render() {
    var configuration = this.state.configuration;
    return <div className="rdp-super-admin-component-connector-form-direct-register">
      {this.state.configuration &&
        <>
          <Autocomplete
            disablePortal
            id="combo-box-url-callback"
            options={this.state.domains.map(option => ({label: `https://${option.domain}`, domain: `https://${option.domain}`}))}
            sx={{ width: "100%", marginTop: "15px" }}
            onInputChange={(event, newInputValue) => {
              this.onConnectorPropChange("url_callback", newInputValue)
            }}
            renderInput={(params) => <TextField {...params} label="URL de callback" required />}
            value={configuration.url_callback || ""}
            onChange={(e, value) => this.onConnectorPropChange("url_callback",  value ? value.domain : "")}
          />

          <Typography variant="button" display="block" gutterBottom style={{ marginTop: "15px" }}>
            E-mail de confirmação de recuperação de senha
          </Typography>
          <TextField
            required
            id="outlined-required"
            label="ID do template"
            style={{ width: "100%", marginTop: "5px" }}
            value={configuration.schema_mail_recovery && configuration.schema_mail_recovery.template_id}
            onChange={(e) => this.onConnectorSubPropChange("schema_mail_recovery", "template_id", e.target.value)}
          />
          <TextField
            required
            id="outlined-required"
            label="E-mail de saída"
            style={{ width: "100%", marginTop: "15px" }}
            value={configuration.schema_mail_recovery && configuration.schema_mail_recovery.from_mail}
            onChange={(e) => this.onConnectorSubPropChange("schema_mail_recovery", "from_mail", e.target.value)}
          />
          <TextField
            required
            id="outlined-required"
            label="Nome do E-mail"
            style={{ width: "100%", marginTop: "15px" }}
            value={configuration.schema_mail_recovery && configuration.schema_mail_recovery.from_name}
            onChange={(e) => this.onConnectorSubPropChange("schema_mail_recovery", "from_name", e.target.value)}
          />
          <TextField
            required
            id="outlined-required"
            label="Chave de API do SendGrid"
            style={{ width: "100%", marginTop: "15px" }}
            value={configuration.schema_mail_recovery && configuration.schema_mail_recovery.sendgrid_api_key}
            onChange={(e) => this.onConnectorSubPropChange("schema_mail_recovery", "sendgrid_api_key", e.target.value)}
          />

          <Typography variant="button" display="block" gutterBottom style={{ marginTop: "15px" }}>
            E-mail de confirmação de criação de conta
          </Typography>
          <TextField
            required
            id="outlined-required"
            label="ID do template"
            style={{ width: "100%", marginTop: "5px" }}
            value={configuration.schema_mail_confirm && configuration.schema_mail_confirm.template_id}
            onChange={(e) => this.onConnectorSubPropChange("schema_mail_confirm", "template_id", e.target.value)}
          />
          <TextField
            required
            id="outlined-required"
            label="E-mail de saída"
            style={{ width: "100%", marginTop: "15px" }}
            value={configuration.schema_mail_confirm && configuration.schema_mail_confirm.from_mail}
            onChange={(e) => this.onConnectorSubPropChange("schema_mail_confirm", "from_mail", e.target.value)}
          />
          <TextField
            required
            id="outlined-required"
            label="Nome do E-mail"
            style={{ width: "100%", marginTop: "15px" }}
            value={configuration.schema_mail_confirm && configuration.schema_mail_confirm.from_name}
            onChange={(e) => this.onConnectorSubPropChange("schema_mail_confirm", "from_name", e.target.value)}
          />
          <TextField
            required
            id="outlined-required"
            label="Chave de API do SendGrid"
            style={{ width: "100%", marginTop: "15px" }}
            value={configuration.schema_mail_confirm && configuration.schema_mail_confirm.sendgrid_api_key}
            onChange={(e) => this.onConnectorSubPropChange("schema_mail_confirm", "sendgrid_api_key", e.target.value)}
          />
        </>
      }
    </div>
  }
}