import $ from "jquery";
import jwtDecode from 'jwt-decode';

var directoriesService = function () {
  this.list = function () {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/directories`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.directoryInfo = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/directory/uid/${uid}`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.directoryLogin = function (uid, login, password) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/signin/virtual/directory/uid/${uid}`,
        type: "POST",
        dataType: "json",
        data: JSON.stringify({
          login: login,
          password: password,
        }),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.userVirtualLogin = function (uid, login, password) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/signin/virtual/user/uid/${uid}`,
        type: "POST",
        dataType: "json",
        data: JSON.stringify({
          login: login,
          password: password,
        }),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.directoryCreate = function (name) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/directory`,
        type: "POST",
        dataType: "json",
        data: JSON.stringify({
          "name": name,
          "type": "Organization"
        }),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.DirectorySchemaCreate = function (data) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/entity/management/schema/directory`,
        type: "POST",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.ProjectSchemaCreate = function (uid, data) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/entity/schema/project/uid/${uid}`,
        type: "POST",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.SchemaTagDelete = function (uid, data) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/entity/schema/tag/uid/${uid}`,
        type: "DELETE",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.SchemaTagCreate = function (uid, data) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/entity/schema/uid/${uid}/tag`,
        type: "POST",
        cache: false,
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.getDirectorySchemas = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/entity/schema/available/directory/uid/${uid}`,
        type: "GET",
        cache:false,
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.getProjectSchemas = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/entity/schema/available/project/uid/${uid}`,
        type: "GET",
        cache:false,
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.getProjectCourses = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/cms/${window.rdp_api_version}/learning/connector/uid/${uid}/courses/management`,
        type: "GET",
        cache: false,
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.SchemaDelete = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/entity/schema/uid/${uid}`,
        type: "DELETE",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.getSchema = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/entity/schema/uid/${uid}/management`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.getCourse = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/cms/${window.rdp_api_version}/learning/course/uid/${uid}/management`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.updateCourse = function (uid, metadata) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/cms/${window.rdp_api_version}/learning/course/uid/${uid}/management`,
        type: "PUT",
        dataType: "json",
        data: JSON.stringify(metadata),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.directoryDelete = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/directory/uid/${uid}`,
        type: "DELETE",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.directoryUpdateMetadata = function (uid, metadata) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/directory/uid/${uid}`,
        type: "PUT",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(metadata),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.getConnectorsSchema = function () {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/connectors?type=schema_`,
        type: "OPTIONS",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.setDirectoryConnector = function(uid, connector) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/connector/register/directory/uid/${uid}`,
        type: "POST",
        processData: false,
        data: JSON.stringify(connector),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.getConnectorProjects = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/directory/uid/${uid}/projects`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.getDirectoryProjectsWithToken = function (uid, token) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/directory/uid/${uid}/projects`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.getDirectoryProjectCoursesWithToken = function (connectorUid, token) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/cms/${window.rdp_api_version}/learning/connector/uid/${connectorUid}/courses/management`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.exportDirectoryProjectCourseWithToken = function (courseUid, token) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/cms/${window.rdp_api_version}/learning/course/uid/${courseUid}/management/export`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.importDirectoryProjectCourseWithToken = function (connectorUid, formData, token) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/cms/${window.rdp_api_version}/learning/connector/uid/${connectorUid}/management/import`,
        type: "POST",
        data: formData,
        contentType: false,
        processData: false,
        headers: {
          "Authorization": `Bearer ${token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(function (data) {
        resolve(data);
      }, function (error) {
        reject(error);
      });
    });
  }

  this.createDirectoryProject = function(uid, name) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/project/directory/uid/${uid}`,
        type: "POST",
        dataType: "json",
        data: JSON.stringify({
          "name": name
        }),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.updateDirectoryProject = function(projectUid, obj) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/project/uid/${projectUid}/management`,
        type: "PUT",
        dataType: "json",
        data: JSON.stringify(obj),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.getDirectoryUsers = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/users/directory/uid/${uid}`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.getDirectoryFirstUser = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/users/directory/uid/${uid}?page_size=1`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          const user = data.find(user => user.uid === uid);
          resolve(user);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.getDirectoryUser = function (userUid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/user/uid/${userUid}?include_projects=true&include_project_connectors=true&include_directory=true&include_directory_connectors=true&include_properties=true`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.getProjectInfo = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/project/uid/${uid}`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.getProjectInfoWithToken = function (uid, token) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/project/uid/${uid}`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.userUpdate = function(userUid, userData) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/user/uid/${userUid}/management`,
        type: "PUT",
        dataType: "json",
        data: JSON.stringify(userData),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.getRoles = function (uid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/roles`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.setRole = function(userUid, role) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/user/uid/${userUid}/role`,
        type: "POST",
        dataType: "json",
        data: JSON.stringify(role),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.removeRole = function(userUid, roleUid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/user/uid/${userUid}/role/uid/${roleUid}`,
        type: "DELETE",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.setUserProject = function(projectUid, userUid, directoryUid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/project/uid/${projectUid}/register/user/uid/${userUid}/directory/uid/${directoryUid}`,
        type: "POST",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.setProjectConnector = function(uid, connector) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/connector/register/project/uid/${uid}`,
        type: "POST",
        processData: false,
        data: JSON.stringify(connector),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.updateProjectConnector = function(connector) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/connector/uid/${connector.uid}/management`,
        type: "PUT",
        processData: false,
        data: typeof connector.configuration !== "string" ? JSON.stringify(connector.configuration) : connector.configuration,
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.setProjectDomain = function(projectUid, domain) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/project/uid/${projectUid}/domainspec`,
        type: "POST",
        processData: false,
        data: JSON.stringify(domain),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.updateProjectDomain = function(activeDomain, domain) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/project/domainspec/uid/${activeDomain.uid}`,
        type: "PUT",
        processData: false,
        data: JSON.stringify(domain),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.deleteProjectDomain = function(activeDomain) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/project/domainspec/uid/${activeDomain.uid}`,
        type: "DELETE",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.onPublicProject = function(projectUid, userUid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/project/uid/${projectUid}/register/user/uid/${userUid}`,
        type: "POST",
        processData: false,
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.getDirectoryInfo = async function(uid) {
    let credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host))
    let directoryProjects = null;
    let directoryUsers = null;
    let roles = null;
    try {
      let directoryLoginData = await DirectoriesService.directoryLogin(uid, credentials.login, credentials.password);
      window.localStorage.setItem("rdp-super-admin-directory-token-" + window.location.host, JSON.stringify(directoryLoginData));

      let directoryFirstUser = await DirectoriesService.getDirectoryFirstUser(uid);
      let userLoginData = await DirectoriesService.userVirtualLogin(directoryFirstUser.uid, credentials.login, credentials.password);
      window.localStorage.setItem("rdp-super-admin-user-token-" + window.location.host, JSON.stringify(userLoginData));

      directoryProjects = await DirectoriesService.getConnectorProjects(uid);
      directoryUsers = await DirectoriesService.getDirectoryUsers(uid);
      roles = await DirectoriesService.getRoles();
    } catch (e) {
      window.localStorage.removeItem("rdp-super-admin-token-" + window.location.host);
    }

    try {
      function getConnectorName(connector) {
        let str = "";
        if (connector.name) {
          str += connector.name + " - ";
        }
        str += connector.type + " - ";
        str += connector.service;

        return str;
      }

      let directoryInfoData = await DirectoriesService.directoryInfo(uid);
      let connectorsSchema = await DirectoriesService.getConnectorsSchema();

      let directoryConnectors = directoryInfoData.connectors;
      for (let i = 0; i < directoryConnectors.length; i++) {
        directoryConnectors[i].custom_name = getConnectorName(directoryConnectors[i]);
      }

      directoryConnectors = directoryConnectors.sort((a, b) => {
        let nameA = a.custom_name.toLowerCase(), nameB = b.custom_name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      directoryInfoData.connectors = directoryConnectors;

      return {
        "directory": directoryInfoData,
        "connectors_schema": connectorsSchema.filter(item => item.authorized_scopes === "Directory, Project, User" || item.authorized_scopes === "Directory, Project" || item.authorized_scopes === "Directory"),
        "projects": directoryProjects,
        "users": directoryUsers,
        "roles": roles,
      };
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  this.storageSearch = async function(query) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/storage/${window.rdp_api_version}/search${query ? "?" + encodeURI(query) : ""}`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  }

  this.getDirectoryProjectComposerContent = async function(contentUid) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/storage/${window.rdp_api_version}/content?uid=${contentUid}&access_token=${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}&project_key=${window.rdp_project_key}&api_env=${window.localStorage.getItem("rdp-super-admin-environment") || "production"}`,
        type: "GET"
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  return this;
};



var DirectoriesService = new directoriesService();

export { DirectoriesService };
