import React, {Component} from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {DirectoriesService} from "../../services/directories";
import Transition from "../transitions/transition";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {UserService} from "../../services/user";
import Properties from "../properties/properties";

class UserEdit extends Component {
  state = {
    "properties": [],
    "temp_user": null,
    "temp_user_login": null,
    "temp_user_detail_email_principal": null,
    "temp_user_detail_first_name": null,
    "temp_user_detail_last_name": null,
    "temp_user_detail_password": null,
  }

  componentDidMount() {
    this.setState({
      "temp_user": this.props.user,
      "properties": this.props.user.user_properties || [],
    })
  }

  handleRole(checked, role) {
    const userUid = this.state.temp_user.uid;
    this.setState({
      "temp_user": null
    });

    if (checked) {
      this.onUserRemoveRole(role, userUid);
    } else {
      this.onUserAddRole(role, userUid);
    }
  }

  async onUserRemoveRole(role, userUid) {
    var roleUid;
    var userRoles = this.state.temp_user.roles;
    for (var i = 0; i < userRoles.length; i++) {
      if (userRoles[i].type === role.type) {
        roleUid = userRoles[i].uid;
      };
    };

    var userRole = await DirectoriesService.removeRole(userUid, roleUid);

    this.getTempUser(userUid)
  }

  async onUserAddRole(role, userUid) {
    var obj = {
      "type": role.type
    };

    var userRole = await DirectoriesService.setRole(userUid, obj);

    this.getTempUser(userUid)
  }

  async getTempUser(userUid) {
    var userData = await DirectoriesService.getDirectoryUser(userUid);
    this.setState({
      "temp_user": userData
    });
  }

  handleProject(checked, project) {
    var userUid = this.state.temp_user.uid;
    this.setState({
      "temp_user": null
    });
    if (checked) {
      console.log("REMOVE PROJECT")
    } else {
      this.onUserAddProject(project, userUid);
    }
  }

  async onUserAddProject(project, userUid) {
    var userProject = await DirectoriesService.setUserProject(project.uid, userUid, this.props.directory.uid);
    this.getTempUser(userUid)
  }

  checkIfHasProject(project) {
    var has = false;
    var userProjects = this.state.temp_user.projects;

    for (var i = 0; i < userProjects.length; i++) {
      if (project.uid === userProjects[i].uid) {
        has = true;
      }
    }

    return has;
  }

  checkIfHasRole(role) {
    var has = false;
    var userRoles = this.state.temp_user.roles;

    for (var i = 0; i < userRoles.length; i++) {
      if (role.type === userRoles[i].type) {
        has = true;
      }
    }

    return has;
  }

  onTempUserPropChange(prop, val) {
    var obj = {};
    obj[prop] = val;
    this.setState(obj);
  }

  async onTempUserUpdate() {
    var userData = {"detail": {}};
    if (this.state.temp_user_login) userData.login = this.state.temp_user_login;
    if (this.state.temp_user_detail_email_principal) userData.detail.email_principal = this.state.temp_user_detail_email_principal;
    if (this.state.temp_user_detail_first_name) userData.detail.first_name = this.state.temp_user_detail_first_name;
    if (this.state.temp_user_detail_last_name) userData.detail.last_name = this.state.temp_user_detail_last_name;
    if (this.state.temp_user_password) {
      userData.password = this.state.temp_user_password;
      userData.password_confirm = this.state.temp_user_password;
    }

    if (JSON.stringify(userData.detail) === "{}") delete userData.detail;

    if (this.state.properties.length > 0) {
      for (const property of this.state.properties) {
        await UserService.userUpdateProperty(property.uid, property);
      }
    }

    var userUpdate = await DirectoriesService.userUpdate(this.state.temp_user.uid, userData);

    this.props.onClose();
  }

  addProperty = () => {
    this.setState({
      properties: [
        ...this.state.properties,
        {
          name: "",
          regex: "",
          type: "text",
        }
      ]
    });
  }

  removeProperty = async (index) => {
    await UserService.deleteProperty(this.state.properties[index].uid)

    this.setState({
      properties: this.state.properties.filter((_, i) => i !== index)
    });
  }

  setPropertyValue = (value, index) => {
    this.setState({
      properties: this.state.properties.map((property, i) => {
        if (i === index) {
          property.value = value;
        }
        return property;
      })
    })
  }

  onPropertyChange = (e, index) => {
    this.setState({
      properties: this.state.properties.map((property, i) => {
        if (i === index) {
          property[e.target.name] = e.target.value;
        }
        return property;
      })
    })
  }

  saveProperty = async (index) => {
    const property = this.state.properties[index];
    const userUid = this.state.temp_user.uid;

    if (!("value" in property)) {
      property.value = "";
    }

    const prop = await UserService.userCreateProperty(userUid, property);

    this.setState({
      properties: this.state.properties.map((property, i) => {
        if (i === index) {
          property = prop;
        }
        return property;
      })
    })
  }

  render() {
    const { temp_user } = this.state;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogTitle id="scroll-dialog-title">Editar usuário</DialogTitle>
        <DialogContent dividers={true}>
          {temp_user === null &&
            <div style={{ width: "500px", textAlign: "center"}}>
              <CircularProgress disableShrink/>
            </div>
          }
          {temp_user &&
            <FormControl fullWidth style={{ width: "500px" }}>
              <TextField
                required
                id="outlined-required"
                label="Login"
                style={{ width: "100%", marginBottom: "15px" }}
                defaultValue={temp_user.login}
                onChange={(e) => this.onTempUserPropChange("temp_user_login", e.target.value)}
              />
              <TextField
                required
                id="outlined-required"
                label="E-mail"
                style={{ width: "100%", marginBottom: "15px" }}
                defaultValue={temp_user.detail.email_principal}
                onChange={(e) => this.onTempUserPropChange("temp_user_detail_email_principal", e.target.value)}
              />
              <TextField
                required
                id="outlined-required"
                label="Nome"
                style={{ width: "100%", marginBottom: "15px" }}
                defaultValue={temp_user.detail.first_name}
                onChange={(e) => this.onTempUserPropChange("temp_user_detail_first_name", e.target.value)}
              />
              <TextField
                required
                id="outlined-required"
                label="Sobrenome"
                style={{ width: "100%", marginBottom: "15px" }}
                defaultValue={temp_user.detail.last_name}
                onChange={(e) => this.onTempUserPropChange("temp_user_detail_last_name", e.target.value)}
              />
              <TextField
                required
                id="outlined-required"
                label="Senha"
                type="password"
                style={{ width: "100%", marginBottom: "15px" }}
                onChange={(e) => this.onTempUserPropChange("temp_user_password", e.target.value)}
              />

              <Typography component="p">
                Propriedades
              </Typography>
              <Properties
                values={this.state.properties}
                onSetPropertyValue={this.setPropertyValue}
                onPropertyChange={this.onPropertyChange}
                onRemoveProperty={this.removeProperty}
                onSaveProperty={this.saveProperty}
                onAddProperty={this.addProperty}
              />

              <FormLabel component="legend">Projetos</FormLabel>
              <FormGroup>
                {this.props.projects.length > 0 && this.props.projects.map((project, index) => {
                  return <FormControlLabel
                    key={index}
                    label={project.name}
                    control={<Switch checked={this.checkIfHasProject(project)} onChange={(e) => this.handleProject(this.checkIfHasProject(project), project)}/>}
                  />
                })}
              </FormGroup>

              <FormLabel component="legend" style={{marginTop: "10px"}}>
                Permissões
              </FormLabel>
              <FormGroup>
                {this.props.roles.length > 0 && this.props.roles.map((role, index) => {
                  return <FormControlLabel
                    key={index}
                    label={role.type}
                    control={<Switch checked={this.checkIfHasRole(role)} onChange={(e) => this.handleRole(this.checkIfHasRole(role), role)}/>}
                  />
                })}
              </FormGroup>
            </FormControl>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancelar</Button>
          <Button onClick={() => this.onTempUserUpdate()}>Editar usuário</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default UserEdit;