import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from './logo.png';
import './header.css';
import {Chip} from "@mui/material";
import Stack from "@mui/material/Stack";

export default class Header extends Component {
  constructor(props) {
    super(props);
  }

  logout() {
    window.localStorage.removeItem("rdp-super-admin-token-" + window.location.host);
    window.localStorage.removeItem("rdp-super-admin-credentials-" + window.location.host);
    window.localStorage.removeItem("rdp-super-admin-directory-token-" + window.location.host);
    window.location = window.location.origin + window.location.pathname + "#/";
  }

  getEnvironmentName() {
    const environments = {
      "production": "Produção",
      "homolog": "Homologação"
    }
    const environment = window.localStorage.getItem("rdp-super-admin-environment");

    return environments[environment] || "";
  }

  getEnvironmentColor() {
    const environments = {
      "production": "success",
      "homolog": "warning"
    }
    const environment = window.localStorage.getItem("rdp-super-admin-environment");

    return environments[environment] || "";
  }

  render() {
    return <AppBar position="relative">
      <Toolbar sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <img className="header-logo" src={Logo} style={{ width: "180px" }}/>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="column" spacing={1}>
            <Chip
              label={this.getEnvironmentName()}
              color={this.getEnvironmentColor()}
              size="small"
            />
          </Stack>

          <Button
            variant="contained"
            startIcon={<LogoutIcon />}
            onClick={() => this.logout()}
          >
            Logout
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  }
}