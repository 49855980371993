import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './preloader.css';

export default class Preloader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="rdp-super-admin-component-preloader">
        <CircularProgress disableShrink/>
      </div>
    );
  }
}