import React, {Component} from 'react';
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import {NoResults, Preloader} from "../../index";
import {DirectoriesService} from "../../../services/directories";
import {RDPUtils} from "../../../utils";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {DeleteOutlineTwoTone} from "@mui/icons-material";
import List from "@mui/material/List";
import SchemaFormFields from "../../schema-form-fields/schema-form-fields";
import Form from "../../form/form";
import ConfirmDialog from "../../confirm-dialog/confirm-dialog";

class CoursesList extends Component {
	btnSubmitRef = React.createRef();
	state = {
		openSchemaModal: false,
		saving: false,
		fieldValues: {
			tags: []
		},
		items: [],
		loading: false,
		dialog_open: {
			delete_schema: false,
		},
		dialog_data: null,
		dialog_loading: false,
	}

	componentDidMount() {
		this.loadItems().finally(() => this.setState({loading: false}));
	}

	openSchemaModal = () => {
		this.setState({openSchemaModal: true});
	}

	closeSchemaModal = () => {
		this.setState({openSchemaModal: false});
	}

	onCreateSchema = () => {
		if (this.btnSubmitRef.current) {
			this.btnSubmitRef.current.click();
		}
	}

	onFieldChange = (evt) => {
		this.setState({fieldValues: {...this.state.fieldValues, [evt.target.name]: evt.target.value}});
	}

	handleSubmit = async (event, data) => {
		event.preventDefault();

		if (this.state.saving) return;

		this.setState({saving: true});

		data.public = data.public === 'on';

		if (this.props.project) {
			DirectoriesService.ProjectSchemaCreate(this.props.project.uid, data).then(() => {
				this.setState({saving: false});
				this.closeSchemaModal();
				this.loadItems();
			});
		} else if(this.props.directory) {
			DirectoriesService.DirectorySchemaCreate(data).then(() => {
				this.setState({saving: false});
				this.closeSchemaModal();
				this.loadItems();
			});
		}
	}

	loadItems = async () => {
		this.setState({loading: true});

		let items = [];

		if (this.props.project) {
			const leaningConnector = this.props.project.connectors.find(connector => connector.type === 'Learning_1');
			if (leaningConnector) {
				const resp = await DirectoriesService.getProjectCourses(leaningConnector.uid);
				items = resp.result;
			}
		}

		this.setState({items, loading: false});
	}

	handleDeleteSchema = async () => {
		this.setState({dialog_loading: true});
		await DirectoriesService.SchemaDelete(this.state.dialog_data.uid).finally(async () => {
			await this.loadItems();
			this.setState({dialog_loading: false});
			this.handleCloseDialog("delete_schema");
		});
	}

	handleOpenDialog(dialog, dialogData) {
		this.setState({
			"dialog_open": {
				...this.state.dialog_open,
				[dialog]: true,
			},
			"dialog_data": dialogData,
		})
	}

	handleCloseDialog(dialog) {
		this.setState({
			"dialog_open": {
				...this.state.dialog_open,
				[dialog]: false,
			},
			"dialog_data": null,
		})
	}

	getEmptyMessage() {
		if (this.props.project) {
			return "Nenhum esquema cadastrado no projeto."
		} else {
			return "Nenhum esquema cadastrado no diretório."
		}
	}

	render() {
		if (this.state.loading) {
			return (
				<Preloader/>
			)
		}

		return (
			<div>
				{this.state.items.length === 0 &&
					<NoResults message={this.getEmptyMessage()} />
				}

				{this.state.items.length > 0 && (
					<>
						<List className="rdp-super-admin-schemas-sidebar">
							{RDPUtils.orderAlphabetically(this.state.items, "name").map((item) => (
								<ListItem disablePadding key={item.uid}>
									<ListItemButton component="a" href={`/#/directory/${this.props.directory.uid}${this.props.project ? `/project/${this.props.project.uid}` : ""}/course/${item.uid}`}>
										<ListItemText primary={item.title}/>
									</ListItemButton>

									{/*<Button*/}
									{/*	variant="outlined"*/}
									{/*	color="error"*/}
									{/*	onClick={() => this.handleOpenDialog("delete_schema", schema)}*/}
									{/*>*/}
									{/*	<DeleteOutlineTwoTone color="white"/>*/}
									{/*</Button>*/}
								</ListItem>
							))}
						</List>
						{/*<ConfirmDialog*/}
						{/*	open={!!this.state.dialog_open.delete_schema}*/}
						{/*	loading={this.state.dialog_loading}*/}
						{/*	title="Exclusão do esquema"*/}
						{/*	description={`Deseja excluír o esquema ${this.state.dialog_data && this.state.dialog_data.name}?`}*/}
						{/*	onCancel={() => this.handleCloseDialog("delete_schema")}*/}
						{/*	onConfirm={this.handleDeleteSchema}*/}
						{/*/>*/}
					</>
				)}

				{/*<Dialog*/}
				{/*	open={this.state.openSchemaModal}*/}
				{/*	onClose={this.closeSchemaModal}*/}
				{/*	scroll={"paper"}*/}
				{/*	aria-labelledby="scroll-dialog-title"*/}
				{/*	aria-describedby="scroll-dialog-description"*/}
				{/*	maxWidth="lg"*/}
				{/*>*/}
				{/*	<DialogTitle id="scroll-dialog-title">Criar esquema</DialogTitle>*/}
				{/*	<DialogContent dividers>*/}
				{/*		<Form*/}
				{/*			onSubmit={this.handleSubmit}*/}
				{/*			loading={this.state.saving}*/}
				{/*		>*/}
				{/*			<SchemaFormFields*/}
				{/*				values={this.state.fieldValues}*/}
				{/*				onFieldChange={this.onFieldChange}*/}
				{/*			/>*/}

				{/*			<button*/}
				{/*				ref={this.btnSubmitRef}*/}
				{/*				type="submit"*/}
				{/*				style={{display: "none"}}*/}
				{/*			/>*/}
				{/*		</Form>*/}
				{/*	</DialogContent>*/}

				{/*	<DialogActions>*/}
				{/*		<Button*/}
				{/*			onClick={this.closeSchemaModal}*/}
				{/*			disabled={this.state.saving}*/}
				{/*		>*/}
				{/*			Cancelar*/}
				{/*		</Button>*/}

				{/*		<Box sx={{position: 'relative'}}>*/}
				{/*			<Button*/}
				{/*				onClick={this.onCreateSchema}*/}
				{/*				disabled={this.state.saving}*/}
				{/*			>*/}
				{/*				Criar esquema*/}
				{/*			</Button>*/}

				{/*			{this.state.saving && (*/}
				{/*				<CircularProgress*/}
				{/*					size={24}*/}
				{/*					sx={{*/}
				{/*						position: 'absolute',*/}
				{/*						top: '50%',*/}
				{/*						left: '50%',*/}
				{/*						marginTop: '-12px',*/}
				{/*						marginLeft: '-12px',*/}
				{/*						pointerEvents: "none",*/}
				{/*					}}*/}
				{/*				/>*/}
				{/*			)}*/}
				{/*		</Box>*/}
				{/*	</DialogActions>*/}
				{/*</Dialog>*/}

				{/*<Fab*/}
				{/*	onClick={this.openSchemaModal}*/}
				{/*	color="primary"*/}
				{/*	aria-label="add"*/}
				{/*	sx={{*/}
				{/*		position: "fixed",*/}
				{/*		right: "20px",*/}
				{/*		bottom: "20px"*/}
				{/*	}}*/}
				{/*>*/}
				{/*	<AddIcon/>*/}
				{/*</Fab>*/}
			</div>
		);
	}
}

export default CoursesList;