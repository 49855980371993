import React, {Component} from 'react';
import Button from "@mui/material/Button";
import {NoResults, Preloader} from "../../index";
import {DirectoriesService} from "../../../services/directories";
import {RDPUtils} from "../../../utils";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Checkbox from "@mui/material/Checkbox";
import {DeleteOutline} from "@mui/icons-material";
import {Badge, Divider} from "@mui/material";
import Swal from "sweetalert2";

class UsersList extends Component {
  state = {
    saving: false,
    data: [],
    roles: [],
    loading: false,
    selected: [],
    preview: null,
  }

  componentDidMount() {
    this.load().finally(() => this.setState({loading: false}));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.userSelected !== this.props.userSelected) {
      this.load().finally(() => this.setState({loading: false}));
    }
  }

  load = async () => {
    this.setState({loading: true});

    let data = [];

    data = await DirectoriesService.getDirectoryUsers(this.props.directory.uid);

    this.setState({data: data || [], loading: false});
  }

  getEmptyMessage() {
    if (this.props.project) {
      return "Nenhum usuário cadastrado no projeto."
    } else {
      return "Nenhum usuário cadastrado no diretório."
    }
  }

  handleDelete = () => {
    Swal.fire({
      title: `Apagar ${this.state.selected.length} usuário(s)?`,
      input: 'password',
      inputLabel: 'Senha de confirmação:',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Apagar',
      confirmButtonColor: '#d33',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#aaa',
      reverseButtons: true,
      preConfirm: (password) => {
        let credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host) || "{}");
        if (credentials.password !== password) {
          Swal.showValidationMessage(
            `Senha inválida.`
          )
          return false;
        }


        const promises = [];

        for (const selectedElement of this.state.selected) {
          promises.push(
            fetch(`https://api-euconquisto.azure-api.net/auth/v1.0/user/uid/${selectedElement}`, {
              "headers": {
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
                "Project-Key": window.rdp_project_key,
                "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
              },
              "method": "DELETE",
            })
          )
        }

        return Promise.all(promises);
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        this.load().finally(() => this.setState({loading: false, selected: []}));
      }
    })
  }

  handleToggleChecked = (evt) => {
    let selected = [];
    if (evt.target.checked) {
      selected = this.state.data.map(item => item.uid);
      this.setState({selected: selected});
    } else {
      this.setState({selected: []});
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Preloader/>
      )
    }

    return (
      <div>
        {this.state.data.length === 0 &&
          <NoResults message={this.getEmptyMessage()}/>
        }

        {this.state.data.length > 0 && (
          <>
            <List className="rdp-super-admin-compositions-list">
              <ListItem disablePadding>
                <Checkbox
                  inputProps={{'aria-label': 'Checkbox demo'}}
                  checked={this.state.selected.length > 0}
                  indeterminate={this.state.selected.length !== this.state.data.length && this.state.data.length > 0 && this.state.selected.length > 0}
                  onChange={this.handleToggleChecked}
                />

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{mr: "10px"}}
                />

                <ListItemText primary="Nome"/>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{mr: "10px"}}
                />

                <Badge badgeContent={this.state.selected.length} color="primary">
                  <Button
                    variant="outlined"
                    disabled={this.state.selected.length === 0}
                    onClick={this.handleDelete}
                  >
                    <DeleteOutline/>
                  </Button>
                </Badge>
              </ListItem>

              <Divider
                orientation="horizontal"
                flexItem
                sx={{pb: "10px"}}
              />

              {RDPUtils.orderAlphabetically(this.state.data, "login").map((row) => (
                <ListItem disablePadding key={row.uid}>
                  <Checkbox
                    inputProps={{'aria-label': 'Checkbox demo'}}
                    checked={this.state.selected.includes(row.uid)}
                    onChange={(evt) => {
                      if (evt.target.checked) {
                        this.setState({selected: [...this.state.selected, row.uid]});
                      } else {
                        this.setState({selected: this.state.selected.filter(uid => uid !== row.uid)});
                      }
                    }}
                  />

                  <Divider orientation="vertical" flexItem/>

                  <ListItemButton
                    onClick={() => this.props.onClickUser(row)}
                  >
                    <ListItemText primary={row.login || ""}/>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </div>
    );
  }
}

export default UsersList;