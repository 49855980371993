import React, {Component} from 'react';
import * as S from "./image-colors.styled";
import getColorsFromImage from "../../utils/getColorsFromImage";

class ImageColors extends Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();
		this.imageRef = React.createRef();

		this.state = {
			image: this.props.src || "https://pocs.digitalpages.com.br/rdpcomposer/media/head-1/background.png",
			colors: {}
		}

		this.getColors();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.image !== prevState.image) {
			this.getColors();
		}
	}

	getColors = async () => {
		const colors = await getColorsFromImage(this.state.image);
		this.setState({colors});
	}

	handleFileChange = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onload = (event) => {
			this.setState({
				image: event.target.result
			})
		};
		reader.readAsDataURL(file);
	}

	copyToClipboard = (text) => {
		const el = document.createElement('textarea');
		el.value = text;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	}

	render() {
		return (
			<S.Container>
				<S.Input
					ref={this.fileInputRef}
					type="file"
					onChange={this.handleFileChange}
					accept="image/*"
				/>

				<S.ImageContainer>
					<S.Image
						ref={this.imageRef}
						src={this.state.image}
						alt="Imagem"
						onClick={() => this.fileInputRef.current.click()}
					/>
				</S.ImageContainer>

				<S.ColorsList>
					{
						Object.keys(this.state.colors).map((key, index) => {
							return (
								<S.ColorItem key={index}>
									<S.Color
										color={this.state.colors[key].rgb}
										onClick={() => this.copyToClipboard(key)}
									/>
									<S.ColorName>
										{key}
									</S.ColorName>
								</S.ColorItem>
							)
						})
					}
				</S.ColorsList>
			</S.Container>
		);
	}
}

export default ImageColors;