import Box from "@mui/material/Box";
import React from "react";

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			style={{overflowY: 'auto', height: '100%'}}
			{...other}
		>
			{value === index && (
				<Box sx={{p: 3}}>
					{children}
				</Box>
			)}
		</div>
	);
}

export default TabPanel;
