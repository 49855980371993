import React, { Component } from 'react';
import './connector-form-learning.css';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default class ConnectorFormLearning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "configuration": null
    }
  }

  componentWillMount() {
    this.normalizeData();
  };

  normalizeData() {
    var configuration = this.props.configuration;
    if ("ranking_factor" in configuration) {
      delete configuration.ranking_factor;
    }
    this.setState({
      "configuration": configuration
    });
  }

  onConnectorPropChange(prop, val) {
    var configuration = this.state.configuration;
    configuration[prop] = val;

    this.setState({
      "configuration": configuration
    });

    this.props.onChange(configuration);
  }

  render() {
    var configuration = this.state.configuration;
    return <div className="rdp-super-admin-component-connector-form-learning">
      {this.state.configuration &&
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={!!configuration.allow_with_empty_authorizations}
                  onChange={(e) => this.onConnectorPropChange("allow_with_empty_authorizations", e.target.checked)}
                />
              }
              label="Permitir acesso aos recursos sem autorizações configuradas"
            />
          </FormGroup>
        </>
      }
    </div>
  }
}