import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import './connector-form-sendgrid.css';
import {Autocomplete} from "@mui/material";

export default class ConnectorFormSendgrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "configuration": null,
      "domains": this.props.domains || [],
    }
  }

  componentWillMount() {
    this.normalizeData();
  };

  componentDidMount() {
    this.setDefaultProps();
  }

  normalizeData() {
    var configuration = this.props.configuration;
    if (configuration) {
      if (configuration.default_variables === null) {
        configuration.default_variables = {
          "player_url": null
        };
      };

      if (configuration.templates === null) {
        configuration.templates = {
          "NewUserInProject": null,
          "UserResetPassword": null
        };
      };

      this.setState({
        "configuration": configuration
      });
    };
  }

  setDefaultProps() {
    const {
      configuration
    } = this.state;
    if (configuration.api_key === null) {
      this.onConnectorPropChange("api_key", "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");
    }
    if (configuration.origin_name === null) {
      this.onConnectorPropChange("origin_name", "EuConquisto.com");
    }
    if (configuration.origin_email === null) {
      this.onConnectorPropChange("origin_email", "noreply@euconquisto.com");
    }
    if (configuration.templates.NewUserInProject === null) {
      this.onConnectorSubPropChange("templates", "NewUserInProject", "d-4aa7eb65dfca4570bb9f042cd82b3345");
    }
    if (configuration.templates.UserResetPassword === null) {
      this.onConnectorSubPropChange("templates", "UserResetPassword", "d-336b77aefc2246b8aa82f75358a1c7ec");
    }
    if (configuration.default_variables.player_url === null) {
      let defaultDomain = "https://app.euconquisto.com";
      if (this.state.domains.length > 0) {
        const protocol = this.state.domains[0].domain.split(":")[0] === "https" ? "" : "https";
        defaultDomain = `${protocol}://${this.state.domains[0].domain}`;
      }
      this.onConnectorSubPropChange("default_variables", "player_url", defaultDomain);
    }
  }

  // "type": "UserCommunication_1",
  // "name": "SendGrid",
  // "internal_name": null,
  // "authorized_scopes": "Directory, Project",
  // "authorized_services": "Communication",
  // "service": "Communication",
  // "api_key": "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",
  // "origin_email": "noreply@sdenablement.com",
  // "origin_name": "SD Enablement",
  // "templates": {
  //     "NewUserInProject": "d-0f9cbb9b9f2f40bdb9138cf35369ec82",
  //     "UserResetPassword": "d-caa9e2ee74c14a67a4ce6963a5a43278"
  // },
  // "use_all_user_emails": true,
  // "default_variables": {
  //     "player_url": "https://sdenablement.com"
  // },

  onConnectorPropChange(prop, val) {
    var configuration = this.state.configuration;
    configuration[prop] = val;

    this.setState({
      "configuration": configuration
    });

    this.props.onChange(configuration);
  }

  onConnectorSubPropChange(prop, subprop, val) {
    console.log("onConnectorSubPropChange", val);
    var configuration = this.state.configuration;
    configuration[prop][subprop] = val;

    this.setState({
      "configuration": configuration
    });

    this.props.onChange(configuration);
  }

  render() {
    var configuration = this.state.configuration;
    console.log(this.state.domains)
    return <div className="rdp-super-admin-component-connector-form-sendgrid">
      {this.state.configuration &&
        <>
          <TextField
            required
            id="outlined-api-key"
            label="API Key"
            sx={{ width: "100%", marginTop: "15px" }}
            value={configuration.api_key || ""}
            onChange={(e) => this.onConnectorPropChange("api_key", e.target.value)}
          />
          <TextField
            required
            id="outlined-origin-name"
            label="Nome de origem"
            sx={{ width: "100%", marginTop: "15px" }}
            value={configuration.origin_name || ""}
            onChange={(e) => this.onConnectorPropChange("origin_name", e.target.value)}
          />
          <TextField
            required
            id="outlined-origin-email"
            label="E-mail de origem"
            sx={{ width: "100%", marginTop: "15px" }}
            value={configuration.origin_email || ""}
            onChange={(e) => this.onConnectorPropChange("origin_email", e.target.value)}
          />
          <TextField
            required
            id="outlined-templates-new-user-in-project"
            label="ID do template de Boas vindas"
            sx={{ width: "100%", marginTop: "15px" }}
            value={configuration.templates && configuration.templates.NewUserInProject || ""}
            onChange={(e) => this.onConnectorSubPropChange("templates", "NewUserInProject", e.target.value)}
          />
          <TextField
            required
            id="outlined-templates-user-reset-password"
            label="ID do template de Recuperação de senha"
            sx={{ width: "100%", marginTop: "15px" }}
            value={configuration.templates && configuration.templates.UserResetPassword || ""}
            onChange={(e) => this.onConnectorSubPropChange("templates", "UserResetPassword", e.target.value)}
          />
          <Autocomplete
            disablePortal
            id="combo-box-default-variables-player-url"
            options={this.state.domains.map(option => ({label: `https://${option.domain}`, domain: `https://${option.domain}`}))}
            sx={{ width: "100%", marginTop: "15px" }}
            onInputChange={(event, newInputValue) => {
              this.onConnectorSubPropChange("default_variables", "player_url", newInputValue)
            }}
            renderInput={(params) => <TextField {...params} label="URL do App" required />}
            value={configuration.default_variables && configuration.default_variables.player_url || ""}
            onChange={(e, value) => this.onConnectorSubPropChange("default_variables", "player_url", value ? value.domain : "")}
          />
        </>
      }
    </div>
  }
}