import ColorThief from "colorthief/dist/color-thief.mjs";

const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
	const hex = x.toString(16);
	return hex.length === 1 ? '0' + hex : hex
}).join('')

export default async function getColorsFromImage(image) {
	return new Promise(async (resolve, reject) => {
		const imageElement = document.createElement("img");
		imageElement.src = image;
		imageElement.crossOrigin = "Anonymous";
		imageElement.onload = () => {
			const colorThief = new ColorThief();
			const colors = colorThief.getPalette(imageElement, 10);
			const primaryColor = colorThief.getColor(imageElement);
			const colorsObj = {};
			colorsObj[`${rgbToHex(primaryColor[0], primaryColor[1], primaryColor[2])}`] = {
				rgb: `rgb(${primaryColor[0]}, ${primaryColor[1]}, ${primaryColor[2]})`,
				hex: rgbToHex(primaryColor[0], primaryColor[1], primaryColor[2]),
				raw: primaryColor
			};
			colors.forEach((color, index) => {
				colorsObj[`${rgbToHex(color[0], color[1], color[2])}`] = {
					rgb: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
					hex: `${rgbToHex(color[0], color[1], color[2])}`,
					raw: color
				};
			});
			resolve(colorsObj);
		}
	});
}