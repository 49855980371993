import React from 'react';
import List from "@mui/material/List";
import {ListItem, Select} from "@mui/material";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Add, Delete, Save} from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import {MobileDatePicker} from "@mui/x-date-pickers";

function Properties(props) {
  const {
    values = [],
    onSetPropertyValue,
    onPropertyChange = (e, index) => {},
    onRemoveProperty = (index) => {},
    onSaveProperty,
    onAddProperty = () => {},
    editForm = false,
  } = props;

  const timeToString = (time) => {
    return dayjs(time, "HH:mm:ss", null, true).format("HH:mm:ss");
  }

  const dateToString = (time) => {
    return dayjs(time, "MM/DD/YYYY", null, true).format("MM/DD/YYYY");
  }

  const renderProValueField = (property, index) => {
    const types = {
      "Text": (
        <TextField
          label="Valor"
          name="value"
          fullWidth
          defaultValue={property.value || ""}
          onChange={(e) => onSetPropertyValue(e.target.value, index)}
        />
      ),
      "Bool": (
        <FormControlLabel
          fullWidth
          control={
            <Switch
              defaultChecked={property.value === "true"}
              name="value"
              onChange={(e) => onSetPropertyValue(e.target.value, index)}
            />
          }
          label="Valor"
        />
      ),
      "TimeSpan": (
        <>
          <TextField
            name="value"
            fullWidth
            value={timeToString(values[index].value)}
            sx={{display: "none"}}
            onChange={(e) => onSetPropertyValue(e, index)}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              ampm={false}
              openTo="hours"
              views={['hours', 'minutes', 'seconds']}
              inputFormat="HH:mm:ss"
              mask="__:__:__"
              label="Valor"
              value={dayjs(values[index].value, "HH:mm:ss")}
              onChange={(newValue) => onSetPropertyValue(newValue, index)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </>
      ),
      "DateTime": (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TextField
            name="value"
            fullWidth
            value={dateToString(values[index].value)}
            sx={{display: "none"}}
            onChange={(e) => onSetPropertyValue(e, index)}
          />
          <MobileDatePicker
            label="Valor"
            inputFormat="MM/DD/YYYY"
            value={dayjs(values[index].value, "MM/DD/YYYY")}
            onChange={(newValue) => onSetPropertyValue(newValue, index)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )
    }

    return types[values[index].type || property.type] || types["Text"];
  }

  return (
    <List sx={{width: "100%"}}>
      {values.map((property, index) => (
        <ListItem key={index}>
          <Stack spacing={2} direction="row" sx={{width: "100%"}}>
            <FormControl
              fullWidth
              sx={{minWidth: "200px", marginBottom: "15px"}}
            >
              <TextField
                required
                label="Nome"
                fullWidth
                defaultValue={property.name || ""}
                name="name"
                onChange={(e) => onPropertyChange(e, index)}
              />
            </FormControl>

            <FormControl
              fullWidth
              sx={{minWidth: "200px", marginBottom: "15px"}}
            >
              <TextField
                label="Regex"
                name="regex_validation"
                fullWidth
                defaultValue={property.regex_validation || ""}
                onChange={(e) => onPropertyChange(e, index)}
              />
            </FormControl>

            <FormControl
              fullWidth
              sx={{minWidth: "200px", marginBottom: "15px"}}
            >
              <InputLabel id="schema-property-type-label">Tipo</InputLabel>
              <Select
                labelId="schema-property-type-label"
                label="Tipo"
                value={(property.type || "")}
                name="type"
                onChange={(e) => onPropertyChange(e, index)}
              >
                <MenuItem value="Text" defaultValue>Texto</MenuItem>
                <MenuItem value="Bool">Booleano</MenuItem>
                <MenuItem value="DateTime">Data</MenuItem>
                <MenuItem value="TimeSpan">Tempo</MenuItem>
                <MenuItem value="NumberInteger">Número inteiro</MenuItem>
                <MenuItem value="NumberDecimal">Número decimal</MenuItem>
                <MenuItem value="ImageUrl">Imagem</MenuItem>
                <MenuItem value="FileUrl">Arquivo</MenuItem>
              </Select>
            </FormControl>

            {onSetPropertyValue &&
              <FormControl
                fullWidth
                sx={{minWidth: "200px", marginBottom: "15px"}}
              >
                {renderProValueField(property, index)}
              </FormControl>
            }

            <Box sx={{paddingBottom: "15px"}}>
              {!values[index].uid && onSaveProperty ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onSaveProperty(index)}
                  sx={{height: "100%", marginBottom: "15px"}}
                >
                  <Save/>
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => onRemoveProperty(index)}
                  sx={{height: "100%", marginBottom: "15px"}}
                >
                  <Delete/>
                </Button>
              )}
            </Box>
          </Stack>
        </ListItem>
      ))}

      <ListItem>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="success"
            onClick={onAddProperty}
            disabled={editForm}
          >
            <Add/> Adicionar propriedade
          </Button>
        </Stack>
      </ListItem>
    </List>
  );
}

export default Properties;