import React, {Component} from 'react';
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";

class SaveButton extends Component {
	render() {
		return (
			<Box sx={{position: 'relative'}}>
				<Button
					variant={this.props.variant || "contained"}
					startIcon={this.props.startIcon || <SaveIcon/>}
					type={this.props.type || "button"}
					disabled={this.props.loading}
					onClick={this.props.onClick}
				>
					{this.props.children}
				</Button>

				{this.props.loading && (
					<CircularProgress
						size={24}
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							marginTop: '-12px',
							marginLeft: '-12px',
							pointerEvents: "none",
						}}
					/>
				)}
			</Box>
		);
	}
}

export default SaveButton;