import React, {Component} from 'react';
import {Header, Preloader} from "../../components";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {DirectoriesService} from "../../services/directories";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import Form from "../../components/form/form";
import SaveButton from "../../components/save-button/save-button";
import CourseFormFields from "../../components/course-form-fields/course-form-fields";

class ViewCourse extends Component {
	state = {
		directory: null,
		project: null,
		item: null,
		loading: true,
		tab: 0,
		saving: false,
		deletedTags: [],
		createdTags: [],
	}

	componentDidMount() {
		Promise.all([
			this.loadDirectory(),
			this.loadProject(),
			this.loadItem(),
		]).finally(() => this.setState({loading: false}));
	}

	loadDirectory = async () => {
		const resp = await DirectoriesService.getDirectoryInfo(this.props.match.params.directoryUid);
		this.setState({directory: resp.directory});
	}

	loadProject = async () => {
		if (this.props.match.params.projectUid) {
			const resp = await DirectoriesService.getProjectInfo(this.props.match.params.projectUid);
			this.setState({project: resp});
		}
	}

	loadItem = async () => {
		const item = await DirectoriesService.getCourse(this.props.match.params.courseUid);
		this.setState({
			item
		});
	}

	a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	handleChangeTab(event, newValue) {
		this.setState({
			"tab": newValue
		})
	};

	handleSubmit = async (event, values) => {
		event.preventDefault();

		if (this.state.saving) return;

		this.setState({saving: true});

		let metadata = {
			...this.state.item,
		};

		if ("properties" in metadata && "properties" in values) {
			metadata.properties = values.properties.map((property, index) => {
				return {
					...property,
					value: property.type === "Bool" ? String(property.value === "on") : property.value,
				}
			});
		}


		const resp = await DirectoriesService.updateCourse(this.props.match.params.courseUid, metadata);

		this.setState({saving: false, item: resp});
	}

	render() {
		if (this.state.loading) {
			return (
				<Preloader/>
			)
		}

		return (
			<div>
				<Header/>

				<div className="rdp-super-admin-breadcrumb">
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							href="/#/directories"
						>
							Diretórios
						</Link>

						<Link
							underline="hover"
							color="inherit"
							href={`/#/directory/${this.props.match.params.directoryUid}`}
						>
							{this.state.directory ? `Diretório: ${this.state.directory.name}` : "Carregando..."}
						</Link>

						{this.state.project && (
							<Link
								underline="hover"
								color="inherit"
								href={`/#/directory/${this.props.match.params.directoryUid}/project/${this.props.match.params.projectUid}`}
							>
								{this.state.project ? `Projeto: ${this.state.project.name}` : "Carregando..."}
							</Link>
						)}

						<Typography
							color="text.primary">{this.state.item ? `Curso: ${this.state.item.title}` : "Carregando..."}</Typography>
					</Breadcrumbs>
				</div>

				<div className="rdp-super-admin-content">
					{this.state.item &&
						<div className="rdp-super-admin-main">
							<Box sx={{width: '100%'}}>
								<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
									<Tabs value={this.state.tab} onChange={this.handleChangeTab} aria-label="Abas do curso">
										<Tab label="Metadados" {...this.a11yProps(0)} />
									</Tabs>
								</Box>
							</Box>

							<div className="rdp-super-admin-tab-content">
								<div style={{marginTop: "30px"}}>
									{this.state.tab === 0 && (
										<>
											<Form
												onSubmit={this.handleSubmit}
												loading={this.state.saving}
											>
												<CourseFormFields
													values={this.state.item}
												/>

												<Stack direction="row" spacing={2} sx={{mt: "20px"}}>
													<SaveButton
														type="submit"
														loading={this.state.saving}
													>
														Salvar metadados
													</SaveButton>
												</Stack>
											</Form>
										</>
									)}
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		);
	}
}

export default ViewCourse;