export default function setConnectorsNames(connectors) {
  for (var i = 0; i < connectors.length; i++) {
    var label = "";
    var temp = [];
    var name = connectors[i].configuration || connectors[i].class;
    if (name.search("ConnectorAzureMedia_1") > -1) {
      label = "Azure Media"
    } else if (name.search("ConnectorBundleApp") > -1) {
      label = "Bundle App";
    } else if (name.search("ConnectorBundleManager") > -1) {
      label = "Bundle Manager";
    } else if (name.search("ConnectorCustomization_1") > -1) {
      label = "Customização visual";
    } else if (name.search("ConnectorDirectRegister_1") > -1) {
      label = "SendGrid para cadastro";
    } else if (name.search("ConnectorDynamics") > -1) {
      label = "Dynamics";
    } else if (name.search("ConnectorForum_1") > -1) {
      label = "Fórum";
    } else if (name.search("ConnectorGuides") > -1) {
      label = "Guides";
    } else if (name.search("UserInsights_1") > -1) {
      label = "Application Insights";
    } else if (name.search("ConnectorIot_1") > -1) {
      label = "IOT";
    } else if (name.search("ConnectorLearning_1") > -1) {
      label = "Learning";
    } else if (name.search("ConnectorManager_v1") > -1) {
      label = "Manager";
    } else if (name.search("ConnectorMicrosoft_v1") > -1) {
      label = "Microsoft";
    } else if (name.search("ConnectorNotification_1") > -1) {
      label = "Notificações";
    } else if (name.search("ConnectorPagarMe_1") > -1) {
      label = "Pagar.me";
    } else if (name.search("ConnectorReview_1") > -1) {
      label = "Revisão de conteúdos";
    } else if (name.search("ConnectorScorm_1") > -1) {
      label = "Scorm";
    } else if (name.search("ConnectorSegment_1") > -1) {
      label = "Segment";
    } else if (name.search("ConnectorSendGrid_1") > -1) {
      label = "Sendgrid";
    } else if (name.search("ConnectorServerProxy_v1") > -1) {
      label = "Server Proxy";
    } else if (name.search("ConnectorSr3_1") > -1) {
      label = "SR3";
    } else if (name.search("ConnectorSystemMessage") > -1) {
      label = "Mensagens do sistema";
    } else if (name.search("ConnectorTaxonomics_v1") > -1) {
      label = "Taxonomias";
    } else if (name.search("ConnectorUserData_1") > -1) {
      label = "Dados do usuário";
    } else if (name.search("ConnectorWebPack_1") > -1) {
      label = "Extração ZIP p/ HTML";
    } else if (name.search("CustomConnector.Petrobras.Offshore") > -1) {
      label = "Petrobras Offshore";
    } else if (name.search("CustomConnector.Petrobras.Revap2") > -1) {
      label = "Petrobras Revap";
    } else if (name.search("ConnectorComposer") > -1) {
      label = "Composer";
    } else if (name.search("ConnectorDam_v1") > -1) {
      label = "Dam";
    } else if (name.search("RecoveryAccessData_1") > -1) {
      label = "Recuperação de acesso";
    } else if (name.search("ReCaptcha_1") > -1) {
      label = "reCaptcha V3";
    } else if (name.search("ConnectorPolicies_1") > -1) {
      label = "Termos de aceite";
    } else if (name.search("WorkflowConnector") > -1) {
      label = "Workflow";
    } else if (name.search("VideoIndexer") > -1) {
      label = "Video Indexer";
    } else {
      temp.push(name);
      console.log(temp)
    }

    connectors[i].label = label;
  };

  return connectors;
}
