import styled, {css} from 'styled-components';

export const Container = styled.div`
	height: 100%;
	overflow: hidden;
	padding-bottom: 50px;
`;

export const Img = styled.img`
	width: 100%;
	height: auto;
	margin: 5px;
	
	${props => props.isLoading && css`
		opacity: 0;
		width: 0;
		height: 0;
		overflow: hidden;
		padding: 0;
		margin: 0;
	`}
`;

export const SvgNoImage = styled.svg`
	color: grey;
`;

export const ImageLabel = styled.p`
  position: absolute;
  top: -12px;
  left: 10px;
  width: auto;
  background-color: white;
  height: 12px;
  padding: 0 5px;
  text-transform: none;
  color: gray;
`;

export const ColorPickerContainer = styled.div`
	position: absolute;
	width: 100%;
	height: auto;
	right: 0;
	top: 0;
	display: flex;
	justify-content: flex-end;
	pointer-events: all;
	
	.sketch-picker:before {
		content: '';
		position: absolute;
		top: 17px;
		right: 70px;
		height: 20px;
		width: 20px;
		background: white;
		border: 1px solid rgba(0, 0, 0, 0.23);
		border-bottom: 0;
		border-left: 0;
		transform: rotate(45deg);
	}
`;

export const ColorPickerFocus = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
`;
