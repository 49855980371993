import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";

function start() {
  ReactDOM.render((
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <App/>
      </Router>
    </ThemeProvider>
  ), document.getElementById('root'));
}

async function preStart() {
  const response = await fetch("./api_context.json");
  const config = await response.json();
  window.rdp_project_key = config.project_key;
  window.rdp_api_env = config.api_env;
  window.rdp_domain = config.domain;
  window.rdp_api_version = "v1.0";

  start();
}

if (["http:", "https:"].includes(window.location.protocol)) {
  preStart();
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
