import React, {Component} from 'react';
import {Header, Preloader} from "../../components";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {DirectoriesService} from "../../services/directories";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SchemaFormFields from "../../components/schema-form-fields/schema-form-fields";
import Stack from "@mui/material/Stack";
import Form from "../../components/form/form";
import SaveButton from "../../components/save-button/save-button";

class ViewSchema extends Component {
	state = {
		directory: null,
		project: null,
		schema: null,
		loading: true,
		tab: 0,
		saving: false,
		deletedTags: [],
		createdTags: [],
	}

	componentDidMount() {
		Promise.all([
			this.loadDirectory(),
			this.loadProject(),
			this.loadSchema(),
		]).finally(() => this.setState({loading: false}));
	}

	loadDirectory = async () => {
		const resp = await DirectoriesService.getDirectoryInfo(this.props.match.params.directoryUid);
		this.setState({directory: resp.directory});
	}

	loadProject = async () => {
		if (this.props.match.params.projectUid) {
			const resp = await DirectoriesService.getProjectInfo(this.props.match.params.projectUid);
			this.setState({project: resp});
		}
	}

	loadSchema = async () => {
		const schema = await DirectoriesService.getSchema(this.props.match.params.schemaUid);
		this.setState({
			schema
		});
	}

	a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	handleChangeTab(event, newValue) {
		this.setState({
			"tab": newValue
		})
	};

	handleSubmit = async (event, values) => {
		event.preventDefault();

		if (this.state.saving) return;

		this.setState({saving: true});

		// TODO: não possui endpoint
		// await DirectoriesService.schemaUpdate(this.props.match.params.schemaUid, values);

		if (this.state.deletedTags.length > 0) {
			this.state.deletedTags.forEach((tag) => {
				if (tag.uid)
					DirectoriesService.SchemaTagDelete(tag.uid, tag).then(() => {
						this.setState({
							deletedTags: this.state.deletedTags.filter((t) => t.uid !== tag.uid),
						})
					});
			})
		}

		if (this.state.createdTags.length > 0) {
			this.state.createdTags.forEach((tag) => {
				if (tag) DirectoriesService.SchemaTagCreate(this.state.schema.uid, tag).then(() => {
					this.setState({
						createdTags: [],
					}, () => this.loadSchema());
				});
			})
		}

		this.setState({saving: false});
	}

	setDeletedTag = (tag) => {
		this.setState({
			deletedTags: [...this.state.deletedTags, tag]
		});
	}

	setCreatedTag = (tag) => {
		this.setState({
			createdTags: [...this.state.createdTags, tag]
		});
	}

	render() {
		if (this.state.loading) {
			return (
				<Preloader/>
			)
		}

		return (
			<div>
				<Header/>

				<div className="rdp-super-admin-breadcrumb">
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							href="/#/directories"
						>
							Diretórios
						</Link>

						<Link
							underline="hover"
							color="inherit"
							href={`/#/directory/${this.props.match.params.directoryUid}`}
						>
							{this.state.directory ? `Diretório: ${this.state.directory.name}` : "Carregando..."}
						</Link>

						{this.state.project && (
							<Link
								underline="hover"
								color="inherit"
								href={`/#/directory/${this.props.match.params.directoryUid}/project/${this.props.match.params.projectUid}`}
							>
								{this.state.project ? `Projeto: ${this.state.project.name}` : "Carregando..."}
							</Link>
						)}

						<Typography
							color="text.primary">{this.state.schema ? `Esquema: ${this.state.schema.name}` : "Carregando..."}</Typography>
					</Breadcrumbs>
				</div>

				<div className="rdp-super-admin-content">
					{this.state.schema &&
						<div className="rdp-super-admin-main">
							<Box sx={{width: '100%'}}>
								<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
									<Tabs value={this.state.tab} onChange={this.handleChangeTab} aria-label="Abas do esquema">
										<Tab label="Metadados" {...this.a11yProps(0)} />
									</Tabs>
								</Box>
							</Box>

							<div className="rdp-super-admin-tab-content">
								<div style={{marginTop: "30px"}}>
									{this.state.tab === 0 && (
										<>
											<Form
												onSubmit={this.handleSubmit}
												loading={this.state.saving}
											>
												<SchemaFormFields
													values={this.state.schema}
													onDeleteTag={this.setDeletedTag}
													onCreateTag={this.setCreatedTag}
													editForm
												/>

												<Stack direction="row" spacing={2} sx={{mt: "20px"}}>
													<SaveButton
														type="submit"
														loading={this.state.saving}
													>
														Salvar metadados
													</SaveButton>
												</Stack>
											</Form>
										</>
									)}
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		);
	}
}

export default ViewSchema;