import React, {Component} from 'react';
import Box from "@mui/material/Box";

class Form extends Component {
	handleSubmit = async (event) => {
		event.preventDefault();

		let data = this.getFormValues(event.currentTarget);

		if (this.props.onSubmit) {
			this.props.onSubmit(event, data);
		}
	}

	getFormValues = (form) => {
		const formData = new FormData(form);
		const data = {};

		for (const [key, value] of formData.entries()) {
			if (this.isArrayKeyWithIndex(key)) {
				const {propertyKeyName, propertyKeyIndex, valueKeyName} = this.getKeyValues(key);

				data[propertyKeyName] = data[propertyKeyName] || [];
				data[propertyKeyName][propertyKeyIndex] = data[propertyKeyName][propertyKeyIndex] || {};
				data[propertyKeyName][propertyKeyIndex][valueKeyName] = value;
			} else {
				data[key] = value;
			}
		}

		return data;
	}

	getKeyValues = (key) => {
		const propertyKeyName = key.split("[")[0];
		const propertyKeyIndex = key.split("[")[1].split("]")[0];
		const valueKeyName = key.split("[")[2].split("]")[0];

		return {
			propertyKeyName,
			propertyKeyIndex,
			valueKeyName
		}
	}

	isArrayKeyWithIndex = (key) => {
		return key.match(/\[\d+\]/g) !== null;
	}

	render() {
		return (
			<form onSubmit={this.handleSubmit} style={{position: "relative"}}>
				{this.props.children}
				{this.props.loading && (
					<Box
						sx={{
							position: "absolute",
							left: 0,
							top: 0,
							right: 0,
							bottom: 0,
							backgroundColor: "rgba(255,255,255,0.63)",
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
					/>
				)}
			</form>
		);
	}
}

export default Form;