import React, {Component} from 'react';
import {Header} from "../../components";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ImageColors from "../../components/image-colors/image-colors";

class ViewImageColors extends Component {
	render() {
		return (
			<div>
				<Header/>

				<div className="rdp-super-admin-breadcrumb">
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							underline="hover"
							color="inherit"
							href="/#/image-colors"
						>
							Cor da imagem
						</Link>
					</Breadcrumbs>
				</div>

				<div className="rdp-super-admin-content">
					<ImageColors/>
				</div>
			</div>
		);
	}
}

export default ViewImageColors;