import React, {Component} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import {DirectoriesService} from "../../services/directories";
import './migration.css';

import {Header, Preloader} from '../../components';

export default class ViewMigration extends Component {
  constructor(props) {
    super(props);

    this.onDirectoryFirstChange = this.onDirectoryFirstChange.bind(this);
    this.onDirectorySecondChange = this.onDirectorySecondChange.bind(this);
    this.onDirectoryFirstProjectChange = this.onDirectoryFirstProjectChange.bind(this);
    this.onDirectorySecondProjectChange = this.onDirectorySecondProjectChange.bind(this);

    this.state = {
      "directories": null,
      "directory_first": null,
      "directory_second": null,
      "directory_first_token": null,
      "directory_second_token": null,
      "directory_first_preloader": false,
      "directory_second_preloader": false,
      "directory_first_projects": null,
      "directory_second_projects": null,
      "directory_first_active_project": null,
      "directory_second_active_project": null,
      "directory_first_current_project_courses": null,
      "directory_second_active_project_object": null
    }
  }

  componentWillMount() {
    this.redirectToRoute();
    this.getDirectories();
  }

  redirectToRoute() {
    var session = window.localStorage.getItem("rdp-super-admin-token-" + window.location.host);
    if (session === null) {
      window.location = window.location.origin + window.location.pathname + "#/";
    }
  }

  getDirectories() {
    var _this = this;
    DirectoriesService.list().then(function (directories) {
      _this.setState({
        "directories": directories
      });
    }, function(error) {
      window.localStorage.removeItem("rdp-super-admin-token-" + window.location.host);
      _this.redirectToRoute();
    });
  }

  async onDirectoryFirstChange(e) {
    var directoryUid = e.target.value;

    this.setState({
      "directory_first": directoryUid,
      "directory_first_preloader": true
    });

    var credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host))
    var directoryLoginData = await DirectoriesService.directoryLogin(directoryUid, credentials.login, credentials.password);
    var directoryProjects = await DirectoriesService.getDirectoryProjectsWithToken(directoryUid, directoryLoginData.access_token);

    this.setState({
      "directory_first_token": directoryLoginData.access_token,
      "directory_first_projects": directoryProjects,
      "directory_first_preloader": false
    });
  }

  async onDirectorySecondChange(e) {
    var directoryUid = e.target.value;

    this.setState({
      "directory_second": directoryUid,
      "directory_second_preloader": true
    });

    var credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host))
    var directoryLoginData = await DirectoriesService.directoryLogin(directoryUid, credentials.login, credentials.password);
    var directoryProjects = await DirectoriesService.getDirectoryProjectsWithToken(directoryUid, directoryLoginData.access_token);

    this.setState({
      "directory_second_token": directoryLoginData.access_token,
      "directory_second_projects": directoryProjects,
      "directory_second_preloader": false
    });
  }

  async onDirectoryFirstProjectChange(e) {
    this.setState({
      "directory_first_active_project": e.target.value
    });

    var project = await DirectoriesService.getProjectInfoWithToken(e.target.value, this.state.directory_first_token);
    var connector = project.connectors.find(obj => obj.type === "Learning_1");
    var courses = await DirectoriesService.getDirectoryProjectCoursesWithToken(connector.uid, this.state.directory_first_token)
    
    this.setState({
      "directory_first_current_project_courses": courses.result
    })
    console.log(courses)
  }

  async onDirectorySecondProjectChange(e) {
    this.setState({
      "directory_second_active_project": e.target.value
    });

    var project = await DirectoriesService.getProjectInfoWithToken(e.target.value, this.state.directory_first_token);
    
    this.setState({
      "directory_second_active_project_object": project
    });

    console.log(project)
  }

  async copyCourse() {
    var course = this.state.directory_first_current_project_courses[0];
    var exportCourse = await DirectoriesService.exportDirectoryProjectCourseWithToken(course.uid, this.state.directory_first_token)
    console.log(exportCourse)

    var fd = new FormData();
    var file = new Blob([exportCourse], {type: 'application/zip'});
    fd.append("course_v1.zip", file, "course_v1.zip");
    
    var connector = this.state.directory_second_active_project_object.connectors.find(obj => obj.type === "Learning_1");
    var importCourse = await DirectoriesService.importDirectoryProjectCourseWithToken(connector.uid, fd, this.state.directory_second_token)

    // "directory_first": null,
    // "directory_second": null,
    // "directory_first_token": null,
    // "directory_second_token": null,
    // "directory_first_preloader": false,
    // "directory_second_preloader": false,
    // "directory_first_projects": null,
    // "directory_second_projects": null,
    // "directory_first_active_project": null,
    // "directory_second_active_project": null,
    // "directory_first_current_project_courses": null
  }

  render() {
    const {
      directories,
      directory_first_preloader,
      directory_second_preloader,
      directory_first_projects,
      directory_second_projects,
      directory_first_active_project,
      directory_second_active_project,
      directory_first_current_project_courses
    } = this.state;

    return (
      <>
        <Header/>

        <div className="rdp-super-admin-breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/#/directories"
            >
              Migração de Cursos
            </Link>
          </Breadcrumbs>
        </div>

        <div className="rdp-super-admin-content">
          {directories === null && <Preloader/>}
          {directories &&
            <>
              <div className="rdp-super-admin-migration-column first">
                {directory_first_preloader &&
                  <div className="rdp-super-admin-migration-column-preloader">
                    <CircularProgress disableShrink/>
                  </div>
                }

                <Typography color="#000" style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginBottom: "15px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#8e36b7"
                }}>Origem</Typography>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Diretório</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.directory_first}
                    label="Diretório"
                    onChange={this.onDirectoryFirstChange}
                  >
                    {directories.map(function(directory, index) {
                      return <MenuItem key={index} value={directory.uid}>{directory.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>

                {directory_first_projects &&
                  <>
                    <FormControl fullWidth style={{marginTop: "20px"}}>
                      <InputLabel id="demo-simple-select-label">Projeto</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={directory_first_active_project}
                        label="Projeto"
                        onChange={this.onDirectoryFirstProjectChange}
                      >
                        {directory_first_projects.map(function(project, index) {
                          return <MenuItem key={index} value={project.uid}>{project.name}</MenuItem>
                        })}
                      </Select>
                    </FormControl>

                    {directory_first_active_project &&
                      <FormGroup aria-label="position" row style={{marginTop: "20px"}}>
                        <FormControlLabel
                          value="start"
                          control={<Switch color="primary" />}
                          label="Migrar estrutura do curso"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="start"
                          control={<Switch color="primary" />}
                          label="Migrar composições"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    }

                    {directory_first_current_project_courses &&
                      <FormGroup style={{
                        marginTop: "20px",
                        height: "280px",
                        border: "1px solid #c4c4c4",
                        overflowX: "hidden",
                        overflowY: "auto",
                        borderRadius: "4px",
                        padding: "15px",
                        display: "block"
                      }}>
                        {directory_first_current_project_courses.map(function(course, index) {
                          return <FormControlLabel style={{display: "block"}} key={index} control={<Checkbox/>} label={course.title} />
                        })}
                      </FormGroup>
                    }
                  </>
                }

                <Button variant="contained" style={{
                  display: "block",
                  width: "100%",
                  marginTop: "20px"
                }} onClick={() => this.copyCourse()}>
                  Executar cópia
                </Button>
              </div>

              <span className="rdp-super-admin-migration-icon-right"></span>

              <div className="rdp-super-admin-migration-column second">
                {directory_second_preloader &&
                  <div className="rdp-super-admin-migration-column-preloader">
                    <CircularProgress disableShrink/>
                  </div>
                }

                <Typography color="#000" style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginBottom: "15px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#8e36b7"
                }}>Destino</Typography>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Diretório</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.directory_second}
                    label="Diretório"
                    onChange={this.onDirectorySecondChange}
                  >
                    {directories.map(function(directory, index) {
                      return <MenuItem key={index} value={directory.uid}>{directory.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>

                {directory_second_projects &&
                  <FormControl fullWidth style={{marginTop: "20px"}}>
                    <InputLabel id="demo-simple-select-label">Projeto</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={directory_second_active_project}
                      label="Projeto"
                      onChange={this.onDirectorySecondProjectChange}
                    >
                      {directory_second_projects.map(function(project, index) {
                        return <MenuItem key={index} value={project.uid}>{project.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                }
              </div>
            </>
          }

        </div>
      </>
    )
  }
}
