import React, {Component} from 'react';
import {
	Button,
	MenuItem,
	Paper,
	Select, Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";


export default class ConnectorFormRecoveryAccess extends Component {
	state = {
		configuration: this.props.configuration,
		selectedRecoveryFieldType: null,
		recovery_fields_types: [
			{
				name: "E-Mail",
				value: "UserDetailEmail"
			},
			{
				name: "CPF",
				value: "UserDetailCpf"
			},
			{
				name: "Telefone",
				value: "UserDetailPhone"
			},
			{
				name: "Data de nascimento",
				value: "UserDetailBirthday"
			},
			{
				name: "RG",
				value: "UserDetailRg"
			},
			{
				name: "CNH",
				value: "UserDetailCnh"
			},
			{
				name: "Primeiro nome",
				value: "UserDetailFirstName"
			},
			{
				name: "Último nome",
				value: "UserDetailLastName"
			},
		]
	}

	onConnectorPropChange(prop, val) {
		var configuration = this.state.configuration;
		configuration[prop] = val;

		this.setState({
			"configuration": configuration
		});

		this.props.onChange(configuration);
	}

	onSelectRecoveryFieldType = (e) => {
		let selectedRecoveryFieldType = this.state.recovery_fields_types.find(p => p.value === e.target.value);
		this.setState({
			selectedRecoveryFieldType
		})
	}

	onAddRecoveryField = () => {
		var recovery_fields = this.state.configuration.fields_available_recovery || [];
		recovery_fields.push(this.state.selectedRecoveryFieldType.value);
		this.onConnectorPropChange("fields_available_recovery", recovery_fields);
		this.setState({
			selectedRecoveryFieldType: null
		})
	}

	onDeleteRecoveryField = (field) => {
		var recovery_fields = this.state.configuration.fields_available_recovery || [];
		recovery_fields = recovery_fields.filter(p => p !== field);
		this.onConnectorPropChange("fields_available_recovery", recovery_fields);
	}

	getRecoveryFieldByValue = (value) => {
		return this.state.recovery_fields_types.find(p => p.value === value);
	}

	render() {
		var configuration = this.state.configuration;
		const {
			fields_available_recovery: fieldsAvailableRecovery = []
		} = configuration;
		const fields_available_recovery = fieldsAvailableRecovery || [];

		return <div className="rdp-super-admin-component-connector-form-insights">
			{configuration &&
				<>
					<Stack direction="row" spacing={2} alignItems="center">
						<FormControl fullWidth sx={{mt: "15px"}}>
							<InputLabel id="project-uid-select-label">Propriedades do usuário</InputLabel>
							<Select
								id="project-uid-select"
								label="Propriedades do usuário"
								labelId="project-uid-select-label"
								value={this.state.selectedRecoveryFieldType ? this.state.selectedRecoveryFieldType.value : ""}
								onChange={this.onSelectRecoveryFieldType}
							>
								{this.state.recovery_fields_types.filter(t => !fields_available_recovery.includes(t.value)).map((field) => (
									<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
								))}
							</Select>
						</FormControl>

						<div>
							<Button
								variant="contained"
								color="success"
								sx={{mt: "15px"}}
								onClick={this.onAddRecoveryField}
								disabled={!this.state.selectedRecoveryFieldType}
							>
								<Add/>
							</Button>
						</div>
					</Stack>

					<Typography variant="h6" gutterBottom sx={{marginTop: "15px"}}>
						Campos de recuperação utilizados
					</Typography>

					<TableContainer component={Paper}>
						<Table sx={{width: "100%"}} size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell>Nome</TableCell>
									<TableCell align="center">Valor</TableCell>
									<TableCell align="center">Remover</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{fields_available_recovery.map((row, index) => (
									<TableRow
										key={index}
										sx={{'&:last-child td, &:last-child th': {border: 0}}}
									>
										<TableCell component="th" scope="row">
											{this.getRecoveryFieldByValue(row).name}
										</TableCell>
										<TableCell align="center">{row}</TableCell>
										<TableCell align="center">
											<Button
												variant="contained"
												color="error"
												onClick={() => this.onDeleteRecoveryField(row)}
											>
												<Delete/>
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			}
		</div>
	}
}
