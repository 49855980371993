import React, {Component, createRef} from 'react';
import {styled} from '@mui/material/styles';
import {Divider, Drawer, FormControl, IconButton, InputLabel, ListItem, MenuItem, Select, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {Close, Folder, PlayArrow, Preview, Splitscreen, Terminal} from "@mui/icons-material";
import {default as MonacoEditor} from "@monaco-editor/react";
import Box from "@mui/material/Box";
/* eslint import/no-webpack-loader-syntax: off */
import contextCode from "!!raw-loader!./boilerplates/context.js";
/* eslint import/no-webpack-loader-syntax: off */
import runtCode from "!!raw-loader!./boilerplates/run.js";
/* eslint import/no-webpack-loader-syntax: off */
import helloWorld from "!!raw-loader!./boilerplates/examples/hello-world.js";
import SaveButton from "../save-button/save-button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ResizeSlider from "../risize-slider/resize-slider";
import Typography from "@mui/material/Typography";


const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	justifyContent: 'space-between',
	height: "60px",
	position: "absolute",
	top: "0",
	left: "0",
	width: "100%",
	borderBottom: "1px solid #e8e8e8",
}));

class CodeEditorTerminal extends Component {
	editorEl = createRef();
	state = {
		editCode: CodeEditorTerminal.getMainCode(helloWorld),
		editorConsole: [],
		templates: [
			{
				label: "Olá Mundo!",
				code: helloWorld,
			},
			...this.props.templates || [],
		],
		selectedTemplate: (this.props.selectedTemplate !== undefined) ? this.props.selectedTemplate : 0,
		running: false,
		consoleOrientation: "vertical",
		projectOpen: false,
		projectWidth: 300,
		consoleSize: 300,
	}

	componentDidMount() {
		this.handleSelectTemplate(this.state.selectedTemplate);
	}

	static getMainCode = (code) => {
		return `// Esta função será executada para cada elemento selecionado\nasync function main(context) {\n\t${code}\n}`;
	}

	mountEditor = (editor, monaco) => {
		this.editorEl.current = editor
	}

	handleClose = () => {
		if (this.props.onClose) {
			this.setState({projectOpen: false});
			this.props.onClose();
		}
	}

	runEditorCode = async () => {
		this.setState({
			editorConsole: [],
			running: true
		});
		let editorConsole = this.state.editorConsole || [];
		// Não remover
		// Usado como parâmetro da função main()
		const context = this.props.context || {};
		context.data = this.props.data || [];
		try {
			await eval(contextCode + this.editorEl.current.getValue() + runtCode);
			this.setState({
				editorConsole,
				running: false
			});
		} catch (e) {
			editorConsole.push(e.message);
			this.setState({
				editorConsole,
				running: false
			});
		}
	}

	handleSelectTemplate = (template) => {
		this.setState({
			editCode: CodeEditorTerminal.getMainCode(this.state.templates[template].code),
			selectedTemplate: template,
		});
	}

	handleEditorChange = (value, event) => {
		this.setState({editCode: value});
	}

	handleClearConsole = () => {
		this.setState({
			editorConsole: [],
		});
	}

	handleToogleConsoleOrientation = () => {
		this.setState({
			consoleOrientation: this.state.consoleOrientation === "vertical" ? "horizontal" : "vertical",
			projectOpen: this.state.consoleOrientation === "horizontal"
		});
	}

	getProjectWidth = () => {
		return `${this.state.projectWidth}px`;
	}

	renderResizeConsoleSlider = () => {
		return (
			<>
				{(this.state.editorConsole.length > 0 && this.state.consoleOrientation === "vertical") && (
					<ResizeSlider
						defaultValue={this.state.consoleOrientation === "vertical" ? (this.state.consoleSize - 60) : this.state.consoleSize}
						orientation={this.state.consoleOrientation}
						marginLeft={this.state.projectOpen ? `-${this.getProjectWidth()}/2` : "0px"}
						onChange={(event, value) => {
							this.setState({
								consoleSize: value + 60,
							});
						}}
					/>
				)}
			</>
		)
	}

	renderProject = () => {
		return (
			<>
				<Drawer
					sx={{
						width: this.getProjectWidth(),
						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: this.getProjectWidth(),
							boxSizing: 'border-box',
						},
						overflow: "hidden",
					}}
					variant="persistent"
					anchor="left"
					open={this.state.projectOpen}
				>
					<DrawerHeader>
						<Typography
							variant="p"
							color="primary"
							sx={{
								fontSize: "1.5rem",
								fontWeight: "bold",
							}}
						>
							Projeto
						</Typography>

						<IconButton onClick={() => this.setState({projectOpen: false})}>
							<ChevronLeftIcon />
						</IconButton>
					</DrawerHeader>

					<Divider />

					<List
						sx={{
							mt: "60px",
							overflowY: "auto",
							overflowX: "hidden",
						}}
					>
						{this.props.data.map((item, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton
									onClick={() => {
										if (this.props.onProjectItemClick) {
											this.props.onProjectItemClick(item, index);
										}
									}}
								>
									<ListItemText primary={item.title} />
								</ListItemButton>
							</ListItem>
						))}

						<ListItem disableGutters disableRipple>
							<ResizeSlider
								defaultValue={300}
								onChange={(event, value) => this.setState({projectWidth: value})}
							/>
						</ListItem>
					</List>
				</Drawer>
			</>
		)
	}

	renderHeader = () => {
		return (
			<>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					sx={{
						width: "100%",
						padding: "10px",
						position: "relative",
						borderBottom: "1px solid #e0e0e0",
				}}
					spacing={1}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={1}
					>
						{!this.state.projectOpen && (
							<Button
								variant="text"
								onClick={() => {
									this.setState({
										projectOpen: !this.state.projectOpen,
										consoleOrientation: !this.state.projectOpen ? "vertical" : this.state.consoleOrientation,
									})
								}}
							>
								<Folder/>
							</Button>
						)}

						<FormControl sx={{ minWidth: 120 }} size="small">
							<InputLabel id="demo-select-small">Templates</InputLabel>
							<Select
								labelId="demo-select-small"
								id="demo-select-small"
								label="Templates"
								onChange={(e) => this.handleSelectTemplate(e.target.value)}
								value={this.state.selectedTemplate}
							>
								{this.state.templates.map((template, index) => (
									<MenuItem key={index} value={index}>{template.label}</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>

					<SaveButton
						type="button"
						loading={this.state.running}
						onClick={this.runEditorCode}
						startIcon={<></>}
					>
						<PlayArrow/>
					</SaveButton>

					<Button
						variant="text"
						onClick={this.handleClose}
					>
						<Close/>
					</Button>
				</Stack>
			</>
		)
	}

	renderPreview = () => {
		if (this.props.preview !== null) {
			return (
				<>
					<Box
						sx={{
							width: "50%",
							height: "100%",
							backgroundColor: 'rgb(32 33 36)',
							color: "white",
							overflow: "hidden",
							padding: "10px",
							position: "relative",
							paddingTop: "30px",
						}}
					>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							sx={{
								width: "100%",
								padding: "10px",
								position: "absolute",
								top: "0",
								left: "0",
								mb: "10px",
								height: "30px",
							}}
						>
							<Preview color="secondary"/>
						</Stack>

						<Box
							sx={{
								width: "100%",
								height: "100%",
								overflowY: "auto",
							}}
						>
							{this.props.preview}
						</Box>
					</Box>
				</>
			)
		}
	}

	render() {
		return (
			<>
				<Drawer
					anchor="bottom"
					open={this.props.open}
					onClose={this.handleClose}
					variant="persistent"
					sx={{
						width: this.state.projectOpen ? `calc(100% - ${this.getProjectWidth()})` : "100%",
						marginLeft: this.state.projectOpen ? this.getProjectWidth() : "0",
						height: "100%",
						flexShrink: 0,
						[`& .MuiDrawer-paper`]: {
							width: this.state.projectOpen ? `calc(100% - ${this.getProjectWidth()})` : "100%",
							height: "100%",
							boxSizing: 'border-box',
							marginLeft: this.state.projectOpen ? this.getProjectWidth() : "0px",
							overflow: "hidden",
						},
					}}
				>
					{this.renderResizeConsoleSlider()}

					{this.renderHeader()}

					<Stack
						direction={this.state.consoleOrientation === "vertical" ? "column" : "row"}
						justifyContent="space-between"
						alignItems="center"
						sx={{
							width: "100%",
							height: "100%",
						}}
					>
						<Stack
							direction="row"
							justifyContent="space-between"
							sx={{
								width: (this.state.consoleOrientation === "vertical" || !this.state.editorConsole.length) ? "100%" : "50%",
								height: (this.state.consoleOrientation === "horizontal" || !this.state.editorConsole.length) ? "100%" : `${window.innerHeight - this.state.consoleSize}px`,
							}}
						>
							{this.renderPreview()}

							<MonacoEditor
								width={this.props.preview ? "50%" : "100%"}
								height="100%"
								theme="vs-dark"
								defaultLanguage="javascript"
								value={this.state.editCode}
								onMount={this.mountEditor}
								onChange={this.handleEditorChange}
								options={{
									tabSize: 2,
								}}
							/>
						</Stack>

						{this.state.editorConsole.length > 0 &&
							<Box
								sx={{
									width: "100%",
									height: this.state.consoleOrientation === "vertical" ? `${this.state.consoleSize}px` : "100%",
									backgroundColor: 'rgb(32 33 36)',
									color: "white",
									overflowY: "auto",
									padding: "10px",
								}}
							>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									sx={{
										width: "100%",
										padding: "10px",
										position: "relative",
										mb: "10px",
										height: "30px",
								}}
								>
									<Terminal color="secondary"/>

									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={2}
									>
										<Button
											variant="text"
											onClick={this.handleToogleConsoleOrientation}
										>
											<Splitscreen
												sx={{
													transform: this.state.consoleOrientation === "vertical" ? "rotate(90deg)" : "rotate(0deg)",
												}}
											/>
										</Button>

										<Button
											variant="text"
											onClick={this.handleClearConsole}
										>
											<Close/>
										</Button>
									</Stack>
								</Stack>

								{this.state.editorConsole.map((line, index) => (<p key={index}>{line}</p>))}
							</Box>
						}
					</Stack>
				</Drawer>

				{this.renderProject()}
			</>
		);
	}
}

export default CodeEditorTerminal;