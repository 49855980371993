import React, {Component} from 'react';
import TextField from '@mui/material/TextField';


export default class ConnectorFormDam extends Component {
	state = {
		configuration: this.props.configuration,
		projects: this.props.projects || [],
	}

	componentDidMount() {
		this.onConnectorPropChange("filters", "");
	}

	onConnectorPropChange(prop, val) {
		var configuration = this.state.configuration;
		configuration[prop] = val;

		this.setState({
			"configuration": configuration
		});

		this.props.onChange(configuration);
	}

	render() {
		const {
			configuration,
		} = this.state;
		return <div className="rdp-super-admin-component-connector-form-dam">
			{this.state.configuration &&
				<>
					<TextField
						label="Filtros adicionais nas consultas realizadas no Storage"
						style={{width: "100%", marginTop: "15px", minWidth: "400px"}}
						value={configuration.filters || ""}
						onChange={(e) => this.onConnectorPropChange("filters", e.target.value)}
					/>
				</>
			}
		</div>
	}
}