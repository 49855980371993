import React, {Component} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Logo from './logo.png';
import {LoginService} from "../../services/login";
import './home.css';
import backgroundImage from '../../assets/images/EC-Equidade-Racial-anuncio_V002.png';
import {Card, CardContent, CircularProgress} from "@mui/material";

export default class ViewHome extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      "title": "Digital Pages",
      "render_error_alert": false,
      "environment": "production",
      "is_loading": false,
    }
  }

  componentWillMount() {
    this.redirectToRoute();
  }

  redirectToRoute() {
    var session = window.localStorage.getItem("rdp-super-admin-token-" + window.location.host);
    if (session !== null) {
      window.location = window.location.origin + window.location.pathname + "#/directories";
    }
  }

  handleSubmit(event) {
    var _this = this;
    event.preventDefault();

    if (this.state.is_loading) return;

    this.setState({is_loading: true})

    const form = new FormData(event.currentTarget);

    LoginService.login(form.get('login'), form.get('password'), _this.state.environment).then(function (data) {
      window.localStorage.setItem("rdp-super-admin-token-" + window.location.host, JSON.stringify(data));
      window.localStorage.setItem("rdp-super-admin-credentials-" + window.location.host, JSON.stringify({
        "login": form.get('login'),
        "password": form.get('password')
      }));
      window.localStorage.setItem("rdp-super-admin-environment", _this.state.environment)
      _this.redirectToRoute()
    }, function (error) {
      _this.setState({
        is_loading: false,
        "render_error_alert": true
      })
    });
  };

  closeErrorAlert() {
    this.setState({
      "render_error_alert": false
    })
  }

  onEnvironmentChange(e) {
    console.log(e.target.value)
    this.setState({
      "environment": e.target.value
    })
  }

  render() {
    return (
      <>
        <CssBaseline />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: "hidden",
            maxHeight: "100%",
            zIndex: -1,
          }}
        >
          <img
            src={backgroundImage}
            width="100%"
            height="auto"
            style={{
              minWidth: "650px",
            }}
            alt="background"
          />
        </Box>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{ minWidth: 275, height: "auto" }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src={Logo} style={{width: "280px"}} alt="euconquisto.com"/>
                <Typography component="h1" variant="h5" style ={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#474747"
                }}>
                  Super Admin
                </Typography>
                <Box component="form" onSubmit={(e) => this.handleSubmit(e)} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="login"
                    label="Login"
                    name="login"
                    autoComplete="login"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <FormControl fullWidth style={{marginTop: "15px"}}>
                    <InputLabel id="demo-simple-select-label">Ambiente</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.environment}
                      label="Ambiente"
                      onChange={(e) => this.onEnvironmentChange(e)}
                    >
                      <MenuItem value={"production"}>Produção</MenuItem>
                      <MenuItem value={"homolog"}>Homolog</MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{position: 'relative', mt: 3, mb: 2 }}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={this.state.is_loading}
                    >
                      Realizar login
                    </Button>

                    {this.state.is_loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                          pointerEvents: "none",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>

              <Snackbar open={this.state.render_error_alert} autoHideDuration={6000} onClose={() => this.closeErrorAlert()}>
                <Alert onClose={() => this.closeErrorAlert()} severity="error" sx={{ width: '100%' }}>
                  Usuário ou senha inválidos. Tente novamente
                </Alert>
              </Snackbar>
            </CardContent>
          </Card>
        </Container>
      </>
    )
  }
}
