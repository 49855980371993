import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {DirectoriesService} from "../../services/directories";
import setConnectorsNames from "../../utils/setConnectorsNames";
import './project.css';

import {
  ConnectorFormDirectRegister,
  ConnectorFormInsights,
  ConnectorFormLearning,
  ConnectorFormSendgrid,
  Header,
  NoResults,
  Preloader,
} from "../../components";
import ConnectorFormCustomization from "../../components/connector-form-customization/connector-form-customization";
import {DeleteOutlineTwoTone} from "@mui/icons-material";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import SchemasList from "../directory/tabs/schemas-list";
import CompositionsList from "../../components/tabs/compositions-list/compositions-list";
import CoursesList from "../../components/tabs/courses-list/courses-list";
import ConnectorFormRecoveryAccess
  from "../../components/connector-form-recovery-access/connector-form-recovery-access";
import ConnectorFormDam from "../../components/connector-form-dam/connector-form-dam";
import Transition from "../../components/transitions/transition";
import Toolbar from "@mui/material/Toolbar";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";

export default class ViewProject extends Component {
  constructor(props) {
    super(props);

    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.onConnectorChange = this.onConnectorChange.bind(this);
    this.onConnectorChange = this.onConnectorChange.bind(this);
    this.openProjectConnectorsModal = this.openProjectConnectorsModal.bind(this);
    this.closeProjectConnectorsModal = this.closeProjectConnectorsModal.bind(this);
    this.openProjectDomainsModal = this.openProjectDomainsModal.bind(this);
    this.closeProjectDomainsModal = this.closeProjectDomainsModal.bind(this);


    this.state = {
      "directory": null,
      "project": null,
      "tab": 0,
      "connectors_schema": null,
      "connector_current": null,
      "connector_current_object": null,
      "connector_configuration_temp": null,
      "render_project_connectors_modal": false,
      "temp_connector": null,
      "render_project_domains_modal": false,
      "temp_domain": null,
      "active_domain": null,
      "dialog_open": {
        "delete_domain": false,
      },
      "dialog_data": null,
      "dialog_loading": false,
    }
  }

  componentWillMount() {
    this.redirectToRoute();
    this.initRequests();
  }

  handleOpenDialog(dialog, dialogData) {
    this.setState({
      "dialog_open": {
        ...this.state.dialog_open,
        [dialog]: true,
      },
      "dialog_data": dialogData,
    })
  }

  handleCloseDialog(dialog) {
    this.setState({
      "dialog_open": {
        ...this.state.dialog_open,
        [dialog]: false,
      },
      "dialog_data": null,
    })
  }

  async handleDeleteSubdomain() {
    if (this.state.dialog_loading) return;

    this.setState({
      "dialog_loading": true,
    });

    DirectoriesService.deleteProjectDomain(this.state.dialog_data)
      .then(result => {
        this.setState({
          "dialog_loading": false,
        });
        this.handleCloseDialog("delete_domain");
        this.initRequests();
      })
      .catch(e => {
        this.setState({
          "dialog_loading": false,
        });
      });
  }

  redirectToRoute() {
    var session = window.localStorage.getItem("rdp-super-admin-token-" + window.location.host);
    if (session === null) {
      window.location = window.location.origin + window.location.pathname + "#/";
    }
  }

  async initRequests() {
    var _this = this;
    var directoryUid = this.props.match.params.directoryUid;
    var projectUid = this.props.match.params.projectUid;
    var credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host))

    try {
      var directoryLoginData = await DirectoriesService.directoryLogin(directoryUid, credentials.login, credentials.password);
      window.localStorage.setItem("rdp-super-admin-directory-token-" + window.location.host, JSON.stringify(directoryLoginData));


    } catch (e) {
      window.localStorage.removeItem("rdp-super-admin-token-" + window.location.host);
      _this.redirectToRoute();
    }

    try {
      var directoryInfoData = await DirectoriesService.directoryInfo(directoryUid);
      var connectorsSchema = await DirectoriesService.getConnectorsSchema();
      var projectInfoData = await DirectoriesService.getProjectInfo(projectUid);

      var projectConnectors = setConnectorsNames(projectInfoData.connectors);
      for (var i = 0; i < projectConnectors.length; i++) {
        projectConnectors[i].custom_name = _this.getConnectorName(projectConnectors[i]);
      };

      projectConnectors = projectConnectors.sort(function(a, b) {
        var nameA = a.label.toLowerCase(), nameB = b.label.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      projectInfoData.connectors = projectConnectors;

      this.setState({
        "directory": directoryInfoData,
        "project": projectInfoData,
        "connectors_schema": setConnectorsNames(connectorsSchema.filter(item => item.authorized_scopes === "Directory, Project, User" || item.authorized_scopes === "Directory, Project" || item.authorized_scopes === "Project")).sort(function(a, b) {
          var nameA = a.label.toLowerCase(), nameB = b.label.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        })
      });
    } catch (e) {
      console.log(e);
    }
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleChangeTab(event, newValue) {
    this.setState({
      "tab": newValue
    })
  };

  async onMetadataSave() {
    var projectUid = this.props.match.params.projectUid;
    var project = this.state.project;
    await DirectoriesService.updateDirectoryProject(projectUid, {
      "name": project.name,
      "config": {
        "activation_end": project.config.activation_end
      }
    });
  }

  onMetadataChange(prop, value) {
    var project = this.state.project;
    project[prop] = value;
    this.setState({
      "project": project
    });
  }

  onProjectFinishDateChange(value) {
    var project = this.state.project;
    if (project.config === undefined) project.config = {};
    project.config.activation_end = value
    this.setState({
      "project": project
    });
  }

  onConnectorChange(e) {
    this.setState({
      "connector_current": e.target.value,
      "connector_current_object": this.state.connectors_schema[e.target.value]
    });
  }

  async onConnectorCreate() {
    this.setState({
      "directory": null,
      "project": null,
      "render_project_connectors_modal": false
    });
    if (this.state.connector_current) {
      var projectUid = this.props.match.params.projectUid;
      var connector = this.state.connector_configuration_temp || this.state.connectors_schema[this.state.connector_current];
      var projectConnectorsPost = await DirectoriesService.setProjectConnector(projectUid, connector);
      this.setState({
        "connector_current": null,
        "connector_current_object": null,
        "connector_configuration_temp": null
      });
      this.initRequests()
    }
  }

  getConnectorName(connector) {
    var str = "";
    if (connector.name) {
      str += connector.name + " - ";
    };
    str += connector.type + " - ";
    str += connector.service;

    return str;
  }

  openProjectConnectorsModal() {
    this.setState({
      "render_project_connectors_modal": true
    });
  };

  closeProjectConnectorsModal() {
    this.setState({
      "render_project_connectors_modal": false,
      "connector_current": null,
      "connector_current_object": null,
    });
  };

  onConnectorClick(connector) {
    this.setState({
      "temp_connector": connector
    })
  }

  closeTempConnectorModal() {
    this.setState({
      "temp_connector": null
    })
  }

  async onConnectorUpdate() {
    var connector = this.state.temp_connector;
    if (this.state.connector_configuration_temp) connector.configuration = this.state.connector_configuration_temp;
    var projectConnectorsPost = await DirectoriesService.updateProjectConnector(connector);
    this.setState({
      "connector_current": null,
      "connector_current_object": null,
      "connector_configuration_temp": null,
      "temp_connector": null
    });
    this.initRequests()
  }

  onConnectorPropChange(prop, value) {
    var connector = this.state.temp_connector;
    connector[prop] = value;
    this.setState({
      "temp_connector": connector
    });
  }

  onChangeTempConfiguration(configuration) {
    this.setState({
      "connector_configuration_temp": configuration
    })
  }

  parseIfIsJson(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  openProjectDomainsModal() {
    this.setState({
      "render_project_domains_modal": true
    });
  };

  closeProjectDomainsModal() {
    this.setState({
      "active_domain": null,
      "render_project_domains_modal": false
    });
  };

  async onSubdomainCreate() {
    this.setState({
      "directory": null,
      "project": null,
      "render_project_domains_modal": false
    });
    var projectUid = this.props.match.params.projectUid;
    var projectConnectorsPost = await DirectoriesService.setProjectDomain(projectUid, {
      "domain": this.state.temp_domain
    });

    this.setState({
      "temp_domain": null
    });
    this.initRequests()
  }

  async onSubdomainEdit() {
    this.setState({
      "directory": null,
      "project": null,
      "render_project_domains_modal": false
    });
    var projectConnectorsPost = await DirectoriesService.updateProjectDomain(this.state.active_domain, {
      "domain": this.state.temp_domain
    });

    console.log(projectConnectorsPost)

    this.setState({
      "temp_domain": null,
      "active_domain": null
    });
    this.initRequests()
  }

  onDomainChange(domain) {
    this.setState({
      "temp_domain": domain
    })
  }

  onDomainClick(domain) {
    this.setState({
      "active_domain": domain
    })
  }

  onPublicProject() {
    var projectUid = this.props.match.params.projectUid;
    var directoryUid = this.props.match.params.directoryUid;
    DirectoriesService.onPublicProject(projectUid, directoryUid);
  }

  render() {
    var _this = this;
    var connector = this.state.connector_current_object;

    return (
      <>
        <Header/>
        <div className="rdp-super-admin-breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/#/directories"
            >
              Diretórios
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={`/#/directory/${this.props.match.params.directoryUid}`}
            >
              {this.state.directory ? `Diretório: ${this.state.directory.name}` : "Carregando..."}
            </Link>
            <Typography color="text.primary">{this.state.project ? `Projeto: ${this.state.project.name}` : "Carregando..."}</Typography>
          </Breadcrumbs>
        </div>

        <div className="rdp-super-admin-content">
          {this.state.project === null &&
            <Preloader/>
          }

          {this.state.project &&
            <div className="rdp-super-admin-main">
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={this.state.tab} onChange={this.handleChangeTab} aria-label="basic tabs example">
                    <Tab label="Metadados" {...this.a11yProps(0)} />
                    <Tab label="Subdomínios" {...this.a11yProps(1)} />
                    <Tab label="Conectores" {...this.a11yProps(2)} />
                    <Tab label="Esquemas" {...this.a11yProps(3)} />
                    <Tab label="Composições" {...this.a11yProps(4)} />
                    <Tab label="Cursos" {...this.a11yProps(5)} />
                  </Tabs>
                </Box>
              </Box>

              <div className="rdp-super-admin-tab-content">
                {this.state.tab === 0 &&
                  <div style={{marginTop: "30px"}}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Nome do projeto"
                      defaultValue={this.state.project.name}
                      style={{ width: "100%", "marginBottom": "20px" }}
                      onChange={(e) => this.onMetadataChange("name", e.target.value)}
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="Data de término do Contrato"
                      defaultValue={this.state.project.config && this.state.project.config.activation_end}
                      style={{ width: "100%", "marginBottom": "20px" }}
                      onChange={(e) => this.onProjectFinishDateChange(e.target.value)}
                    />
                    <Button onClick={() => this.onPublicProject()}>Tornar projeto público</Button>
                    <Stack direction="row" spacing={2}>
                      <Button variant="contained" startIcon={<SaveIcon />} onClick={() => this.onMetadataSave()}>Salvar metadados</Button>
                    </Stack>
                  </div>
                }

                {this.state.tab === 1 &&
                  <>
                    {this.state.project.domain_specs.length > 0 && (
                      <>
                        {this.state.project.domain_specs.map((domain, index) => {
                          return <ListItem disablePadding key={index}>
                            <ListItemButton onClick={() => _this.onDomainClick(domain)}>
                              <ListItemText primary={domain.domain}/>
                            </ListItemButton>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => this.handleOpenDialog("delete_domain", domain)}
                            >
                              <DeleteOutlineTwoTone color="white" />
                            </Button>
                          </ListItem>
                        })}
                        <ConfirmDialog
                          open={!!this.state.dialog_open.delete_domain}
                          loading={this.state.dialog_loading}
                          title="Exclusão de subdomínio"
                          description={`Deseja excluír o subdomínio ${this.state.dialog_data && this.state.dialog_data.domain}?`}
                          onCancel={() => this.handleCloseDialog("delete_domain")}
                          onConfirm={() => this.handleDeleteSubdomain()}
                        />
                      </>
                    )}

                    {this.state.project.domain_specs.length === 0 &&
                      <NoResults message="Nenhum subdomínio cadastrado no projeto."/>
                    }

                    <Fab onClick={() => this.openProjectDomainsModal()} color="primary" aria-label="add" style={{position: "fixed", right: "20px", bottom: "20px"}}>
                      <AddIcon />
                    </Fab>

                    <Dialog
                      open={!!this.state.render_project_domains_modal}
                      onClose={() => this.closeProjectDomainsModal()}
                      scroll={"paper"}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                    >
                      <DialogTitle id="scroll-dialog-title">Novo subdomínio</DialogTitle>
                      <DialogContent dividers={true}>
                        <FormControl fullWidth>
                          <TextField
                            required
                            id="outlined-required"
                            label="Subdomínio"
                            style={{ width: "500px", "marginBottom": "20px" }}
                            onChange={(e) => this.onDomainChange(e.target.value)}
                          />
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => this.closeProjectDomainsModal()}>Cancelar</Button>
                        <Button onClick={() => this.onSubdomainCreate()}>Criar subdomínio</Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      open={!!this.state.active_domain}
                      onClose={() => this.closeProjectDomainsModal()}
                      scroll={"paper"}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                    >
                      <DialogTitle id="scroll-dialog-title">Editar subdomínio</DialogTitle>
                      <DialogContent dividers={true}>
                        <FormControl fullWidth>
                          <TextField
                            required
                            id="outlined-required"
                            label="Subdomínio"
                            style={{ width: "500px", "marginBottom": "20px" }}
                            defaultValue={(this.state.active_domain && this.state.active_domain.domain)}
                            onChange={(e) => this.onDomainChange(e.target.value)}
                          />
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => this.closeProjectDomainsModal()}>Cancelar</Button>
                        <Button onClick={() => this.onSubdomainEdit()}>Editar subdomínio</Button>
                      </DialogActions>
                    </Dialog>
                  </>
                }

                {this.state.tab === 2 &&
                  <>
                    {this.state.project.connectors.length > 0 && this.state.project.connectors.filter(item => item.scope === "Project").map(function(connector, index) {
                      return <ListItem disablePadding key={index} onClick={() => _this.onConnectorClick(connector)}>
                        <ListItemButton>
                          <ListItemText primary={connector.label}/>
                        </ListItemButton>
                      </ListItem>
                    })}

                    {this.state.project.connectors.length === 0 &&
                      <NoResults message="Nenhum conector cadastrado no projeto."/>
                    }

                    <Dialog
                      fullScreen
                      open={!!this.state.render_project_connectors_modal}
                      onClose={() => this.closeProjectConnectorsModal()}
                      scroll={"paper"}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                      TransitionComponent={Transition}
                    >
                      <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.closeProjectConnectorsModal()}
                            aria-label="close"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Toolbar>
                      </AppBar>
                      <DialogTitle id="scroll-dialog-title">Novo conector</DialogTitle>
                      <DialogContent dividers={true}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                          <Select
                            style={{minWidth: "500px"}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={age}
                            label="Tipo"
                            onChange={this.onConnectorChange}
                          >
                            {this.state.connectors_schema.map(function(connector, index) {
                              return <MenuItem key={index} value={index}>{connector.label}</MenuItem>
                            })}
                          </Select>
                          {connector && connector.type === "UserCommunication_1" && connector.name === "SendGrid" &&
                            <ConnectorFormSendgrid
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              domains={_this.state.project.domain_specs}
                            />
                          }
                          {connector && connector.type === "DirectRegister_1" &&
                            <ConnectorFormDirectRegister
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              domains={_this.state.project.domain_specs}
                            />
                          }
                          {connector && connector.type === "Insights_1" &&
                            <ConnectorFormInsights
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {connector && connector.type === "Customization_1" &&
                            <ConnectorFormCustomization
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              project={_this.props.match.params.projectUid}
                              directory={_this.props.match.params.directoryUid}
                            />
                          }
                          {connector && connector.type === "Dam_1" &&
                            <ConnectorFormDam
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => this.closeProjectConnectorsModal()}>Cancelar</Button>
                        <Button onClick={() => this.onConnectorCreate()}>Criar conector</Button>
                      </DialogActions>
                    </Dialog>

                    <Fab onClick={() => this.openProjectConnectorsModal()} color="primary" aria-label="add" style={{position: "fixed", right: "20px", bottom: "20px"}}>
                      <AddIcon />
                    </Fab>

                    <Dialog
                      fullScreen
                      open={this.state.temp_connector !== null}
                      onClose={() => this.closeProjectConnectorsModal()}
                      scroll={"paper"}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                      TransitionComponent={Transition}
                    >
                      <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.closeTempConnectorModal()}
                            aria-label="close"
                          >
                            <CloseIcon />
                          </IconButton>

                          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Editar conector
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <DialogContent dividers={true}>
                        <FormControl fullWidth sx={{height: "100%"}}>
                          {this.state.temp_connector && this.state.temp_connector.type === "UserCommunication_1" && this.state.temp_connector.name === "SendGrid" &&
                            <ConnectorFormSendgrid
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              domains={_this.state.project.domain_specs}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.type === "DirectRegister_1" &&
                            <ConnectorFormDirectRegister
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              domains={_this.state.project.domain_specs}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.type === "Insights_1" &&
                            <ConnectorFormInsights
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.type === "Learning_1" &&
                            <ConnectorFormLearning
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.type === "Customization_1" &&
                            <ConnectorFormCustomization
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              project={_this.props.match.params.projectUid}
                              directory={_this.props.match.params.directoryUid}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.type === "Dam_1" &&
                            <ConnectorFormDam
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => this.closeTempConnectorModal()}>Cancelar</Button>
                        <Button onClick={() => this.onConnectorUpdate()}>Salvar conector</Button>
                      </DialogActions>
                    </Dialog>
                  </>
                }

                {this.state.tab === 3 && (
                  <SchemasList
                    directory={this.state.directory}
                    project={this.state.project}
                  />
                )}

                {this.state.tab === 4 && (
                  <CompositionsList
                    directory={this.state.directory}
                    project={this.state.project}
                  />
                )}

                {this.state.tab === 5 && (
                  <CoursesList
                    directory={this.state.directory}
                    project={this.state.project}
                  />
                )}
              </div>
            </div>
          }
        </div>
      </>
    )
  }
}
