import React, {Component, createRef} from 'react';
import Button from "@mui/material/Button";
import {NoResults, Preloader} from "../../index";
import {DirectoriesService} from "../../../services/directories";
import {RDPUtils} from "../../../utils";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Checkbox from "@mui/material/Checkbox";
import {Terminal} from "@mui/icons-material";
import {Divider} from "@mui/material";
import CodeEditorTerminal from "../../code-editor-terminal/code-editor-terminal";
import ReactJson from "react-json-view";
import $ from "jquery";
/* eslint import/no-webpack-loader-syntax: off */
import updateCompositionContent from "!!raw-loader!./boilerplates/update-composition-content.js";

class CompositionsList extends Component {
	state = {
		openEditorCode: false,
		saving: false,
		data: [],
		loading: false,
		selected: [],
		preview: null,
	}

	componentDidMount() {
		this.load().finally(() => this.setState({loading: false}));
	}

	componentWillUnmount() {
		if (this.state.editor) {
			this.state.editor.dispose();
		}
	}

	load = async () => {
		this.setState({loading: true});

		let data = [];

		if (this.props.project) {
			let query = `filter=(content_type eq 'digitalpages/composer') and (project_uid eq '${this.props.project.uid}')&top=10&orderBy=last_modified desc`;
			data = await DirectoriesService.storageSearch(query);
		} else if (this.props.directory) {
			let query = `filter=(content_type eq 'digitalpages/composer')&top=10&orderBy=last_modified desc`;
			data = await DirectoriesService.storageSearch(query);
		}

		this.setState({data: data.results || [], loading: false});
	}

	getEmptyMessage() {
		if (this.props.project) {
			return "Nenhuma composição cadastrado no projeto."
		} else {
			return "Nenhuma composição cadastrado no diretório."
		}
	}

	handleOpenEditorCode = () => {
		this.setState({openEditorCode: true});
	}

	handleCloseEditorCode = () => {
		this.setState({openEditorCode: false});
	}

	handleToggleChecked = (evt) => {
		let selected = [];
		if (evt.target.checked) {
			selected = this.state.data.map(item => item.document.content_uid);
			this.setState({selected: selected});
		} else {
			this.setState({selected: []});
		}
	}

	getCodeEditorData = () => {
		let data = this.state.data.filter(item => this.state.selected.includes(item.document.content_uid));

		data = data.map(item => {
			return {
				value: item,
				title: item.document.content_info_values[1],
			};
		});

		return data;
	}

	handleProjectItemClick = (item, index) => {
		this.setState({
			preview: {...item, index},
			previewLoading: true,
		}, () => {
			DirectoriesService.getDirectoryProjectComposerContent(item.value.document.content_uid)
				.then(resp => {
					item.content = JSON.parse(resp);
					this.setState({
						preview: {...item, index},
						previewLoading: false,
					});
				});
		});
	}

	renderPreview = () => {
		if (this.state.previewLoading) {
			return <Preloader/>;
		}

		if (this.state.preview) {
			return (
				<>
					<ReactJson
						name="context"
						src={{
							DirectoriesService,
							directory: this.props.directory,
							project: this.props.project,
							selected: {
								index: this.state.preview.index,
								content: this.state.preview.content,
							},
							data: [
								{
									title: this.state.preview.title,
									value: this.state.preview.value,
								}
							]
						}}
						theme="twilight"
						collapsed={false}
					/>
				</>
			)
		}

		return null;
	}

  getDocumentTitle = (document) => {
    return document.content_info[1].split("=")[1];
  }

	render() {
		if (this.state.loading) {
			return (
				<Preloader/>
			)
		}

		return (
			<div>
				{this.state.data.length === 0 &&
					<NoResults message={this.getEmptyMessage()} />
				}

				{this.state.data.length > 0 && (
					<>
						<List className="rdp-super-admin-compositions-list">
							<ListItem disablePadding>
								<Checkbox
									inputProps={{'aria-label': 'Checkbox demo' }}
									checked={this.state.selected.length > 0}
									indeterminate={this.state.selected.length !== this.state.data.length && this.state.data.length > 0 && this.state.selected.length > 0}
									onChange={this.handleToggleChecked}
								/>

								<Divider
									orientation="vertical"
									flexItem
									sx={{mr: "10px"}}
								/>

								<ListItemText primary="Nome"/>

								<Divider
									orientation="vertical"
									flexItem
									sx={{mr: "10px"}}
								/>

								<Button
									variant="outlined"
									disabled={this.state.selected.length === 0}
									onClick={this.handleOpenEditorCode}
								>
									<Terminal />
								</Button>
							</ListItem>

							<Divider
								orientation="horizontal"
								flexItem
								sx={{pb: "10px"}}
							/>

							{RDPUtils.orderAlphabetically(this.state.data, "name").map((row) => (
								<ListItem disablePadding key={row.uid}>
									<Checkbox
										inputProps={{'aria-label': 'Checkbox demo' }}
										checked={this.state.selected.includes(row.document.content_uid)}
										onChange={(evt) => {
											if (evt.target.checked) {
												this.setState({selected: [...this.state.selected, row.document.content_uid]});
											} else {
												this.setState({selected: this.state.selected.filter(uid => uid !== row.document.content_uid)});
											}
										}}
									/>

									<Divider orientation="vertical" flexItem />

									<ListItemButton>
										<ListItemText primary={this.getDocumentTitle(row.document) || ""}/>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</>
				)}

				<CodeEditorTerminal
					open={this.state.openEditorCode}
					context={{
						$,
						DirectoriesService,
						directory: this.props.directory,
						project: this.props.project,
						selected: {
							index: this.state.preview ? this.state.preview.index : null,
							content: this.state.preview ? this.state.preview.content : null,
						}
					}}
					templates={[
						{
							label: "Alterar composição",
							code: updateCompositionContent,
						}
					]}
					data={this.getCodeEditorData()}
					onClose={this.handleCloseEditorCode}
					onProjectItemClick={this.handleProjectItemClick}
					preview={this.renderPreview()}
					selectedTemplate={1}
				/>
			</div>
		);
	}
}

export default CompositionsList;