import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {DirectoriesService} from "../../services/directories";
import setConnectorsNames from "../../utils/setConnectorsNames";
import './directory.css';
import CloseIcon from '@mui/icons-material/Close';

import {
  ConnectorFormDirectRegister,
  ConnectorFormInsights,
  ConnectorFormSendgrid,
  ConnectorFormAzureMedia,
  Header,
  NoResults,
  Preloader
} from "../../components";
import $ from "jquery";
import SchemasList from "./tabs/schemas-list";
import ConnectorFormRecoveryAccess
  from "../../components/connector-form-recovery-access/connector-form-recovery-access";
import ConnectorFormRecaptcha from "../../components/connector-form-recaptcha/connector-form-recaptcha";
import ConnectorFormDam from "../../components/connector-form-dam/connector-form-dam";
import ConnectorFormPolicies from "../../components/connector-form-policies/connector-form-policies";
import {IconButton} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Transition from "../../components/transitions/transition";
import UsersList from "../../components/tabs/users-list/users-list";
import UserEdit from "../../components/users-edit/user-edit";
import Properties from "../../components/properties/properties";
import {UserService} from "../../services/user";
import Swal from "sweetalert2";

export default class ViewDirectory extends Component {
  constructor(props) {
    super(props);

    this.handleRole = this.handleRole.bind(this);
    this.handleProject = this.handleProject.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.onConnectorChange = this.onConnectorChange.bind(this);
    this.openDirectoryConnectorsModal = this.openDirectoryConnectorsModal.bind(this);
    this.closeDirectoryConnectorsModal = this.closeDirectoryConnectorsModal.bind(this);

    this.state = {
      "directory": null,
      "projects": null,
      "users": null,
      "roles": null,
      "tab": 0,
      "connectors_schema": null,
      "connector_current": null,
      "connector_current_object": null,
      "connector_configuration_temp": null,
      "temp_project": null,
      "temp_user": null,
      "render_directory_connectors_modal": false,
      "render_directory_projects_modal": false,
      "temp_user_login": null,
      "temp_user_detail_email_principal": null,
      "temp_user_detail_first_name": null,
      "temp_user_detail_last_name": null,
      "temp_user_detail_password": null,
      "temp_connector": null,
      "user_required_properties": [],
    }

    this.redirectToRoute();
    this.getDirectoryInfo();
  }

  redirectToRoute() {
    var session = window.localStorage.getItem("rdp-super-admin-token-" + window.location.host);
    if (session === null) {
      window.location = window.location.origin + window.location.pathname + "#/";
    }
  }

  async getDirectoryInfo() {
    var _this = this;
    var uid = this.props.match.params.uid;
    var credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host))

    try {
      var directoryLoginData = await DirectoriesService.directoryLogin(uid, credentials.login, credentials.password);
      window.localStorage.setItem("rdp-super-admin-directory-token-" + window.location.host, JSON.stringify(directoryLoginData));

      var directoryFirstUser = await DirectoriesService.getDirectoryFirstUser(uid);
      var userLoginData = await DirectoriesService.userVirtualLogin(directoryFirstUser.uid, credentials.login, credentials.password);
      window.localStorage.setItem("rdp-super-admin-user-token-" + window.location.host, JSON.stringify(userLoginData));

      _this.getDirectoryProjects();
      _this.getDirectoryUsers();
    } catch (e) {
      window.localStorage.removeItem("rdp-super-admin-token-" + window.location.host);
      _this.redirectToRoute();
    }

    try {
      var directoryInfoData = await DirectoriesService.directoryInfo(uid);
      var connectorsSchema = await DirectoriesService.getConnectorsSchema();

      var directoryConnectors = setConnectorsNames(directoryInfoData.connectors);
      for (var i = 0; i < directoryConnectors.length; i++) {
        directoryConnectors[i].custom_name = _this.getConnectorName(directoryConnectors[i]);
      };

      directoryConnectors = directoryConnectors.sort(function(a, b) {
        var nameA = a.label.toLowerCase(), nameB = b.label.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      directoryInfoData.connectors = directoryConnectors;

      this.setState({
        "directory": directoryInfoData,
        "user_required_properties": directoryInfoData.user_required_properties,
        "connectors_schema": setConnectorsNames(connectorsSchema.filter(item => item.authorized_scopes === "Directory, Project, User" || item.authorized_scopes === "Directory, Project" || item.authorized_scopes === "Directory")).sort(function(a, b) {
          var nameA = a.label.toLowerCase(), nameB = b.label.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        })
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getDirectoryProjects() {
    var uid = this.props.match.params.uid;
    var directoryProjects = await DirectoriesService.getConnectorProjects(uid);
    this.setState({
      "projects": directoryProjects
    });
  }

  async getDirectoryUsers() {
    var uid = this.props.match.params.uid;
    var directoryUsers = await DirectoriesService.getDirectoryUsers(uid);
    var roles = await DirectoriesService.getRoles();

    this.setState({
      "users": directoryUsers,
      "roles": roles,
      "temp_user": null
    });
  }

  componentDidMount() {

  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleChangeTab(event, newValue) {
    this.setState({
      "tab": newValue
    })
  };

  async onMetadataSave() {
    var uid = this.props.match.params.uid;
    var directory = this.state.directory;
    var metadataObj = await DirectoriesService.directoryUpdateMetadata(uid, {
      "name": directory.name,
      "jwt_expiration_hours_admin": parseInt(directory.jwt_expiration_hours_admin),
      "jwt_expiration_hours_contributor": parseInt(directory.jwt_expiration_hours_contributor),
      "jwt_expiration_hours_viewer": parseInt(directory.jwt_expiration_hours_viewer),
      "user_required_properties": this.state.user_required_properties
    });

    this.setState({
      "directory": metadataObj,
      "user_required_properties": metadataObj.user_required_properties
    });
  }

  onMetadataChange(prop, value) {
    var directory = this.state.directory;
    directory[prop] = value;
    this.setState({
      "directory": directory
    });
  }

  onConnectorChange(e) {
    debugger;
    this.setState({
      "connector_current": e.target.value,
      "connector_current_object": this.state.connectors_schema[e.target.value]
    });
  }

  async onConnectorCreate() {
    this.setState({
      "directory": null,
      "render_directory_connectors_modal": false
    });
    if (this.state.connector_current !== null) {
      let uid = this.props.match.params.uid;
      let [beforeConnector, tempData] = this.beforeCreate(this.state.connectors_schema[this.state.connector_current]);
      let directoryConnectorsPost = await DirectoriesService.setDirectoryConnector(uid, beforeConnector);
      let [afterConnector] = this.afterCreate(directoryConnectorsPost, tempData);
      this.setState({
        "connector_current": null,
        "connector_current_object": null,
        "connector_configuration_temp": null
      });
      this.getDirectoryInfo()
    }
  }

  beforeCreate(connector) {
    let tempData = {};

    switch (connector.type) {
      case "Insights_1":
        tempData.report = connector.report;
        delete connector.report;

        tempData.workspace = connector.workspace;
        delete connector.workspace;

        tempData.authorizations = connector.authorizations;
        delete connector.authorizations;
        break;
      default:
        console.error("Connector type not supported: ", connector.type);
    }

    return [connector, tempData];
  }

  afterCreate(connector, tempData) {
    switch (connector.type) {
      case "Insights_1":
        this.sendPowerBiRegistration({
          connectorUid: connector.uid,
          authorizations: tempData.authorizations,
          workspace: tempData.workspace,
          report: tempData.report,
          scope_uid: this.props.match.params.uid,
        });
        break;
      default:
        console.error("Connector type not supported: ", connector.type);
    }

    return [connector, tempData];
  }

  sendPowerBiRegistration(args) {
    const {
      connectorUid,
      authorizations,
      workspace,
      report,
      scope_uid,
    } = args;

    const data = {
      name: "Visão Geral",
      description: "Dados de uso",
      type: "Learning_1",
      workspace,
      report,
      scope_type: "Directory",
      scope_uid,
      authorizations,
    };

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        $.ajax({
          url: `https://${window.rdp_domain}/insights/v1.1/report/config/uid/${connectorUid}`,
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          headers: {
            "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
            "Project-Key": window.rdp_project_key,
            "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
          },
        }).then(resolve, reject);
      }, 10000);
    });
  }

  onTempUserReset() {
    this.setState({
      "temp_user_login": null,
      "temp_user_detail_email_principal": null,
      "temp_user_detail_first_name": null,
      "temp_user_detail_last_name": null,
      "temp_user_password": null
    });
  }

  async onProjectCreate() {
    this.setState({
      "projects": null,
      "render_directory_projects_modal": false
    });

    if (this.state.temp_project) {
      var uid = this.props.match.params.uid;
      var projectPost = await DirectoriesService.createDirectoryProject(uid, this.state.temp_project);
      await this.onUserAddProject(projectPost, uid);
      this.setState({
        "temp_project": null
      });
      this.getDirectoryProjects()
    }
  }

  getConnectorName(connector) {
    var str = "";
    if (connector.name) {
      str += connector.name + " - ";
    };
    str += connector.type + " - ";
    str += connector.service;

    return str;
  }

  openDirectoryConnectorsModal() {
    this.setState({
      "render_directory_connectors_modal": true
    });
  };

  closeDirectoryConnectorsModal() {
    this.setState({
      "render_directory_connectors_modal": false,
      "connector_current": null,
      "connector_current_object": null,
    });
  };

  openDirectoryProjectsModal() {
    this.setState({
      "render_directory_projects_modal": true
    });
  };

  closeDirectoryProjectsModal() {
    this.setState({
      "render_directory_projects_modal": false
    });
  };

  closeDirectoryUserEditModal() {
    this.onTempUserReset();
    this.setState({
      "temp_user": null,
    });
  }

  async getTempUser(userUid) {
    var userData = await DirectoriesService.getDirectoryUser(userUid);
    this.setState({
      "temp_user": userData
    });
  }

  async onUserAddRole(role, userUid) {
    var obj = {
      "type": role.type
    };

    var userRole = await DirectoriesService.setRole(userUid, obj);

    this.getTempUser(userUid)
  }

  async onUserRemoveRole(role, userUid) {
    var roleUid;
    var userRoles = this.state.temp_user.roles;
    for (var i = 0; i < userRoles.length; i++) {
      if (userRoles[i].type === role.type) {
        roleUid = userRoles[i].uid;
      };
    };

    var userRole = await DirectoriesService.removeRole(userUid, roleUid);

    this.getTempUser(userUid)
  }

  handleRole(checked, role) {
    var userUid = this.state.temp_user.uid;
    this.setState({
      "temp_user": null
    });

    if (checked) {
      this.onUserRemoveRole(role, userUid);
    } else {
      this.onUserAddRole(role, userUid);
    }
  }

  async onUserAddProject(project, userUid) {
    var userProject = await DirectoriesService.setUserProject(project.uid, userUid, this.state.directory.uid);
    this.getTempUser(userUid)
  }

  handleProject(checked, project) {
    var userUid = this.state.temp_user.uid;
    this.setState({
      "temp_user": null
    });
    if (checked) {
      console.log("REMOVE PROJECT")
    } else {
      this.onUserAddProject(project, userUid);
    }
  }

  onProjectNameChange(val) {
    this.setState({
      "temp_project": val === "" ? null : val
    })
  }

  onChangeTempConfiguration(configuration) {
    this.setState({
      "connector_configuration_temp": configuration
    })
  }

  closeProjectConnectorsModal() {
    this.setState({
      "render_directory_connectors_modal": false
    });
  };

  onConnectorClick(connector) {
    this.setState({
      "temp_connector": connector
    })
  }

  closeTempConnectorModal() {
    this.setState({
      "temp_connector": null
    })
  }

  async onConnectorUpdate() {
    var connector = this.state.temp_connector;
    if (this.state.connector_configuration_temp) connector.configuration = this.state.connector_configuration_temp;
    var projectConnectorsPost = await DirectoriesService.updateProjectConnector(connector);
    this.setState({
      "connector_current": null,
      "connector_current_object": null,
      "connector_configuration_temp": null,
      "temp_connector": null
    });
    this.getDirectoryInfo()
  }

  parseIfIsJson(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  onPropertyChange = (e, index) => {
    this.setState({
      user_required_properties: this.state.user_required_properties.map((property, i) => {
        if (i === index) {
          property[e.target.name] = e.target.value;
        }
        return property;
      })
    })
  }

  removeProperty = async (index) => {
    const property = this.state.user_required_properties[index];

    if (property.uid) {
      Swal.fire({
        title: `Apagar a propriedade "${property.name}" dos usuários?`,
        text: "Os usuários podem ter preenchido algum valor nesta propriedade. Esta ação não pode ser desfeita.",
        input: 'password',
        inputLabel: 'Senha de confirmação:',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Apagar',
        confirmButtonColor: '#d33',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#aaa',
        reverseButtons: true,
        preConfirm: (password) => {
          let credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host) || "{}");
          if (credentials.password !== password) {
            Swal.showValidationMessage(
              `Senha inválida.`
            )
            return;
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({
            user_required_properties: this.state.user_required_properties.filter((_, i) => i !== index)
          });
        }
      })
    } else {
      this.setState({
        user_required_properties: this.state.user_required_properties.filter((_, i) => i !== index)
      });
    }
  }

  addProperty = () => {
    this.setState({
      user_required_properties: [
        ...this.state.user_required_properties,
        {
          name: "",
          regex: "",
          type: "text",
        }
      ]
    });
  }

  render() {
    var _this = this;
    const { temp_user } = this.state;
    var connector = this.state.connector_current_object;
    console.log(connector)

    return (
      <>
        <Header/>

        <div className="rdp-super-admin-breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/#/directories"
            >
              Diretórios
            </Link>
            <Typography color="text.primary">{this.state.directory ? `Diretório: ${this.state.directory.name}` : "Carregando..."}</Typography>
          </Breadcrumbs>
        </div>

        <div className="rdp-super-admin-content">
          {this.state.directory === null &&
            <Preloader/>
          }

          {this.state.directory &&
            <div className="rdp-super-admin-main">
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={this.state.tab} onChange={this.handleChangeTab} aria-label="basic tabs example">
                    <Tab label="Metadados" {...this.a11yProps(0)} />
                    <Tab label="Conectores" {...this.a11yProps(1)} />
                    <Tab label="Projetos" {...this.a11yProps(2)} />
                    <Tab label="Usuários" {...this.a11yProps(3)} />
                    <Tab label="Esquemas" {...this.a11yProps(4)} />
                  </Tabs>
                </Box>
              </Box>

              <div className="rdp-super-admin-tab-content">
                {this.state.tab === 0 &&
                  <div style={{marginTop: "30px"}}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Nome do diretório"
                      defaultValue={this.state.directory.name}
                      style={{ width: "100%", "marginBottom": "20px" }}
                      onChange={(e) => this.onMetadataChange("name", e.target.value)}
                    />
                    <TextField
                      id="outlined-number"
                      label="Expiração em horas para perfil Admin"
                      type="number"
                      defaultValue={this.state.directory.jwt_expiration_hours_admin}
                      style={{ width: "100%", "marginBottom": "20px" }}
                      onChange={(e) => this.onMetadataChange("jwt_expiration_hours_admin", e.target.value)}
                    />
                    <TextField
                      id="outlined-number"
                      label="Expiração em horas para perfil Contribuidor"
                      type="number"
                      defaultValue={this.state.directory.jwt_expiration_hours_contributor}
                      style={{ width: "100%", "marginBottom": "20px" }}
                      onChange={(e) => this.onMetadataChange("jwt_expiration_hours_contributor", e.target.value)}
                    />
                    <TextField
                      id="outlined-number"
                      label="Expiração em horas para perfil Aluno"
                      type="number"
                      defaultValue={this.state.directory.jwt_expiration_hours_viewer}
                      style={{ width: "100%", "marginBottom": "20px" }}
                      onChange={(e) => this.onMetadataChange("jwt_expiration_hours_viewer", e.target.value)}
                    />

                    <Typography component="p">
                      Propriedades adicionais dos usuários
                    </Typography>
                    <Properties
                      values={this.state.user_required_properties}
                      onPropertyChange={this.onPropertyChange}
                      onRemoveProperty={this.removeProperty}
                      onAddProperty={this.addProperty}
                    />

                    <Stack direction="row" spacing={2}>
                      <Button variant="contained" startIcon={<SaveIcon />} onClick={() => this.onMetadataSave()}>Salvar metadados</Button>
                    </Stack>
                  </div>
                }

                {this.state.tab === 1 &&
                  <>
                    {this.state.directory.connectors.length > 0 && this.state.directory.connectors.map(function(connector, index) {
                      return <ListItem disablePadding key={index} onClick={() => _this.onConnectorClick(connector)}>
                        <ListItemButton>
                          <ListItemText primary={connector.label}/>
                        </ListItemButton>
                      </ListItem>
                    })}

                    {this.state.directory.connectors.length === 0 &&
                      <NoResults message="Nenhum conector cadastrado no diretório."/>
                    }

                    <Dialog
                      fullScreen
                      open={this.state.render_directory_connectors_modal}
                      onClose={() => this.closeDirectoryConnectorsModal()}
                      scroll={"paper"}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                      TransitionComponent={Transition}
                    >
                      <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.closeDirectoryConnectorsModal()}
                            aria-label="close"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Toolbar>
                      </AppBar>
                      <DialogTitle id="scroll-dialog-title">Novo conector</DialogTitle>
                      <DialogContent dividers={true}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                          <Select
                            style={{minWidth: "500px"}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={age}
                            label="Tipo"
                            onChange={this.onConnectorChange}
                          >
                            {this.state.connectors_schema.map(function(connector, index) {
                              return <MenuItem key={index} value={index}>{connector.label}</MenuItem>
                            })}
                          </Select>
                          {connector && connector.type === "UserCommunication_1" && connector.name === "SendGrid" &&
                            <ConnectorFormSendgrid
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {connector && connector.type === "DirectRegister_1" &&
                            <ConnectorFormDirectRegister
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {connector && connector.type === "Insights_1" &&
                            <ConnectorFormInsights
                              configuration={connector}
                              projects={this.state.projects}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {connector && connector.name === "RecoveryAccessData_1" &&
                            <ConnectorFormRecoveryAccess
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              directory={_this.props.match.params.directoryUid}
                            />
                          }
                          {connector && connector.name === "ReCaptcha_1" &&
                            <ConnectorFormRecaptcha
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              directory={_this.props.match.params.directoryUid}
                            />
                          }
                          {connector && connector.type === "Dam_1" &&
                            <ConnectorFormDam
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {connector && connector.type === "Policies_1" &&
                            <ConnectorFormPolicies
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              directory={_this.props.match.params.directoryUid}
                            />
                          }
                          {connector && connector.type === "AzureMedia_1" &&
                            <ConnectorFormAzureMedia
                              configuration={connector}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => this.closeDirectoryConnectorsModal()} type="button">Cancelar</Button>
                        <Button onClick={() => this.onConnectorCreate()} type="button">Criar conector</Button>
                      </DialogActions>
                    </Dialog>

                    <Fab onClick={() => this.openDirectoryConnectorsModal()} color="primary" aria-label="add" style={{position: "fixed", right: "20px", bottom: "20px"}}>
                      <AddIcon />
                    </Fab>

                    <Dialog
                      fullScreen
                      open={this.state.temp_connector !== null}
                      onClose={() => this.closeProjectConnectorsModal()}
                      scroll={"paper"}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                      TransitionComponent={Transition}
                    >
                      <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.closeTempConnectorModal()}
                            aria-label="close"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Toolbar>
                      </AppBar>
                      <DialogTitle id="scroll-dialog-title">Editar conector</DialogTitle>
                      <DialogContent dividers={true}>
                        <FormControl fullWidth>
                          {/* {this.state.temp_connector && ConnectorsService.getSchema(this.state.temp_connector).map(function(field, index) {
                          return <TextField
                            required
                            key={index}
                            id="outlined-required"
                            label={field.label}
                            style={{ width: "400px", marginBottom: "15px" }}
                            onChange={(e) => this.onConnectorPropChange(field.prop, e.target.value)}
                          />
                        })} */}
                          {this.state.temp_connector && this.state.temp_connector.type === "UserCommunication_1" && this.state.temp_connector.name === "SendGrid" &&
                            <ConnectorFormSendgrid
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.type === "DirectRegister_1" &&
                            <ConnectorFormDirectRegister
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.type === "Insights_1" &&
                            <ConnectorFormInsights
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              connectorUid={this.state.temp_connector.uid}
                              directoryUid={this.state.directory.uid}
                              projects={this.state.projects}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              onSendPowerBiRegistration={this.sendPowerBiRegistration}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.name === "RecoveryAccessData_1" &&
                            <ConnectorFormRecoveryAccess
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              directory={_this.props.match.params.directoryUid}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.name === "ReCaptcha_1" &&
                            <ConnectorFormRecaptcha
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                              directory={_this.props.match.params.directoryUid}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.type === "Dam_1" &&
                            <ConnectorFormDam
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.type === "Policies_1" &&
                            <ConnectorFormPolicies
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                          {this.state.temp_connector && this.state.temp_connector.type === "AzureMedia_1" &&
                            <ConnectorFormAzureMedia
                              configuration={this.parseIfIsJson(this.state.temp_connector.configuration)}
                              onChange={(configuration) => _this.onChangeTempConfiguration(configuration)}
                            />
                          }
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => this.closeTempConnectorModal()}>Cancelar</Button>
                        <Button onClick={() => this.onConnectorUpdate()}>Salvar conector</Button>
                      </DialogActions>
                    </Dialog>
                  </>
                }

                {this.state.tab === 2 &&
                  <>
                    {this.state.projects && this.state.projects.length > 0 && this.state.projects.map(function(project, index) {
                      return <ListItem disablePadding key={index}>
                        <ListItemButton component="a" href={`/#/directory/${_this.state.directory.uid}/project/${project.uid}`}>
                          <ListItemText primary={project.name} />
                        </ListItemButton>
                      </ListItem>
                    })}

                    {this.state.projects && this.state.projects.length === 0 &&
                      <NoResults message="Nenhum projeto cadastrado no diretório."/>
                    }

                    <Dialog
                      open={this.state.render_directory_projects_modal}
                      onClose={() => this.closeDirectoryProjectsModal()}
                      scroll={"paper"}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                    >
                      <DialogTitle id="scroll-dialog-title">Novo projeto</DialogTitle>
                      <DialogContent dividers={true}>
                        <FormControl fullWidth>
                          <TextField
                            required
                            id="outlined-required"
                            label="Nome do projeto"
                            style={{ width: "400px" }}
                            onChange={(e) => this.onProjectNameChange(e.target.value)}
                          />
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => this.closeDirectoryProjectsModal()}>Cancelar</Button>
                        <Button onClick={() => this.onProjectCreate()}>Criar projeto</Button>
                      </DialogActions>
                    </Dialog>

                    <Fab onClick={() => this.openDirectoryProjectsModal()} color="primary" aria-label="add" style={{position: "fixed", right: "20px", bottom: "20px"}}>
                      <AddIcon />
                    </Fab>
                  </>
                }

                {this.state.tab === 3 && (
                  <>
                    <UsersList
                      directory={this.state.directory}
                      onClickUser={(user) => this.getTempUser(user.uid)}
                      userSelected={this.state.temp_user}
                    />

                    {temp_user &&
                      <UserEdit
                        open
                        user={temp_user}
                        roles={this.state.roles}
                        projects={this.state.projects}
                        directory={this.state.directory}
                        onClose={() => this.closeDirectoryUserEditModal()}
                      />
                    }
                  </>
                )}

                {this.state.tab === 4 && (
                  <SchemasList
                    directory={this.state.directory}
                  />
                )}
              </div>
            </div>
          }
        </div>
      </>
    )
  }
}
