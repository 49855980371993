import React, {Component} from 'react';
import TextField from "@mui/material/TextField";


export default class ConnectorFormRecaptcha extends Component {
	state = {
		configuration: this.props.configuration,
	}

	onConnectorPropChange(prop, val) {
		var configuration = this.state.configuration;
		configuration[prop] = val;

		this.setState({
			"configuration": configuration
		});

		this.props.onChange(configuration);
	}

	render() {
		const {
			configuration
		} = this.state;
		const {
			public_key = "",
			secret_key = "",
		} = configuration;

		return <div className="rdp-super-admin-component-connector-form-recaptcha">
			{configuration &&
				<>
					<TextField
						required
						label="Chave pública"
						style={{width: "100%", marginTop: "15px"}}
						value={public_key}
						onChange={(e) => this.onConnectorPropChange("public_key", e.target.value)}
					/>
					<TextField
						required
						label="Chave privada"
						style={{width: "100%", marginTop: "15px"}}
						value={secret_key}
						onChange={(e) => this.onConnectorPropChange("secret_key", e.target.value)}
					/>
				</>
			}
		</div>
	}
}
