import React, {Component} from 'react';
import {
	Typography,
} from "@mui/material";
import {DirectoriesService} from "../../services/directories";
import RDPDamWidget from "../rdp-dam-widget/rdp-dam-widget";
import {DamService} from "../../services/dam";


export default class ConnectorFormPolicies extends Component {
	state = {
		configuration: this.props.configuration,
		projects: this.props.projects || [],
		selectedProject: null,
		authorizations: [],
		waitingPowerBiRegistration: false,
		terms: [],
		dam_modal_open: false,
	}

	componentDidMount() {
		// this.onConnectorPropChange("terms", []);
		this.generateUserToken().then(() => this.setState({loading: false}));
	}

	async generateUserToken() {
		try {
			var credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host))
			var directoryFirstUser = await DirectoriesService.getDirectoryFirstUser(this.props.directory);
			var userLoginData = await DirectoriesService.userVirtualLogin(directoryFirstUser.uid, credentials.login, credentials.password);
			window.localStorage.setItem("rdp-super-admin-user-token-" + window.location.host, JSON.stringify(userLoginData));
		} catch (e) {
			window.localStorage.removeItem("rdp-super-admin-user-token-" + window.location.host);
		}
	}

	// onConnectorPropChange(prop, val) {
	// 	const {
	// 		configuration,
	// 	} = this.state;
	// 	configuration[prop] = val;
	//
	// 	this.setState({
	// 		configuration
	// 	});
	//
	// 	this.props.onChange(configuration);
	// }

	renderTerms = configuration => {
		const {
			terms
		} = configuration;
		return (
			<>
				<Typography component="p" sx={{mt: 3}}>
					Termos
				</Typography>
			</>
		)
	}

	renderDAMWidget = () => {
		if (this.state.dam_modal_open === true) {
			return <RDPDamWidget
				type="attachments"
				onClose={this.onDamModalClose}
				onAssetChange={this.onDamFileChange}
				project={this.props.project}
			/>;
		}
	}

	getPublicUrl = async (assetUid) => {
		const accessToken = `${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`;

		return await DamService.getPublicContent(assetUid, accessToken).then((data) => {
			return `https://${window.rdp_domain}/storage/${window.rdp_api_version}/content?uid=${data.share_uid}&project_key=${window.rdp_project_key}&api_env=${window.rdp_api_env}`
		});
	}

	onDamModalClose = () => {
		this.setState({
			dam_modal_open: false,
		})
	}

	onDamFileChange = async (asset, file) => {
		const {
			parentUid,
			propertieUid
		} = this.state;

		const publicUrl = await this.getPublicUrl(file.uid);

		this.setConfigValue(parentUid, propertieUid, publicUrl);

		this.setState({
			dam_modal_open: false,
			parentUid: null,
			propertieUid: null,
		});
	}

	render() {
		const {
			configuration,
		} = this.state;
		return (
			<div className="rdp-super-admin-component-connector-form-terms">
				{configuration &&
					<>
						{this.renderTerms(configuration)}
						{this.renderDAMWidget()}
					</>
				}
			</div>
		)
	}
}
