import React, {Component} from 'react';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {ListItem, Select} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import {Add, Delete} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {MobileDatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";

class CourseFormFields extends Component {
	state = {
		properties: this.props.values.properties || [],
		errors: {},
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.values !== prevProps.values) {
			this.setState({
				properties: this.props.values.properties || [],
			});
		}
	}

	getFieldValue = (fieldName) => {
		return this.props.values[fieldName] || "";
	}

	addProperty = () => {
		this.setState({
			properties: [
				...this.state.properties,
				{
					name: "",
					regex: "",
					type: "text",
				}
			]
		});
	}

	removeProperty = (index) => {
		this.setState({
			properties: this.state.properties.filter((_, i) => i !== index)
		});
	}

	renderProValueField = (property, index) => {
		const {
			properties
		} = this.state;
		const types = {
			"Text": (
				<TextField
					label="Valor"
					name={`properties[${index}][value]`}
					fullWidth
					defaultValue={property.value || ""}
				/>
			),
			"Bool": (
				<FormControlLabel
					control={
						<Switch
							defaultChecked={property.value === "true"}
							name={`properties[${index}][value]`}
							fullWidth
						/>
					}
					label="Valor"
				/>
			),
			"TimeSpan": (
				<>
					<TextField
						name={`properties[${index}][value]`}
						fullWidth
						value={this.timeToString(properties[index].value)}
						sx={{display: "none"}}
					/>

					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<TimePicker
							ampm={false}
							openTo="hours"
							views={['hours', 'minutes', 'seconds']}
							inputFormat="HH:mm:ss"
							mask="__:__:__"
							label="Valor"
							value={dayjs(properties[index].value, "HH:mm:ss")}
							onChange={(newValue) => this.setPropertyValue(newValue, index)}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</>
			),
			"DateTime": (
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<TextField
						name={`properties[${index}][value]`}
						fullWidth
						value={this.dateToString(properties[index].value)}
						sx={{display: "none"}}
					/>
					<MobileDatePicker
						label="Valor"
						inputFormat="MM/DD/YYYY"
						value={dayjs(properties[index].value, "MM/DD/YYYY")}
						onChange={(newValue) => this.setPropertyValue(newValue, index)}
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider>
			)
		}

		return types[properties[index].type || property.type] || types["Text"];
	}

	timeToString = (time) => {
		return dayjs(time, "HH:mm:ss", null, true).format("HH:mm:ss");
	}

	dateToString = (time) => {
		return dayjs(time, "MM/DD/YYYY", null, true).format("MM/DD/YYYY");
	}

	setPropertyType = (e, index) => {
		this.setState({
			properties: this.state.properties.map((property, i) => {
				if (i === index) {
					property.type = e.target.value;
				}
				return property;
			})
		})
	}

	setPropertyValue = (value, index) => {
		this.setState({
			properties: this.state.properties.map((property, i) => {
				if (i === index) {
					property.value = value;
				}
				return property;
			})
		})
	}

	render() {
		return (
			<div>
				<FormControl
					fullWidth
					sx={{minWidth: "500px"}}
				>
					<TextField
						required
						label="Título"
						name="title"
						sx={{width: "100%", marginBottom: "15px"}}
						defaultValue={this.getFieldValue("title")}
						disabled
					/>
				</FormControl>

				<Typography component="p">
					Propriedades
				</Typography>

				<List sx={{width: "100%"}}>
					{this.state.properties.map((property, index) => (
						<ListItem key={index}>
							<Stack spacing={2} direction="row" sx={{width: "100%"}}>
								<FormControl
									fullWidth
									sx={{minWidth: "200px", marginBottom: "15px"}}
								>
									<TextField
										required
										label="Nome"
										name={`properties[${index}][name]`}
										fullWidth
										defaultValue={property.name || ""}
									/>
								</FormControl>

								<FormControl
									fullWidth
									sx={{minWidth: "200px", marginBottom: "15px"}}
								>
									<TextField
										label="Regex"
										name={`properties[${index}][regex_validation]`}
										fullWidth
										defaultValue={property.regex_validation || ""}
									/>
								</FormControl>

								<FormControl
									fullWidth
									sx={{minWidth: "200px", marginBottom: "15px"}}
								>
									<InputLabel id="schema-property-type-label">Tipo</InputLabel>
									<Select
										labelId="schema-property-type-label"
										label="Tipo"
										value={(this.state.properties[index].type || property.type || "")}
										name={`properties[${index}][type]`}
										onChange={(e) => this.setPropertyType(e, index)}
									>
										<MenuItem value="Text" defaultValue>Texto</MenuItem>
										<MenuItem value="Bool">Booleano</MenuItem>
										<MenuItem value="DateTime">Data</MenuItem>
										<MenuItem value="TimeSpan">Tempo</MenuItem>
										<MenuItem value="NumberInteger">Número inteiro</MenuItem>
										<MenuItem value="NumberDecimal">Número decimal</MenuItem>
										<MenuItem value="ImageUrl">Imagem</MenuItem>
										<MenuItem value="FileUrl">Arquivo</MenuItem>
									</Select>
								</FormControl>

								<FormControl
									fullWidth
									sx={{minWidth: "200px", marginBottom: "15px"}}
								>
									{this.renderProValueField(property, index)}
								</FormControl>

								<Box sx={{paddingBottom: "15px"}}>
									<Button
										variant="outlined"
										color="error"
										onClick={() => this.removeProperty(index)}
										sx={{height: "100%", marginBottom: "15px"}}
									>
										<Delete/>
									</Button>
								</Box>
							</Stack>
						</ListItem>
					))}

					<ListItem>
						<Stack
							spacing={2}
							direction="row"
							sx={{width: "100%"}}
							justifyContent="center"
						>
							<Button
								variant="contained"
								color="success"
								onClick={this.addProperty}
								disabled={this.props.editForm}
							>
								<Add/> Adicionar propriedade
							</Button>
						</Stack>
					</ListItem>
				</List>
			</div>
		);
	}
}

export default CourseFormFields;