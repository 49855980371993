import React, { Component } from 'react';
import {
  Autocomplete, Divider,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Select,
  Skeleton,
  Switch,
  FormGroup,
  FormLabel
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SketchPicker } from 'react-color';
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as S from "./connector-form-customization.styled";
import imageSvg from '../../assets/svgs/image.svg';
import RDPDamWidget from "../rdp-dam-widget/rdp-dam-widget";
import { DamService } from "../../services/dam";
import TabPanel from "../tab-panel/tab-panel";
import { DirectoriesService } from "../../services/directories";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";


export default class ConnectorFormCustomization extends Component {
  state = {
    configuration: [],
    openedColorPickerId: null,
    activeTab: 0,
    dam_modal_open: false,
    parentUid: null,
    propertieUid: null,
    loadedImagesArr: [],
    loading: true,
    login_types: [
      {
        value: 'text',
        label: 'Texto'
      },
      {
        value: 'email',
        label: 'E-mail'
      },
      {
        value: 'cpf',
        label: 'CPF'
      },
      {
        value: 'cnpj',
        label: 'CNPJ'
      }
    ],
    additional_fields: [
      {
        value: "cpf",
        label: "CPF",
      },
      {
        value: "birthday",
        label: "Data de nascimento",
      },
      {
        value: "rg",
        label: "RG",
      }
    ],
    languages: [
      {
        "language": "pt_br",
        "label": "Português(BR)",
        "enabled": true
      },
      {
        "language": "en",
        "label": "English",
        "enabled": false
      },
      {
        "language": "es",
        "label": "Español",
        "enabled": false
      }
    ]
  }

  componentDidMount() {
    Promise.all([
      this.generateUserToken(),
      this.getConfigs().then((configs) => this.setState({ configuration: configs }))
    ]).then(() => this.setState({ loading: false }));
  }

  async generateUserToken() {
    try {
      var credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host))
      var directoryFirstUser = await DirectoriesService.getDirectoryFirstUser(this.props.directory);
      var userLoginData = await DirectoriesService.userVirtualLogin(directoryFirstUser.uid, credentials.login, credentials.password);
      window.localStorage.setItem("rdp-super-admin-user-token-" + window.location.host, JSON.stringify(userLoginData));
    } catch (e) {
      window.localStorage.removeItem("rdp-super-admin-user-token-" + window.location.host);
    }
  }

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  async getConfigs() {
    const {
      configuration,
    } = this.props;
    let configs = configuration.configs || {};

    if (!(configs.find(c => c.uid === 'course_tree'))) {
      configs.push({
        uid: "course_tree",
        value: JSON.stringify([
          {
            uid: "perform_activities_sequentially",
            value: false,
          },
          {
            uid: "coming_soon_label",
            value: "Em breve",
          },
          {
            uid: "coming_soon_date_label",
            value: "Esta tarefa será liberada dia {{date}} às {{hours}}H {{minutes}}MIN",
          },
        ])
      });
    } else {
      let course_tree = configs.find(c => c.uid === 'course_tree');
      let value = JSON.parse(course_tree.value);

      if (!(value.find(c => c.uid === 'perform_activities_sequentially'))) {
        value.push({
          uid: "perform_activities_sequentially",
          value: false,
        });
      }
      if (!(value.find(c => c.uid === 'coming_soon_label'))) {
        value.push({
          uid: "coming_soon_label",
          value: "Em breve",
        });
      }
      if (!(value.find(c => c.uid === 'coming_soon_date_label'))) {
        value.push({
          uid: "coming_soon_date_label",
          value: "Esta tarefa será liberada dia {{date}} às {{hours}}H {{minutes}}MIN",
        });
      }

      course_tree.value = JSON.stringify(value);
    }

    if (!(configs.find(c => c.uid === 'login'))) {
      configs.push({
        uid: "login",
        value: JSON.stringify([
          {
            uid: "login_placeholder",
            value: "Digite seu e-mail",
          },
          {
            uid: "login_type",
            value: "email",
          },
          {
            uid: "show_register_button",
            value: false,
          },
        ])
      });
    } else {
      let login = configs.find(c => c.uid === 'login');
      let value = JSON.parse(login.value);

      if (!(value.find(c => c.uid === 'show_register_button'))) {
        value.push({
          uid: "show_register_button",
          value: false,
        });
      }

      login.value = JSON.stringify(value);
    }

    if (!(configs.find(c => c.uid === 'register'))) {
      configs.push({
        uid: "register",
        value: JSON.stringify([
          {
            uid: "show_login_button",
            value: true,
          },
          {
            uid: "additional_fields",
            value: JSON.stringify([
            ]),
          },
          {
            uid: "auto_login",
            value: false,
          },
          {
            uid: "username_property_to_register",
            value: "email",
          }
        ])
      });
    } else {
      let register = configs.find(c => c.uid === 'register');
      let value = JSON.parse(register.value);

      if (!(value.find(c => c.uid === 'auto_login'))) {
        value.push({
          uid: "auto_login",
          value: false,
        });
      }

      if (!(value.find(c => c.uid === 'username_property_to_register'))) {
        value.push({
          uid: "username_property_to_register",
          value: "email",
        });
      }

      register.value = JSON.stringify(value);
    }

    if (!(configs.find(c => c.uid === 'users_group'))) {
      configs.push({
        uid: "users_group",
        value: JSON.stringify([
          {
            uid: "enabled",
            value: false,
          },
          {
            uid: "title",
            value: "Gestão de grupos de usuários",
          },
          {
            uid: "subscription_button_label",
            value: "Inscrever-se",
          },
        ])
      });
    }

    if (!(configs.find(c => c.uid === 'composer'))) {
      configs.push({
        uid: "composer",
        value: JSON.stringify([
          {
            uid: "enable_download",
            value: false,
          },
          {
            uid: "enable_object_messages",
            value: false,
          }
        ])
      });
    }

    if (!(configs.find(c => c.uid === 'first_access'))) {
      configs.push({
        uid: "first_access",
        value: JSON.stringify([
          {
            uid: "show_first_access_button",
            value: false,
          },
        ])
      });
    }

    if (!(configs.find(c => c.uid === 'dynamic_recovery_password'))) {
      configs.push({
        uid: "dynamic_recovery_password",
        value: JSON.stringify([
          {
            uid: "enabled_types",
            value: ["email"],
          },
          {
            uid: "types",
            value: [
              {
                type: "personal_info",
                label: "Utilizar informações pessoais",
                formProps: {}
              },
              {
                type: "email",
                label: "Utilizar e-mail",
                formProps: {}
              }
            ],
          },
        ])
      });
    }

    if (!(configs.find(c => c.uid === 'common'))) {
      configs.push({
        uid: "common",
        value: JSON.stringify([
          {
            uid: "apresentation_background",
            value: null,
          },
          {
            uid: "primary_color",
            value: "#ffffff",
          },
          {
            uid: "secondary_color",
            value: "#ffffff",
          },
          {
            uid: "tertiary_color",
            value: "#ffffff",
          },
          {
            uid: "fourthiary_color",
            value: "#ffffff",
          },
          {
            uid: "modality",
            value: "courses",
          }
        ])
      });
    }

    if (!(configs.find(c => c.uid === 'header'))) {
      configs.push({
        uid: "header",
        value: JSON.stringify([
          {
            uid: "logotype",
            value: null,
          },
          {
            uid: "background",
            value: "#ffffff",
          },
          {
            uid: "buttons_background",
            value: "#ffffff",
          },
          {
            uid: "buttons_icon_background",
            value: "#ffffff",
          },
          {
            uid: "buttons_text_color",
            value: "#000000",
          },
          {
            uid: "dropdown_background",
            value: "#ffffff",
          },
          {
            uid: "dropdown_button_background",
            value: "#ffffff",
          },
          {
            uid: "dropdown_button_icon_background",
            value: "#ffffff",
          },
          {
            uid: "first_label_color",
            value: "#ffffff",
          },
          {
            uid: "notifications_header_color",
            value: "#ffffff",
          },
          {
            uid: "second_label_color",
            value: "#ffffff",
          },
        ])
      });
    }

    if (!(configs.find(c => c.uid === 'sidebar'))) {
      configs.push({
        uid: "sidebar",
        value: JSON.stringify([
          {
            uid: "active_button_background_color",
            value: "#ffffff",
          },
          {
            uid: "active_icon_color",
            value: "#ffffff",
          },
          {
            uid: "active_label_color",
            value: "#ffffff",
          },
          {
            uid: "background_color",
            value: "#000000",
          },
          {
            uid: "button_background_color",
            value: "#ffffff",
          },
          {
            uid: "icon_color",
            value: "#ffffff",
          },
          {
            uid: "item_border_color",
            value: "#ffffff",
          },
          {
            uid: "label_color",
            value: "#ffffff",
          },
          {
            uid: "course_menu_label",
            value: "Cursos",
          },
        ])
      });
    } else {
      const sidebar = configs.find(c => c.uid === 'sidebar');
      const sidebarConfig = JSON.parse(sidebar.value);
      if (!(sidebarConfig.find(c => c.uid === 'course_menu_label'))) {
        sidebarConfig.push({
          uid: "course_menu_label",
          value: "Cursos",
        });
        sidebar.value = JSON.stringify(sidebarConfig);
      }
    }

    configuration.configs = configs.filter(c => c.uid !== 'pages');

    this.props.onChange(configuration);

    return configuration;
  }

  getLabels(name) {
    try {
      const labels = {
        pt_br: {
          primary_color: 'Cor primária',
          secondary_color: 'Cor secundária',
          tertiary_color: 'Cor terciária',
          fourthiary_color: 'Quarta cor',
          modality: 'Modalidade',
          background: 'Cor de fundo',
          buttons_background: 'Cor de fundo dos botões',
          buttons_icon_background: 'Cor de fundo dos ícones dos botões',
          buttons_text_color: 'Cor do texto dos botões',
          dropdown_background: 'Cor de fundo do menu dropdown',
          dropdown_button_background: 'Cor de fundo dos botões do menu dropdown',
          dropdown_button_icon_background: 'Cor de fundo dos ícones dos botões do menu dropdown',
          first_label_color: 'Cor do primeiro texto',
          logotype: 'Logotipo',
          notifications_header_color: 'Cor do cabeçalho das notificações',
          second_label_color: 'Cor do segundo texto',
          header: 'Cabeçalho',
          sidebar: 'Menu lateral',
          active_button_background_color: 'Cor de fundo do botão ativo',
          active_icon_color: 'Cor do ícone ativo',
          active_label_color: 'Cor do texto ativo',
          background_color: 'Cor de fundo',
          button_background_color: 'Cor de fundo dos botões',
          icon_color: 'Cor dos ícones',
          item_border_color: 'Cor da borda dos itens',
          label_color: 'Cor do texto',
          common: 'Geral',
          apresentation_background: 'Imagem de fundo da tela de login',
          composer: "Composer",
          enable_download: "Habilitar download das composições",
          enable_object_messages: "Habilitar mensagens por objeto",
          dynamic_recovery_password: "Recuperação de senha dinâmica",
          enabled_types: "Tipos habilitados",
          personal_info: "Informações pessoais",
          email: "E-mail",
          show_first_access_button: "Mostrar botão de primeiro acesso",
          first_access: "Primeiro acesso",
          login: "Login",
          login_placeholder: "Login placeholder",
          login_type: "Tipo de login",
          course_tree: "Trilha dos cursos",
          perform_activities_sequentially: "Realizar atividades sequencialmente",
          coming_soon_label: "Label de em breve",
          coming_soon_date_label: "Label de data de em breve",
          users_group: "Grupo de usuários",
          enabled: "Habilitado",
          schema_uid: "Schema UID",
          title: "Título",
          subscription_button_label: "Label do botão de inscrição",
          course_menu_label: "Label do menu de cursos",
          show_register_button: "Mostrar botão de registro",
          show_login_button: "Mostrar botão de login",
          additional_fields: "Campos adicionais",
          register: "Registro",
          auto_login: "Auto login",
        }
      };

      return labels["pt_br"][name];
    } catch (e) {
      return name;
    }
  }

  handleChangeColor = (color, parentUid, id) => {
    this.setConfigValue(parentUid, id, color.hex);
  }

  handleOpenColorPicker = (id) => {
    if (this.state.openedColorPickerId === id) {
      this.setState({ openedColorPickerId: null });
    } else {
      this.setState({
        openedColorPickerId: id
      });
    }
  }

  renderColorInput = (parentUid, propertie, index) => {
    const {
      openedColorPickerId
    } = this.state;

    return (
      <FormControl
        fullWidth
        key={index}
        variant="outlined"
        sx={{
          position: 'relative',
        }}
      >
        <InputLabel
          htmlFor={propertie.uid}
          sx={{
            width: 300,
            ml: "auto",
            mr: "auto",
          }}
        >
          {this.getLabels(propertie.uid)}
        </InputLabel>
        <OutlinedInput
          id={propertie.uid}
          type="text"
          value={propertie.value}
          onChange={(e) => {
            const value = e.target.value;
            const hexColor = value.startsWith('#') ? value : `#${value}`;
            if (hexColor.length <= 7) {
              this.handleChangeColor({ hex: hexColor }, parentUid, propertie.uid)
            } else {
              e.target.value = propertie.value.slice(0, 7);
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <Button
                onClick={e => this.handleOpenColorPicker(`${parentUid}_${propertie.uid}`)}
              >
                <Box
                  component="span"
                  sx={{
                    bgcolor: propertie.value,
                    width: 30,
                    height: 30,
                    border: "1px solid black",
                  }}
                />
              </Button>
            </InputAdornment>
          }
          label={this.getLabels(propertie.uid)}
          fullWidth
        />
        {(`${parentUid}_${propertie.uid}` === String(openedColorPickerId)) && (
          <S.ColorPickerContainer
            onClick={e => this.setState({ openedColorPickerId: null })}
          >
            <SketchPicker
              color={propertie.value}
              styles={{
                picker: {
                  width: '280px',
                  padding: '10px 10px 0',
                  marginTop: '0',
                  marginRight: '80px',
                  boxSizing: 'initial',
                  background: '#fff',
                  borderRadius: '4px',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  zIndex: 10
                }
              }}
              onChange={color => this.handleChangeColor(color, parentUid, propertie.uid)}
            />
          </S.ColorPickerContainer>
        )}
      </FormControl>
    )
  }

  handleChangeTab = (e, newValue) => {
    this.setState({
      activeTab: newValue
    });
  }

  renderConfigs = () => {
    const {
      configuration,
      activeTab
    } = this.state;
    const {
      configs = []
    } = configuration;

    const tabs = configs.filter(c => (c.uid !== 'primary_color' && c.uid !== 'secondy_color' && c.uid !== 'tertiary_color' && c.uid !== 'fourthiary_color' && c.uid !== 'modality'))

    return (
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={this.handleChangeTab}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((config, index) => <Tab key={index} label={this.getLabels(config.uid)} {...this.a11yProps(index)} />)}
          </Tabs>
        </Box>
        {
          tabs.map((config, index) => {
            switch (config.uid) {
              case "header":
                return (
                  <TabPanel key={index} value={activeTab} index={index} sx={{ h: "100%" }}>
                    <Stack component="div" spacing={2} sx={{ h: "100%" }}>
                      {this.renderHeader(config)}
                    </Stack>
                  </TabPanel>
                )
              case "sidebar":
                return (
                  <TabPanel key={index} value={activeTab} index={index}>
                    <Stack component="div" spacing={2}>
                      {this.renderSidebar(config)}
                    </Stack>
                  </TabPanel>
                )
              case "common":
                return (
                  <TabPanel key={index} value={activeTab} index={index}>
                    <Stack component="div" spacing={2}>
                      {this.renderCommon(config)}
                    </Stack>
                  </TabPanel>
                )
              case "composer":
                return (
                  <TabPanel key={index} value={activeTab} index={index}>
                    <Stack component="div" spacing={2}>
                      {this.renderComposer(config)}
                    </Stack>
                  </TabPanel>
                )
              case "dynamic_recovery_password":
                return (
                  <TabPanel key={index} value={activeTab} index={index}>
                    <Stack component="div" spacing={2}>
                      {this.renderDynamicRecoveryPassword(config)}
                    </Stack>
                  </TabPanel>
                )
              case "first_access":
                return (
                  <TabPanel key={index} value={activeTab} index={index}>
                    <Stack component="div" spacing={2}>
                      {this.renderFirstAccess(config)}
                    </Stack>
                  </TabPanel>
                )
              case "login":
                return (
                  <TabPanel key={index} value={activeTab} index={index}>
                    <Stack component="div" spacing={2}>
                      {this.renderLogin(config)}
                    </Stack>
                  </TabPanel>
                )
              case "register":
                return (
                  <TabPanel key={index} value={activeTab} index={index}>
                    <Stack component="div" spacing={2}>
                      {this.renderRegister(config)}
                    </Stack>
                  </TabPanel>
                )
              case "course_tree":
                return (
                  <TabPanel key={index} value={activeTab} index={index}>
                    <Stack component="div" spacing={2}>
                      {this.renderCourseTree(config)}
                    </Stack>
                  </TabPanel>
                )
              case "users_group":
                return (
                  <TabPanel key={index} value={activeTab} index={index}>
                    <Stack component="div" spacing={2}>
                      {this.renderUsersGroup(config)}
                    </Stack>
                  </TabPanel>
                )
            }
          })
        }
      </Box>
    );
  }

  renderDynamicRecoveryPassword = (config) => {
    const parsedValue = JSON.parse(config.value);

    return (
      <>
        {
          parsedValue.map((propertie, index) => {
            switch (propertie.uid) {
              case "enabled_types":
                return this.renderDynamicFormEnabledTypes(config.uid, propertie, index);
              case "types":
                return this.renderDynamicFormTypes(config.uid, propertie, index);
              default:
                console.warn("Not implemented for uid: ", propertie.uid);
                return null;
            }
          })
        }
      </>
    )
  }

  renderDynamicFormTypes = (parentUid, propertie, index) => {
    const {
      configuration
    } = this.state;
    const {
      configs = []
    } = configuration;
    const config = configs.find(c => c.uid === parentUid);
    const parsedValue = JSON.parse(config.value);
    const propertieValue = parsedValue.find(p => p.uid === propertie.uid);
    const values = propertieValue.value;
    const types = [
      {
        key: "personal_info",
        render: ({ type, value }) => (
          <>
            <TextField
              required
              id="outlined-api-key"
              label="Placeholder do campo de login"
              sx={{ width: "100%", marginTop: "15px" }}
              value={value.formProps.inputLoginLabel || undefined}
              onChange={(e) => {
                handleTypeFormPropsChange({
                  type: type.key,
                  formProps: value.formProps,
                  name: "inputLoginLabel",
                  value: e.target.value
                })
              }}
            />

            <FormControl fullWidth key={index}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={this.state.login_types}
                getOptionLabel={(option) => option.label}
                value={typeof value.formProps.inputLoginType === "string" ? [] : this.state.login_types.filter(o => Array.from(value.formProps.inputLoginType || []).includes(o.value))}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  handleTypeFormPropsChange({
                    type: type.key,
                    formProps: value.formProps,
                    name: "inputLoginType",
                    value: newValue.map(n => n.value)
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo do campo de login"
                    placeholder="Tipo do campo de login"
                  />
                )}
              />
            </FormControl>

            <TextField
              required
              id="outlined-api-key"
              label="Label do botão de confirmação do login"
              sx={{ width: "100%", marginTop: "15px" }}
              value={value.formProps.inputLoginConfirmButtonLabel || undefined}
              onChange={(e) => {
                handleTypeFormPropsChange({
                  type: type.key,
                  formProps: value.formProps,
                  name: "inputLoginConfirmButtonLabel",
                  value: e.target.value
                })
              }}
            />

            <TextField
              required
              id="outlined-api-key"
              label="Texto da pergunta pessoal"
              sx={{ width: "100%", marginTop: "15px" }}
              value={value.formProps.personalQuestionLabel || undefined}
              onChange={(e) => {
                handleTypeFormPropsChange({
                  type: type.key,
                  formProps: value.formProps,
                  name: "personalQuestionLabel",
                  value: e.target.value
                })
              }}
            />

            <FormControl fullWidth>
              <InputLabel>
                Tipo do campo de pergunta pessoal
              </InputLabel>
              <Select
                value={value.formProps.personalQuestionType || undefined}
                onChange={(e) => {
                  handleTypeFormPropsChange({
                    type: type.key,
                    formProps: value.formProps,
                    name: "personalQuestionType",
                    value: e.target.value
                  })
                }}
              >
                <MenuItem value="cpf">CPF</MenuItem>
                <MenuItem value="text">Texto</MenuItem>
                <MenuItem value="date">Data</MenuItem>
                <MenuItem value="email">E-Mail</MenuItem>
                <MenuItem value="phone">Telefone</MenuItem>
                <MenuItem value="number">Número</MenuItem>
                <MenuItem value="CNPJ">CNPJ</MenuItem>
              </Select>
            </FormControl>

            <TextField
              required
              id="outlined-api-key"
              label="Texto do botão de confirmação da pergunta pessoal"
              sx={{ width: "100%", marginTop: "15px" }}
              value={value.formProps.personalQuestionConfirmButtonLabel || undefined}
              onChange={(e) => {
                handleTypeFormPropsChange({
                  type: type.key,
                  formProps: value.formProps,
                  name: "personalQuestionConfirmButtonLabel",
                  value: e.target.value
                })
              }}
            />

            <TextField
              required
              id="outlined-api-key"
              label="Placeholder do campo de resposta da pergunta pessoal"
              sx={{ width: "100%", marginTop: "15px" }}
              value={value.formProps.personalAnswerLabel || undefined}
              onChange={(e) => {
                handleTypeFormPropsChange({
                  type: type.key,
                  formProps: value.formProps,
                  name: "personalAnswerLabel",
                  value: e.target.value
                })
              }}
            />
          </>
        )
      },
      {
        key: "email",
        render: ({ type, value }) => (
          <>
            <TextField
              required
              id="outlined-api-key"
              label="Placeholder do campo de e-mail"
              sx={{ width: "100%", marginTop: "15px" }}
              value={value.formProps.inputPlaceholder || undefined}
              onChange={(e) => {
                handleTypeFormPropsChange({
                  type: type.key,
                  formProps: value.formProps,
                  name: "inputPlaceholder",
                  value: e.target.value
                })
              }}
            />
          </>
        )
      },
    ]

    const handleTypeFormPropsChange = ({ type, formProps, name, value }) => {
      const newFormProps = { ...formProps, [name]: value }
      this.setConfigValue(parentUid, propertie.uid, values.map(v => v.type === type ? { ...v, formProps: newFormProps } : v))
    }

    const handleTypeValueChange = ({ type, name, value }) => {
      this.setConfigValue(parentUid, propertie.uid, values.map(v => v.type === type ? { ...v, [name]: value } : v))
    }

    return (
      <Stack key={index} component="div" spacing={2}>
        <Typography variant="div" display="block">
          Configurações dos formulários
        </Typography>

        <Divider />

        {types.map((type, index) => {
          const value = values.find(v => v.type === type.key);
          return (
            <>
              <Typography variant="div" display="block">
                {this.getLabels(type.key)}
              </Typography>

              <TextField
                required
                id="outlined-api-key"
                label="Menu Label"
                sx={{ width: "100%", marginTop: "15px" }}
                value={value.label || undefined}
                onChange={(e) => {
                  handleTypeValueChange({
                    type: type.key,
                    name: "label",
                    value: e.target.value
                  })
                }}
              />

              {type.render({ type, value })}
              <Divider />
            </>
          )
        })}
      </Stack>
    );
  }

  renderDynamicFormEnabledTypes = (parentUid, propertie, index) => {
    const {
      configuration
    } = this.state;
    const {
      configs = []
    } = configuration;
    const config = configs.find(c => c.uid === parentUid);
    const parsedValue = JSON.parse(config.value);
    const propertieValue = parsedValue.find(p => p.uid === propertie.uid);
    const types = parsedValue.find(p => p.uid === "types");
    const options = types.value.map(o => ({ value: o.type, label: o.label }));
    const defaultValue = options.filter(o => propertieValue.value.includes(o.value));

    return (
      <FormControl fullWidth key={index}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={options}
          getOptionLabel={(option) => option.label}
          value={defaultValue}
          filterSelectedOptions
          onChange={(event, newValue) => {
            this.setConfigValue(parentUid, propertie.uid, newValue.map(n => n.value));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={this.getLabels(propertie.uid)}
              placeholder={this.getLabels(propertie.uid)}
            />
          )}
        />
      </FormControl>
    )
  }

  renderCombobox = (parentUid, propertie, index, options) => {
    const {
      configuration
    } = this.state;
    const {
      configs = []
    } = configuration;
    const config = configs.find(c => c.uid === parentUid);
    const parsedValue = JSON.parse(config.value);
    const propertieValue = parsedValue.find(p => p.uid === propertie.uid);
    const defaultValue = options.filter(o => propertieValue.value.includes(o.value));

    return (
      <FormControl fullWidth key={index}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={options}
          getOptionLabel={(option) => option.label}
          value={defaultValue}
          filterSelectedOptions
          onChange={(event, newValue) => {
            this.setConfigValue(parentUid, propertie.uid, newValue.map(n => n.value));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={this.getLabels(propertie.uid)}
              placeholder={this.getLabels(propertie.uid)}
            />
          )}
        />
      </FormControl>
    )
  }

  renderHeader = (config) => {
    const parsedValue = JSON.parse(config.value);

    return (
      <>
        {
          parsedValue.map((propertie, index) => {
            switch (propertie.uid) {
              case "logotype":
                return this.renderImageInput(config.uid, propertie, index);
              case "background":
              case "buttons_background":
              case "buttons_icon_background":
              case "buttons_text_color":
              case "dropdown_background":
              case "dropdown_button_background":
              case "dropdown_button_icon_background":
              case "first_label_color":
              case "notifications_header_color":
              case "second_label_color":
                return this.renderColorInput(config.uid, propertie, index);
              default:
                console.warn("Not implemented for uid: ", propertie.uid);
                return null;
            }
          })
        }
      </>
    )
  }

  renderSidebar = (config) => {
    const parsedValue = JSON.parse(config.value);

    return (
      <>
        {
          parsedValue.map((propertie, index) => {
            switch (propertie.uid) {
              case "active_button_background_color":
              case "active_icon_color":
              case "active_label_color":
              case "background_color":
              case "button_background_color":
              case "icon_color":
              case "item_border_color":
              case "label_color":
                return this.renderColorInput(config.uid, propertie, index);
              case "course_menu_label":
                return this.renderTextField(config.uid, propertie, index);
              default:
                console.warn("Not implemented for uid: ", propertie.uid);
                return null;
            }
          })
        }
      </>
    )
  }

  renderCommon = (config) => {
    var parsedValue = JSON.parse(config.value);
    if (parsedValue.filter(val => val.uid === "modality").length === 0) {
      parsedValue.push({
        "uid": "modality",
        "value": "courses"
      })
    }

    if (parsedValue.filter(val => val.uid === "languages").length === 0) {
      parsedValue.push({
        "uid": "languages",
        "value": JSON.stringify([
          {
            "language": "pt_br",
            "label": "Português(BR)",
            "enabled": true
          },
          {
            "language": "en",
            "label": "English",
            "enabled": false
          },
          {
            "language": "es",
            "label": "Español",
            "enabled": false
          }
        ])
      })
    }

    return (
      <>
        {
          parsedValue.map((propertie, index) => {
            switch (propertie.uid) {
              case "apresentation_background":
                return this.renderImageInput(config.uid, propertie, index);
              case "primary_color":
              case "secondary_color":
              case "tertiary_color":
              case "fourthiary_color":
                return this.renderColorInput(config.uid, propertie, index);
              case "modality":
                return this.renderModality(config);
              case "languages":
                return this.renderLanguages(config);
              default:
                console.warn("Not implemented for uid: ", propertie.uid);
                return null;
            }
          })
        }
      </>
    )
  }

  renderComposer = (config) => {
    const parsedValue = JSON.parse(config.value);

    if (parsedValue.filter(val => val.uid === "enable_object_messages").length === 0) {
      parsedValue.push({
        "uid": "enable_object_messages",
        "value": false
      })
    }

    return (
      <>
        {
          parsedValue.map((propertie, index) => {
            switch (propertie.uid) {
              case "enable_download":
                return this.renderSwitchInput(config.uid, propertie, index);
              case "enable_object_messages":
                return this.renderSwitchInput(config.uid, propertie, index);
              default:
                console.warn("Not implemented for uid: ", propertie.uid);
                return null;
            }
          })
        }
      </>
    )
  }

  renderFirstAccess = (config) => {
    const parsedValue = JSON.parse(config.value);

    return (
      <>
        {
          parsedValue.map((propertie, index) => {
            switch (propertie.uid) {
              case "show_first_access_button":
                return this.renderSwitchInput(config.uid, propertie, index);
              default:
                console.warn("Not implemented for uid: ", propertie.uid);
                return null;
            }
          })
        }
      </>
    )
  }

  renderLogin = (config) => {
    const parsedValue = JSON.parse(config.value);

    return (
      <>
        {
          parsedValue.map((propertie, index) => {
            switch (propertie.uid) {
              case "login_placeholder":
                return this.renderTextField(config.uid, propertie, index);
              case "login_type":
                return this.renderCombobox(config.uid, propertie, index, this.state.login_types);
              case "show_register_button":
                return this.renderSwitchInput(config.uid, propertie, index);
              default:
                console.warn("Not implemented for uid: ", propertie.uid);
                return null;
            }
          })
        }
      </>
    )
  }

  renderUsernamePropertyToRegister = (config, uid, property, index) => {
    var vl = JSON.parse(config.value).find(item => item.uid === "username_property_to_register")
    console.log(vl)
    return <FormControl fullWidth>
      <InputLabel>
        Propriedade a ser considerada no campo de usuário ao se registrar
      </InputLabel>
      <Select
        value={vl.value || undefined}
        onChange={(e) => {
          this.setConfigValue(config.uid, "username_property_to_register", e.target.value)
        }}
      >
        <MenuItem value="cpf">CPF</MenuItem>
        <MenuItem value="email">E-mail</MenuItem>
      </Select>
    </FormControl>
  }

  renderModality = (config) => {
    var vl = JSON.parse(config.value).find(item => item.uid === "modality")
    if (vl === undefined) {
      vl = {
        "uid": "modality",
        "value": "courses"
      }
    }

    console.log(vl)
    return <FormControl fullWidth>
      <InputLabel>
        Modalidade
      </InputLabel>
      <Select
        value={vl.value || undefined}
        onChange={(e) => {
          this.setConfigValue(config.uid, "modality", e.target.value)
        }}
      >
        <MenuItem value="courses">Cursos</MenuItem>
        <MenuItem value="academies">Academias</MenuItem>
      </Select>
    </FormControl>
  }

  renderLanguages = (config) => {
    var vl = JSON.parse(config.value).find(item => item.uid === "languages")
    if (vl === undefined) {
      vl = {
        "uid": "languages",
        "value": JSON.stringify([
          {
            "language": "pt_br",
            "label": "Português(BR)",
            "enabled": true
          },
          {
            "language": "en",
            "label": "English",
            "enabled": false
          },
          {
            "language": "es",
            "label": "Español",
            "enabled": false
          }
        ])
      }
    }

    // return <button onClick={() => this.setConfigValue(config.uid, "languages", JSON.stringify([
    //   {
    //     "language": "pt_br",
    //     "label": "Português(BR)",
    //     "enabled": true
    //   },
    //   {
    //     "language": "en",
    //     "label": "English",
    //     "enabled": false
    //   },
    //   {
    //     "language": "es",
    //     "label": "Español",
    //     "enabled": false
    //   }
    // ]))}>oioioioioi</button>

    console.log(vl)
    var langs = JSON.parse(vl.value);
    var _this = this;
    return <FormControl fullWidth>
      <FormLabel component="legend">Idiomas</FormLabel>
      <FormGroup>
        {langs.map(function (lang, index) {
          return <FormControlLabel
            control={<Checkbox
              key={index}
              name={lang.language}
              defaultChecked={lang.enabled}
              onChange={(e) => _this.onChangeLanguage(lang.language, e, config)}
            />}
            label={lang.label}
          />
        })}
      </FormGroup>
    </FormControl>
  }

  onChangeLanguage = (lang, e, config) => {
    var _this = this;
    var langs = this.state.languages;

    if (JSON.parse(config.value).filter(item => item.uid === "languages").length > 0) {
      langs = JSON.parse(JSON.parse(config.value).find(item => item.uid === "languages").value);
    };

    for (var i = 0; i < langs.length; i++) {
      if (langs[i].language === lang) {
        langs[i].enabled = e.target.checked;
      };
    };

    // {
    //   "uid": "languages",
    //   "value": JSON.stringify([
    //     {
    //       "language": "pt_br",
    //       "label": "Português(BR)",
    //       "enabled": true
    //     },
    //     {
    //       "language": "en",
    //       "label": "English",
    //       "enabled": false
    //     },
    //     {
    //       "language": "es",
    //       "label": "Español",
    //       "enabled": false
    //     }
    //   ])
    // }

    window.setTimeout(function() {
      _this.setConfigValue(config.uid, "languages", JSON.stringify(langs))
    }, 100);
  }

  renderRegister = (config) => {
    const parsedValue = JSON.parse(config.value);

    return (
      <>
        {
          parsedValue.map((propertie, index) => {
            switch (propertie.uid) {
              case "additional_fields":
                return this.renderCombobox(config.uid, propertie, index, this.state.additional_fields);
              case "show_login_button":
                return this.renderSwitchInput(config.uid, propertie, index);
              case "auto_login":
                return this.renderSwitchInput(config.uid, propertie, index);
              case "username_property_to_register":
                return this.renderUsernamePropertyToRegister(config, config.uid, propertie, index)
              default:
                console.warn("Not implemented for uid: ", propertie.uid);
                return null;
            }
          })
        }
      </>
    )
  }

  renderCourseTree = (config) => {
    const parsedValue = JSON.parse(config.value);

    return (
      <>
        {
          parsedValue.map((propertie, index) => {
            switch (propertie.uid) {
              case "perform_activities_sequentially":
                return this.renderSwitchInput(config.uid, propertie, index);
              case "coming_soon_label":
              case "coming_soon_date_label":
                return this.renderTextField(config.uid, propertie, index);
              default:
                console.warn("Not implemented for uid: ", propertie.uid);
                return null;
            }
          })
        }
      </>
    )
  }

  renderUsersGroup = (config) => {
    const parsedValue = JSON.parse(config.value);

    const enabled = parsedValue.find(propertie => propertie.uid === "enabled");

    return (
      <>
        {this.renderSwitchInput(config.uid, enabled, parsedValue.indexOf(enabled))}
        {enabled && enabled.value &&
          parsedValue.map((propertie, index) => {
            switch (propertie.uid) {
              case "title":
                return this.renderTextField(config.uid, propertie, index);
              case "subscription_button_label":
                return this.renderTextField(config.uid, propertie, index);
              default:
                console.warn("Not implemented for uid: ", propertie.uid);
                return null;
            }
          })
        }
      </>
    )
  }

  renderSelectInput = (uid, propertie, index, options) => {
    return (
      <FormControl key={index} fullWidth>
        <InputLabel id={`${propertie.uid}-label`}>{this.getLabels(propertie.uid)}</InputLabel>
        <Select
          labelId={`${propertie.uid}-label`}
          id={`${propertie.uid}-select`}
          value={propertie.value}
          label={this.getLabels(propertie.uid)}
          onChange={(e) => this.handleSelectChange(e, uid, propertie.uid)}
        >
          {
            options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    )
  }

  handleSelectChange = (e, uid, propertieUid) => {
    this.setConfigValue(uid, propertieUid, e.target.value);
  }

  handleLoadedImage = (e, image) => {
    this.setState({
      loadedImagesArr: [...this.state.loadedImagesArr, image]
    });
  }

  renderImageInput = (parentUid, propertie, index) => {
    return (
      <Button
        key={index}
        sx={{
          border: "1px dashed grey",
          borderRadius: "8px",
          position: "relative",
          padding: "10px",
          width: "300px",
          ml: "auto",
          mr: "auto",
        }}
        onClick={e => {
          this.setState({
            dam_modal_open: true,
            parentUid,
            propertieUid: propertie.uid,
          });
        }}
      >
        <S.ImageLabel>
          {this.getLabels(propertie.uid)}
        </S.ImageLabel>

        {
          propertie.value ? (
            <>
              <S.Img
                key={index}
                src={imageSvg}
                srcSet={propertie.value}
                alt={propertie.uid}
                loading="lazy"
                isLoading={!this.state.loadedImagesArr.includes(`${parentUid}_${propertie.uid}`)}
                onLoad={e => this.handleLoadedImage(e, `${parentUid}_${propertie.uid}`)}
              />
              {
                !this.state.loadedImagesArr.includes(`${parentUid}_${propertie.uid}`) && (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={300}
                    height={300}
                  />
                )
              }
            </>
          ) : (
            <S.SvgNoImage
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
            </S.SvgNoImage>
          )
        }
      </Button>
    );
  }

  renderSwitchInput = (parentUid, propertie, index) => {
    return (
      <FormControlLabel
        key={index}
        control={
          <Switch
            key={index}
            checked={propertie.value}
            onChange={e => this.handleSwitchChange(e, parentUid, propertie.uid)}
          />
        }
        label={this.getLabels(propertie.uid)}
      />
    );
  }

  handleSwitchChange = (e, parentUid, propertieUid) => {
    this.setConfigValue(parentUid, propertieUid, e.target.checked);
  }

  renderTextField = (parentUid, propertie, index) => {
    return (
      <TextField
        key={index}
        label={this.getLabels(propertie.uid)}
        value={propertie.value}
        onChange={e => this.handleInputChange(e, parentUid, propertie.uid)}
      />
    );
  }

  handleInputChange = (e, parentUid, propertieUid) => {
    this.setConfigValue(parentUid, propertieUid, e.target.value);
  }

  renderDAMWidget = () => {
    if (this.state.dam_modal_open === true) {
      return <RDPDamWidget
        type="image"
        onClose={this.onDamModalClose}
        onAssetChange={this.onDamFileChange}
        project={this.props.project}
      />;
    }
  }

  getPublicUrl = async (assetUid) => {
    const accessToken = `${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`;

    return await DamService.getPublicContent(assetUid, accessToken).then((data) => {
      return `https://${window.rdp_domain}/storage/${window.rdp_api_version}/content?uid=${data.share_uid}&project_key=${window.rdp_project_key}&api_env=${window.rdp_api_env}`
    });
  }

  onDamFileChange = async (asset, file) => {
    const {
      parentUid,
      propertieUid
    } = this.state;

    const publicUrl = await this.getPublicUrl(file.uid);

    this.setConfigValue(parentUid, propertieUid, publicUrl);

    this.setState({
      dam_modal_open: false,
      parentUid: null,
      propertieUid: null,
    });
  }

  setConfigValue = (parentUid, propertieUid, value) => {
    const { configuration } = this.state;

    if (parentUid === "composer") {
      var tempComposer = JSON.parse(configuration.configs.find(cnf => cnf.uid === "composer").value)

      if (propertieUid === "enable_object_messages") {
        if (tempComposer.find(cnfg => cnfg.uid === "enable_object_messages") === undefined) {
          tempComposer.push({
            "uid": "enable_object_messages",
            "value": value
          })
        }
      }

      configuration.configs.find(cnf => cnf.uid === "composer").value = JSON.stringify(tempComposer);
    }

    if (parentUid === "common") {
      var tempCommon = JSON.parse(configuration.configs.find(cnf => cnf.uid === "common").value)

      if (propertieUid === "modality") {
        if (tempCommon.find(cnfg => cnfg.uid === "modality") === undefined) {
          tempCommon.push({
            "uid": "modality",
            "value": value
          })
        }
      }

      if (propertieUid === "languages") {
        if (tempCommon.find(cnfg => cnfg.uid === "languages") === undefined) {
          tempCommon.push({
            "uid": "languages",
            "value": JSON.stringify([
              {
                "language": "pt_br",
                "label": "Português(BR)",
                "enabled": true
              },
              {
                "language": "en",
                "label": "English",
                "enabled": false
              },
              {
                "language": "es",
                "label": "Español",
                "enabled": false
              }
            ])
            // "value": JSON.stringify({
            //   "pt_br": true,
            //   "en": false,
            //   "es": false
            // })
          })
        }
      }
      
      configuration.configs.find(cnf => cnf.uid === "common").value = JSON.stringify(tempCommon);
    }

    const newConfiguration = {
      ...configuration,
      configs: configuration.configs.map(config => {
        if (parentUid === config.uid) {
          const parsedValue = JSON.parse(config.value);
          return {
            ...config,
            value: JSON.stringify(parsedValue.map(c => {
              if (c.uid === propertieUid) {
                return {
                  ...c,
                  value,
                };
              }

              return c;
            }))
          }
        }

        return config;
      }),
    };

    this.setState({
      configuration: newConfiguration,
    });

    this.props.onChange(newConfiguration);
  }

  onDamModalClose = () => {
    this.setState({
      dam_modal_open: false,
    })
  }

  render() {
    return <S.Container className="rdp-super-admin-component-connector-form-customization">
      {this.state.loading && (
        <Skeleton animation="wave" variant="rectangular" width={300} height={300} />
      )}
      {(this.state.configuration && !this.state.loading) &&
        <>
          {this.renderConfigs()}
          {this.renderDAMWidget()}
        </>
      }
    </S.Container>
  }
}
