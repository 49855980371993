import React, {Component} from 'react';
import {Slider} from "@mui/material";

class ResizeSlider extends Component {
	render() {
		const {
			orientation = "horizontal",
			defaultValue = 0,
			onChange = () => {},
			max,
			marginLeft = "0px",
			marginTop = "0px",
		} = this.props;
		let maxValue = orientation === "horizontal" ? window.innerWidth : window.innerHeight;
		if (max) {
			maxValue = max;
		}

		return (
			<Slider
				size="small"
				defaultValue={defaultValue}
				min={0}
				orientation={orientation}
				max={maxValue}
				aria-label="Small"
				valueLabelDisplay="off"
				onChange={onChange}
				sx={{
					width: orientation === "horizontal" ? "100%" : "auto",
					height: orientation === "horizontal" ? "auto" : "100%",
					position: "fixed",
					top: orientation === "horizontal" ? `calc(50% - (25px + ${marginTop}))` : "0",
					left: orientation === "horizontal" ? "0" : `calc(50% - (25px + ${marginLeft}))`,
					zIndex: 1201,
					pointerEvents: "none",
					["& .MuiSlider-track"]: {
						backgroundColor: "transparent",
					},
					["& .MuiSlider-rail"]: {
						backgroundColor: "transparent",
					},
					["& .MuiSlider-thumb"]: {
						height: orientation === "horizontal" ? "50px" : "8px",
						width: orientation === "horizontal" ? "8px" : "50px",
						borderRadius: "4px",
						pointerEvents: "auto",
					}
				}}
			/>
		);
	}
}

export default ResizeSlider;