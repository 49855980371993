import React, {Component} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {CircularProgress, DialogContentText} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

class ConfirmDialog extends Component {
	render() {
		const {
			open,
			title = "Confirmação",
			description = "Deseja realizar esta ação?",
			loading = false,
			cancelLabel = "CANCELAR",
			confirmLabel = "CONFIRMAR",
			onCancel,
			onConfirm,
		} = this.props;

		return (
			<Dialog
				open={open}
				aria-labelledby="alert-confirm-dialog-title"
				aria-describedby="alert-confirm-dialog-description"
			>
				<DialogTitle id="alert-confirm-dialog-title">
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-confirm-dialog-description">
						{description}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={onCancel}
						autoFocus
						disabled={loading}
					>
						{cancelLabel}
					</Button>
					<Box sx={{position: 'relative'}}>
						<Button
							onClick={onConfirm}
							color="error"
							disabled={loading}
						>
							{confirmLabel}
						</Button>

						{loading && (
							<CircularProgress
								size={24}
								sx={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									marginTop: '-12px',
									marginLeft: '-12px',
									pointerEvents: "none",
								}}
							/>
						)}
					</Box>
				</DialogActions>
			</Dialog>
		)
	}
}

export default ConfirmDialog;