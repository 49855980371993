import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import {
	Button,
	MenuItem,
	Paper,
	Select, Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import SaveButton from "../save-button/save-button";


export default class ConnectorFormInsights extends Component {
	state = {
		configuration: this.props.configuration,
		projects: this.props.projects || [],
		selectedProject: null,
		authorizations: [],
		waitingPowerBiRegistration: false,
	}

	componentDidMount() {
		this.onConnectorPropChange("application_insights_api_key", "hjl4c2l243j5vo3cp2bxsyeqwfn8bbzlzob2ak38");
		this.onConnectorPropChange("application_insights_id", "b5566827-f4c8-45a2-856d-a96d79a86796");
		this.onConnectorPropChange("application_insights_key", "04271eef-c838-451f-addb-a5b315097733");
		this.onConnectorPropChange("workspace", "da7654c7-3859-4561-a634-74958f3e5e6a");
		this.onConnectorPropChange("report", "c55df635-b82b-4c4c-bab4-158944540055");
		this.loadAuthorizations();
	}

	loadAuthorizations = () => {
		const {
			projects,
		} = this.state;
		const {
			connectorUid
		} = this.props;

		if (projects.length > 0) {
			const projectsFound = projects.filter(p => p.connectors.find(c => c.uid === connectorUid) !== undefined);
			if (projectsFound.length > 0) {
				this.setState({
					authorizations: projectsFound.map(p => ({
						role: "Viewer",
						references: [{
							type: "Project",
							reference_uid: p.uid
						}]
					}))
				})
			}
		}
	}

	onConnectorPropChange(prop, val) {
		var configuration = this.state.configuration;
		configuration[prop] = val;

		this.setState({
			"configuration": configuration
		});

		this.props.onChange(configuration);
	}

	onSelectProject = (e) => {
		this.setState({
			selectedProject: this.state.projects.find(p => p.uid === e.target.value)
		})
	}

	onAddProject = () => {
		if (this.state.selectedProject) {
			const {
				authorizations,
				configuration,
			} = this.state;
			authorizations.push({
				role: "Viewer",
				references: [{
					type: "Project",
					reference_uid: this.state.selectedProject.uid
				}]
			});
			this.setState({
				authorizations,
				selectedProject: null
			});
			configuration.authorizations = authorizations;
			this.props.onChange(configuration);
		}
	}

	getProjectByUid = (uid) => {
		return this.state.projects.find(p => p.uid === uid);
	}

	notAuthorized = (project) => {
		return !this.state.authorizations.find(a => a.references.find(r => r.reference_uid === project.uid));
	}

	handleSendPowerBiRegistration = () => {
		if (this.state.waitingPowerBiRegistration) {
			return;
		} else {
			this.setState({
				waitingPowerBiRegistration: true
			});
		}
		this.props.onSendPowerBiRegistration({
			connectorUid: this.props.connectorUid,
			authorizations: this.state.authorizations,
			workspace: this.state.configuration.workspace,
			report: this.state.configuration.report,
			scope_uid: this.props.directoryUid,
		}).then(() => {
			this.setState({
				waitingPowerBiRegistration: false
			});
		}).catch(() => {
			this.setState({
				waitingPowerBiRegistration: false
			});
		});
	}

	render() {
		var configuration = this.state.configuration;
		return <div className="rdp-super-admin-component-connector-form-insights">
			{this.state.configuration &&
				<>
					<TextField
						required
						label="Application Insights API Key"
						style={{width: "100%", marginTop: "15px"}}
						value={configuration.application_insights_api_key || ""}
						onChange={(e) => this.onConnectorPropChange("application_insights_api_key", e.target.value)}
					/>
					<TextField
						required
						label="Application Insights ID"
						style={{width: "100%", marginTop: "15px"}}
						value={configuration.application_insights_id || ""}
						onChange={(e) => this.onConnectorPropChange("application_insights_id", e.target.value)}
					/>
					<TextField
						required
						label="Application Insights Key"
						style={{width: "100%", marginTop: "15px"}}
						value={configuration.application_insights_key || ""}
						onChange={(e) => this.onConnectorPropChange("application_insights_key", e.target.value)}
					/>
					<TextField
						required
						label="Power BI workspace UID"
						style={{width: "100%", marginTop: "15px"}}
						defaultValue="da7654c7-3859-4561-a634-74958f3e5e6a"
						onChange={(e) => this.onConnectorPropChange("workspace", e.target.value)}
					/>
					<TextField
						required
						label="Power BI report UID"
						style={{width: "100%", marginTop: "15px"}}
						defaultValue="c55df635-b82b-4c4c-bab4-158944540055"
						onChange={(e) => this.onConnectorPropChange("report", e.target.value)}
					/>

					<Stack direction="row" spacing={2} alignItems="center">
						<FormControl fullWidth sx={{mt: "15px"}}>
							<InputLabel id="project-uid-select-label">Projeto</InputLabel>
							<Select
								id="project-uid-select"
								label="Projeto"
								labelId="project-uid-select-label"
								value={this.state.selectedProject ? this.state.selectedProject.uid : ""}
								onChange={this.onSelectProject}
							>
								{this.state.projects.filter(this.notAuthorized).map((project) => (
									<MenuItem key={project.uid} value={project.uid}>{project.name}</MenuItem>
								))}
							</Select>
						</FormControl>

						<div>
							<Button
								variant="contained"
								color="success"
								sx={{mt: "15px"}}
								onClick={this.onAddProject}
								disabled={!this.state.selectedProject}
							>
								<Add/>
							</Button>
						</div>
					</Stack>

					<Typography variant="h6" gutterBottom sx={{marginTop: "15px"}}>
						Projetos autorizados
					</Typography>

					<TableContainer component={Paper}>
						<Table sx={{width: "100%"}} size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell>Nome</TableCell>
									<TableCell align="center">Role</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.authorizations.map((row, index) => (
									<TableRow
										key={index}
										sx={{'&:last-child td, &:last-child th': {border: 0}}}
									>
										<TableCell component="th" scope="row">
											{this.getProjectByUid(row.references[0].reference_uid).name}
										</TableCell>
										<TableCell align="center">{row.role}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					{this.props.onSendPowerBiRegistration && (
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={1}
							sx={{marginTop: "15px"}}
						>
							<SaveButton
								type="button"
								loading={this.state.waitingPowerBiRegistration}
								onClick={() => this.handleSendPowerBiRegistration()}
								startIcon={<></>}
							>
								Autorizar Power BI
							</SaveButton>
						</Stack>
					)}

				</>
			}
		</div>
	}
}