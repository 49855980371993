import $ from "jquery";
import jwtDecode from 'jwt-decode';

var userService = function() {
  this.getUserDecoded = function() {
    if (window.localStorage.getItem("rdp-composer-user-data") !== null) {
      return jwtDecode(JSON.parse(window.localStorage.getItem("rdp-composer-user-data")).access_token);
    } else {
      return null;
    }
  }

  this.getUser = function() {
    return JSON.parse(window.localStorage.getItem(`rdp-super-admin-user-token-${window.location.host}`));
  }

  this.setUser = function(data) {
    window.localStorage.setItem(`rdp-super-admin-user-token-${window.location.host}`, JSON.stringify(data));
  }

  this.setUserInfo = function(data) {
    window.localStorage.setItem(`rdp-super-admin-user-token-${window.location.host}`, JSON.stringify(data));  
  }

  this.getUserInfo = function(data) {
    return JSON.parse(window.localStorage.getItem(`rdp-super-admin-user-token-${window.location.host}`));
  }

  this.getProjects = function() {
    return JSON.parse(window.localStorage.getItem("rdp-composer-projects"));
  }

  this.setProjects = function(data) {
    window.localStorage.setItem("rdp-composer-projects", JSON.stringify(data));  
  }

  this.getActiveProject = function() {
    return JSON.parse(window.localStorage.getItem("rdp-composer-active-project"));
  }

  this.setActiveProjectLocal = function(data) {
    window.localStorage.setItem("rdp-composer-active-project", JSON.stringify(data));
  }

  this.loadUserInfo = async function(uid, type, accessToken) {
    var _this = this;
    var data = await new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/user/me`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production"
        }
      }).then(function(data) {
      
        resolve(data)
      }, function(error) {
        reject(error)
      });
    });

    _this.setUserInfo(data);
  }

  this.loadProjects = async function(uid, type, accessToken) {
    var _this = this;
    var data = await new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/projects`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production"
        }
      }).then(function(data) {
      
        resolve(data)
      }, function(error) {
        reject(error)
      });
    });

    _this.setProjects(data);
    _this.setActiveProject(data[0].uid, type, accessToken);
  }

  this.setActiveProject = function(uid, type, accessToken) {
    var _this = this;
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/project/uid/${uid}`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production"
        }
      }).then(function(data) {
        _this.setActiveProjectLocal(data);
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.userCreateProperty = function (userUid, property) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/user/uid/${userUid}/management/property`,
        type: "POST",
        dataType: "json",
        data: JSON.stringify(property),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production"
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.userUpdateProperty = function (propertyUid, property) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/user/management/property/uid/${propertyUid}`,
        type: "PUT",
        dataType: "json",
        data: JSON.stringify(property),
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production"
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.userProperties = function (userUid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/user/uid/${userUid}/management/property`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production"
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.deleteProperty = function (propertyUid) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/${window.rdp_api_version}/user/management/property/uid/${propertyUid}`,
        type: "DELETE",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-directory-token-" + window.location.host)).access_token}`,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production"
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  return this;
}

var UserService = new userService();

export {
  UserService
};
