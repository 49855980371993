import $ from "jquery";
import { UserService } from "./user";

var damService = function() {
  this.searchContent = function(filter, q, type, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/storage/${window.rdp_api_version}/search?filter=${filter}&top=1000&orderBy=last_modified desc${q}`,
        // &q=estrondosos
        type: "GET",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key" : window.rdp_project_key,
          "Api-Env" : window.localStorage.getItem("rdp-super-admin-environment") || "production"
        }
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getContent = function(id, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/storage/${window.rdp_api_version}/content?uid=${id}&access_token=${accessToken}&project_key=${window.rdp_project_key}&api_env=${window.localStorage.getItem("rdp-super-admin-environment") || "production"}`,
        type: "GET"
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.removeContent = function(id, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/storage/${window.rdp_api_version}/content?uid=${id}&access_token=${accessToken}&project_key=${window.rdp_project_key}&api_env=${window.localStorage.getItem("rdp-super-admin-environment") || "production"}`,
        type: "DELETE"
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getPublicContent = function(uid, accessToken, index) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/storage/${window.rdp_api_version}/content/share/scope/public?uid=${uid}&access_token=${accessToken}&project_key=${window.rdp_project_key}&api_env=${window.localStorage.getItem("rdp-super-admin-environment") || "production"}`,
        type: "POST",
      }).then(function(data) {
        data.index = index;
        resolve(data);
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getTags = function(filter, query, type, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/storage/${window.rdp_api_version}/search/autocomplete/tags?q=${query}`,
        type: "GET",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key" : window.rdp_project_key,
          "Api-Env" : window.localStorage.getItem("rdp-super-admin-environment") || "production"
        }
      }).then(function(data) {
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  this.downloadScormPackage = function(contentUid, accessToken) {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/storage/${window.rdp_api_version}/content/convert/format/scorm?uid=${contentUid}`,
        type: "GET",
        xhrFields:{
          responseType: 'blob'
        },
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Project-Key" : window.rdp_project_key,
          "Api-Env" : window.localStorage.getItem("rdp-super-admin-environment") || "production"
        }
      }).then(function(blob) {
        resolve(blob)    
      }, function(error) {
        reject(error)
      });
    })
  }

  this.getGoogleFonts = function() {
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBT9ObyYug-k4tOVB01qObA_xd4dOWrof8`,
        type: "GET"
      }).then(function(data) {
        resolve(data.items);
      }, function(error) {
        reject(error)
      });
    })
  }

  this.updateFile = function (contentTags, contentInfo, contentFilters, uid, type, accessToken) {
    var body = {
      content_info: [
        `file_name=${
          contentInfo.file_name !== undefined ? contentInfo.file_name : ""
        }`,
        `file_description=${
          contentInfo.file_description !== undefined
            ? contentInfo.file_description
            : ""
        }`,
        `file_cognitive_description=${
          contentInfo.file_cognitive_description !== undefined
            ? contentInfo.file_cognitive_description
            : ""
        }`,
        `file_copyright=${
          contentInfo.file_copyright !== undefined
            ? contentInfo.file_copyright
            : ""
        }`,
      ],
      content_filters: [
        `file_author=${
          contentFilters.file_author !== undefined
            ? contentFilters.file_author
            : ""
        }`,
        `file_location=${
          contentFilters.file_location !== undefined
            ? contentFilters.file_location
            : ""
        }`,
        `file_language=${
          contentFilters.file_language !== undefined
            ? contentFilters.file_language
            : ""
        }`,
      ],
      content_tags: contentTags
    };

    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/storage/${window.rdp_api_version}/content/metadata?manual_project_uid=${UserService.getActiveProject().uid}&uid=${uid}`,
        type: "PUT",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": window.rdp_project_key,
          "Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
        },
        contentType: false,
        dataType: "json",
        data: JSON.stringify(body),
      }).then(function () {
        resolve();
      }, function (error) {
        reject(error);
      });
    });
  };

  return this;
}

var DamService = new damService();

export {
  DamService
};

