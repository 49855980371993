import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {CircularProgress, StepConnector, stepConnectorClasses, StepLabel} from "@mui/material";
import {styled} from '@mui/material/styles';
import {AdminPanelSettings, Apartment, Dns, HomeWork, Palette, People} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {DirectoriesService} from "../../services/directories";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import $ from "jquery";
import ConnectorFormCustomization from "../connector-form-customization/connector-form-customization";


const ColorlibConnector = styled(StepConnector)(({theme}) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				'linear-gradient( 136deg, rgb(70 26 91 / 30%) 0%, rgb(141 53 183) 50%, rgb(70 26 91) 100%);',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				'linear-gradient( 136deg, rgb(70 26 91 / 30%) 0%, rgb(141 53 183) 50%, rgb(70 26 91) 100%);',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundImage:
			'linear-gradient( 136deg, rgb(70 26 91 / 30%) 0%, rgb(141 53 183) 50%, rgb(70 26 91) 100%);',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
		backgroundImage:
			'linear-gradient( 136deg, rgb(70 26 91 / 30%) 0%, rgb(141 53 183) 50%, rgb(70 26 91) 100%);',
	}),
}));

function ColorlibStepIcon(props) {
	const {active, completed, className} = props;

	const icons = {
		1: <Apartment/>,
		2: <AdminPanelSettings/>,
		3: <Dns/>,
		4: <Palette/>,
	};

	return (
		<ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

const steps = [
	'Nome do projeto',
	'Usuário administrador',
	'Adicionar subdomínio',
	'Personalizar projeto',
];

export default function NewDefaultEnviroment(props) {
	const {
		open,
		onClose,
		onCancel,
		enableSkip
	} = props;
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [formData, setFormData] = React.useState({});
	const [respStep, setRespStep] = React.useState({});
	const submitBtnRef = React.useRef(null);
	const [tempUser, setTempUser] = React.useState(null);
	const [infoData, setInfoData] = React.useState({});
	const [userValues, setUserValues] = React.useState({});
	const [connectorsSchemas, setConnectorsSchemas] = React.useState([]);

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed,
			    // find the first step that has been completed
				steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleComplete = async () => {
		if (submitBtnRef.current) {
			submitBtnRef.current.click();
		}
	};

	const handleConfigDomain = async () => {
		await DirectoriesService.setProjectDomain(respStep[0].project.uid, {
			"domain": formData[activeStep].domain
		});
	}

	const handleConfigUser = async () => {
		var userData = {"detail": {}};
		if (userValues.temp_user_login) userData.login = userValues.temp_user_login;
		if (userValues.temp_user_detail_email_principal) userData.detail.email_principal = userValues.temp_user_detail_email_principal;
		if (userValues.temp_user_detail_first_name) userData.detail.first_name = userValues.temp_user_detail_first_name;
		if (userValues.temp_user_detail_last_name) userData.detail.last_name = userValues.temp_user_detail_last_name;
		if (userValues.temp_user_password) {
			userData.password = userValues.temp_user_password;
			userData.password_confirm = userValues.temp_user_password;
		}

		if (JSON.stringify(userData.detail) === "{}") delete userData.detail;

		await DirectoriesService.userUpdate(tempUser.uid, userData);
	}

	const handleCustomizeProject = async () => {
		await DirectoriesService.setProjectConnector(respStep[0].project.uid, formData[activeStep].connector);
	}

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	const checkIfHasRole = (role) => {
		let has = false;
		let userRoles = tempUser.roles;

		for (let i = 0; i < userRoles.length; i++) {
			if (role.type === userRoles[i].type) {
				has = true;
			}
		}

		return has;
	}

	const checkIfHasProject = (project) => {
		let has = false;
		let userProjects = tempUser.projects;

		for (let i = 0; i < userProjects.length; i++) {
			if (project.uid === userProjects[i].uid) {
				has = true;
			}
		}

		return has;
	}

	const handleProject = async (checked, project) => {
		let userUid = tempUser.uid;
		setTempUser(null);
		if (!checked) {
			await DirectoriesService.setUserProject(project.uid, userUid, this.state.directory.uid);
			let userData = await DirectoriesService.getDirectoryUser(userUid);
			setTempUser(userData);
		}
	}

	const handleRole = async (checked, role) => {
		let userUid = tempUser.uid;

		if (checked) {
			var roleUid;
			let userRoles = this.state.temp_user.roles;
			for (let i = 0; i < userRoles.length; i++) {
				if (userRoles[i].type === role.type) {
					roleUid = userRoles[i].uid;
				};
			};

			await DirectoriesService.removeRole(userUid, roleUid);

			let userData = await DirectoriesService.getDirectoryUser(userUid);
			setTempUser(userData);
		} else {
			let obj = {
				"type": role.type
			};

			await DirectoriesService.setRole(userUid, obj);

			let userData = await DirectoriesService.getDirectoryUser(userUid);
			setTempUser(userData);
		}
	}

	const renderStep = (step) => {
		switch (step) {
			case 0:
				return (
					<FormControl fullWidth>
						<TextField
							required
							label="Nome"
							fullWidth
							onChange={(e) => onStepFormChange(step, {field: "name", value: e.target.value})}
							value={formData[step] ? formData[step].name : ""}
						/>
					</FormControl>
				)
			case 1:
				return (
					<>
						{tempUser === null &&
							<div style={{ width: "500px", textAlign: "center"}}>
								<CircularProgress disableShrink/>
							</div>
						}
						{
							tempUser !== null && (
								<FormControl fullWidth style={{ width: "500px" }}>
									<TextField
										required
										id="outlined-required"
										label="Login"
										style={{ width: "100%", marginBottom: "15px" }}
										defaultValue={tempUser.login}
										onChange={(e) => onTempUserPropChange("temp_user_login", e.target.value)}
										inputProps={{
											form: {
												autocomplete: 'off',
											},
										}}
									/>
									<TextField
										required
										id="outlined-required"
										label="E-mail"
										style={{ width: "100%", marginBottom: "15px" }}
										defaultValue={tempUser.detail.email_principal}
										onChange={(e) => onTempUserPropChange("temp_user_detail_email_principal", e.target.value)}
										inputProps={{
											form: {
												autocomplete: 'off',
											},
										}}
									/>
									<TextField
										required
										id="outlined-required"
										label="Nome"
										style={{ width: "100%", marginBottom: "15px" }}
										defaultValue={tempUser.detail.first_name}
										onChange={(e) => onTempUserPropChange("temp_user_detail_first_name", e.target.value)}
										inputProps={{
											form: {
												autocomplete: 'off',
											},
										}}
									/>
									<TextField
										required
										id="outlined-required"
										label="Sobrenome"
										name="last_name"
										autoComplete="off"
										style={{ width: "100%", marginBottom: "15px" }}
										defaultValue={tempUser.detail.last_name}
										onChange={(e) => onTempUserPropChange("temp_user_detail_last_name", e.target.value)}
										inputProps={{
											form: {
												autocomplete: 'off',
											},
										}}
									/>
									<TextField
										required
										id="outlined-required"
										label="Senha"
										type="password"
										name="password"
										autoComplete="off"
										style={{ width: "100%", marginBottom: "15px" }}
										onChange={(e) => onTempUserPropChange("temp_user_password", e.target.value)}
										inputProps={{
											form: {
												autocomplete: 'off',
											},
										}}
									/>
									<FormLabel component="legend">Projetos</FormLabel>
									<FormGroup>
										{infoData.projects.length > 0 && infoData.projects.map((project, index) => (
											<FormControlLabel
												key={index}
												label={project.name}
												control={
													<Switch
														checked={checkIfHasProject(project)}
														onChange={(e) => handleProject(checkIfHasProject(project), project)}
													/>
												}
											/>
										))}
									</FormGroup>

									<FormLabel component="legend" style={{marginTop: "10px"}}>
										Permissões
									</FormLabel>
									<FormGroup>
										{infoData.roles.length > 0 && infoData.roles.map((role, index) => (
											<FormControlLabel
												key={index}
												label={role.type}
												control={
													<Switch
														checked={checkIfHasRole(role)}
														onChange={(e) => handleRole(checkIfHasRole(role), role)}
													/>
												}
											/>
										))}
									</FormGroup>
								</FormControl>
							)
						}
					</>
				)
			case 2:
				if (!formData[step]) {
					let directoryName = respStep[0].directory.name || "";
					directoryName = directoryName.toLowerCase().replace(/ /g, "-");
					onStepFormChange(step, {field: "domain", value: `${directoryName}.euconquisto.com`})
				}

				return (
					<FormControl fullWidth>
						<TextField
							required
							label="Domínio"
							fullWidth
							onChange={(e) => onStepFormChange(step, {field: "name", value: e.target.value})}
							value={formData[step] ? formData[step].domain : ""}
						/>
					</FormControl>
				)
			case 3:
				if (!formData[step]) {
					let schema = connectorsSchemas.find(schema => schema.type === "Customization_1" && schema.service === "Authorization");
					onStepFormChange(step, {field: "connector", value: schema})
				}

				return (
					<ConnectorFormCustomization
						configuration={formData[step] ? formData[step].connector : {}}
						onChange={(configuration) => onStepFormChange(step, {field: "connector", value: configuration})}
						project={respStep[0].project.uid}
						directory={respStep[0].directory.uid}
					/>
				)
		}
	}

	const onStepFormChange = (step, {field, value}) => {
		let newFormFata = {...formData};

		if (formData[step] === undefined) {
			newFormFata[step] = {};
		}

		newFormFata[step][field] = value;

		setFormData(newFormFata);
	}

	const configDirectoryMainUser = async (directory) => {
		const user = await DirectoriesService.getDirectoryUser(directory.uid);
		if (user) {
			const credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host))
			let directoryLoginData = await DirectoriesService.directoryLogin(directory.uid, credentials.login, credentials.password);
			window.localStorage.setItem("rdp-super-admin-directory-token-" + window.location.host, JSON.stringify(directoryLoginData));

			let userLoginData = await DirectoriesService.userVirtualLogin(directory.uid, credentials.login, credentials.password);
			window.localStorage.setItem("rdp-super-admin-user-token-" + window.location.host, JSON.stringify(userLoginData));

			const roles = user.roles || [];
			const annonymousRole = roles.find(role => role.type === "Anonymous");
			if (annonymousRole) {
				await DirectoriesService.removeRole(user.uid, annonymousRole.uid);
			}

			await DirectoriesService.setRole(user.uid, {
				"type": "AdministratorGlobal",
			});

			await DirectoriesService.setRole(user.uid, {
				"type": "ContributorGlobal",
			});
		}
	}

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		if (loading && !props.onCreate) return;
		setLoading(true);

		const newCompleted = completed;

		switch (activeStep) {
			case 0:
				await handleInitialConfig();
				break;
			case 1:
				await handleConfigUser();
				break;
			case 2:
				await handleConfigDomain();
				break;
			case 3:
				await handleCustomizeProject();
				let uid = respStep[0].directory.uid;
				window.location = window.location.origin + window.location.pathname + "#/directory/" + uid;
				break;
			default:
				return null;
		}

		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		setLoading(false);
		handleNext();
	}

	const handleInitialConfig = async () => {
		let resp = null;

		resp = {};

		resp.directory = await DirectoriesService.directoryCreate(formData[activeStep].name);

		const credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host))
		let directoryLoginData = await DirectoriesService.directoryLogin(resp.directory.uid, credentials.login, credentials.password);
		window.localStorage.setItem("rdp-super-admin-directory-token-" + window.location.host, JSON.stringify(directoryLoginData));

		let connectorsSchemas = await DirectoriesService.getConnectorsSchema();

		await configDirectoryMainUser(resp.directory);

		resp.project = await DirectoriesService.createDirectoryProject(resp.directory.uid, formData[activeStep].name);

		await DirectoriesService.onPublicProject(resp.project.uid, resp.directory.uid);

		await addProjectConnectors(
			connectorsSchemas.filter(item => item.authorized_scopes === "Directory, Project, User" || item.authorized_scopes === "Directory, Project" || item.authorized_scopes === "Project"),
			resp.project.uid
		);

		await addDirectoryConnectors(
			connectorsSchemas.filter(item => item.authorized_scopes === "Directory, Project, User" || item.authorized_scopes === "Directory, Project" || item.authorized_scopes === "Directory"),
			resp.directory.uid,
			resp.project.uid
		);

		await DirectoriesService.setUserProject(resp.project.uid, resp.directory.uid, resp.directory.uid);

		let newInfo = await DirectoriesService.getDirectoryInfo(resp.directory.uid);

		let userData = await DirectoriesService.getDirectoryUser(resp.directory.uid);

		setRespStep({...respStep, [activeStep]: resp});
		setInfoData(newInfo);
		setTempUser(userData);
		setConnectorsSchemas(connectorsSchemas);
	}

	const addProjectConnectors = async (connectorsSchemas, uid) => {
		let composerSchema = connectorsSchemas.find(item => item.type === "Composer_1" && item.service === "Storage");

		await DirectoriesService.setProjectConnector(uid, composerSchema);

		let reviewSchema = connectorsSchemas.find(item => item.type === "Review_1" && item.service === "ContentManager");

		await DirectoriesService.setProjectConnector(uid, reviewSchema);

		let directRegisterSchema = connectorsSchemas.find(item => item.type === "DirectRegister_1" && item.service === "Authorization");

		await DirectoriesService.setProjectConnector(uid, {
			...directRegisterSchema,
			"url_callback": "https://app.euconquisto.com",
			"validation_schema": {},
			"schema_mail_recovery": {
				"template_id": "d-336b77aefc2246b8aa82f75358a1c7ec",
				"from_mail": "noreply@euconquisto.com",
				"from_name": "EuConquisto",
				"sendgrid_api_key": "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",
			},
			"schema_mail_confirm": {
				"template_id": "d-4aa7eb65dfca4570bb9f042cd82b3345",
				"from_mail": "noreply@euconquisto.com",
				"from_name": "EuConquisto",
				"sendgrid_api_key": "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",
			}
		});

		let userDataSchema = connectorsSchemas.find(item => item.type === "UserData_1" && item.service === "ContentManager" && item.name === "DefaultUserData");

		await DirectoriesService.setProjectConnector(uid, userDataSchema);

		let forumSchema = connectorsSchemas.find(item => item.type === "SocialMedia_1" && item.service === "ContentManager");

		await DirectoriesService.setProjectConnector(uid, forumSchema);

		let learningSchema = connectorsSchemas.find(item => item.type === "Learning_1" && item.service === "ContentManager");

		// TODO: remover quando o conector for atualizado, esta propriedade foi movida para o curso
		if ("ranking_factor" in learningSchema) {
			delete learningSchema.ranking_factor;
		}

		await DirectoriesService.setProjectConnector(uid, {
			...learningSchema,
			"allow_with_empty_authorizations": true,
		});

		let sendGridSchema = connectorsSchemas.find(item => item.type === "UserCommunication_1" && item.service === "Communication" && item.name === "SendGrid");

		await DirectoriesService.setProjectConnector(uid, {
			...sendGridSchema,
			"api_key": "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",
			"origin_email": "noreply@euconquisto.com",
			"origin_name": "EuConquisto.com",
			"templates": {
				"NewUserInProject": "d-4aa7eb65dfca4570bb9f042cd82b3345",
				"UserResetPassword": "d-336b77aefc2246b8aa82f75358a1c7ec"
			},
			"default_variables": {
				"player_url": "https://app.euconquisto.com",
			}
		});

		let notificationSchema = connectorsSchemas.find(item => item.type === "UserCommunication_1" && item.service === "Communication" && item.name === "Notificações");

		await DirectoriesService.setProjectConnector(uid, {
			...notificationSchema,
			"templates": [
				{
					"title": "title",
					"description": "description",
					"annotation":"annotation",
					"action_type":"Custom"
				}
			],
			"authorized_roles": [
				4, // Reviewer
			],
		});

		let scormSchema = connectorsSchemas.find(item => item.type === "Custom" && item.service === "Storage" && item.class.search("ConnectorScorm_1") !== -1);

		await DirectoriesService.setProjectConnector(uid, {
			...scormSchema,
		});
	}

	const addDirectoryConnectors = async (connectorsSchemas, uid, projectUid) => {
		try {

			let insightsSchema = connectorsSchemas.find(item => item.type === "Insights_1" && item.service === "Insights" && item.application_insights_api_key === null);

			insightsSchema = {
				...insightsSchema,
				"application_insights_api_key": "hjl4c2l243j5vo3cp2bxsyeqwfn8bbzlzob2ak38",
				"application_insights_id": "b5566827-f4c8-45a2-856d-a96d79a86796",
				"application_insights_key": "04271eef-c838-451f-addb-a5b315097733",
			}

			let connector = await DirectoriesService.setDirectoryConnector(uid, insightsSchema);

			await sendPowerBiRegistration({
				connectorUid: connector.uid,
				scope_uid: uid,
				"authorizations": [
					{
						"role": "Viewer",
						"references": [
							{
								"type": "Project",
								"reference_uid": projectUid
							}
						]
					}
				],
				"workspace": "da7654c7-3859-4561-a634-74958f3e5e6a",
				"report": "c55df635-b82b-4c4c-bab4-158944540055"
			})
		} catch (error) {
			console.error(error);
		}
	}

	const onTempUserPropChange = (prop, val) => {
		let newUserValues = {...userValues};
		newUserValues[prop] = val;
		setUserValues(newUserValues);
	}

	const sendPowerBiRegistration = (args) => {
		const {
			connectorUid,
			authorizations,
			workspace,
			report,
			scope_uid
		} = args;

		const data = {
			"name": "Visão Geral",
			"description": "Dados de uso",
			"type": "Learning_1",
			"workspace": workspace,
			"report": report,
			"scope_uid": scope_uid,
			"scope_type": "Directory",
			"authorizations": authorizations,
		};

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				$.ajax({
					url: `https://${window.rdp_domain}/insights/v1.1/report/config/uid/${connectorUid}`,
					type: "POST",
					processData: false,
					data: JSON.stringify(data),
					headers: {
						"Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("rdp-super-admin-token-" + window.location.host)).access_token}`,
						"Project-Key": window.rdp_project_key,
						"Api-Env": window.localStorage.getItem("rdp-super-admin-environment") || "production",
					},
				}).then(resolve, reject);
			}, 10000);
		});
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			scroll={"paper"}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			maxWidth="xl"
		>
			<DialogTitle id="scroll-dialog-title">Novo ambiente padrão</DialogTitle>
			<DialogContent dividers>
				<Box sx={{width: '100%'}}>
					<Stepper
						alternativeLabel
						nonLinear
						activeStep={completed[activeStep] ? completed[activeStep] : activeStep}
	          connector={<ColorlibConnector/>}
					>
						{steps.map((label, index) => (
							<Step key={label} completed={completed[index]}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Box>
				<Box sx={{width: '100%', mt: 3}}>
					<Stack spacing={3} direction="column" alignItems="center">
						<form
							onSubmit={handleSubmit}
							style={{width: '100%'}}
							autoComplete="off"
						>
							{renderStep(activeStep)}
							<button
								ref={submitBtnRef}
								type="submit"
								style={{display: 'none'}}
							/>
						</form>
					</Stack>
				</Box>
			</DialogContent>
			<DialogActions>
				{allStepsCompleted() ? (
					<>
						<Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
							<Box sx={{flex: '1 1 auto'}}/>
							<Button onClick={handleReset}>Redefinir</Button>
						</Box>
					</>
				) : (
					<>
						<Box sx={{display: 'flex', flexDirection: 'row', pt: 2, justifyContent: "space-between", width: "100%"}}>
							<Button
								color="inherit"
								disabled={activeStep === 0}
								onClick={handleBack}
								sx={{mr: 1}}
							>
								Voltar
							</Button>

							<Stack direction="row" spacing={1}>
								{
									enableSkip && (
										<Button onClick={handleNext} sx={{mr: 1}} disabled={loading}>
											Pular
										</Button>
									)
								}
								<Box sx={{position: 'relative'}}>
									{activeStep !== steps.length &&
										(!completed[activeStep] && (
											<Button onClick={handleComplete} variant="contained" disabled={loading}>
												{completedSteps() === totalSteps() - 1
													? 'Finalizar'
													: 'Completar Etapa'}
											</Button>
										))}

									{loading && (
										<CircularProgress
											size={24}
											sx={{
												position: 'absolute',
												top: '50%',
												left: '50%',
												marginTop: '-12px',
												marginLeft: '-12px',
												pointerEvents: "none",
											}}
										/>
									)}
								</Box>
							</Stack>
						</Box>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
}
