import {createTheme} from "@mui/material/styles";

let theme = createTheme({
	palette: {
		primary: {
			main: "#8e35b7",
		},
		secondary: {
			main: "#ff5b00",
		},
	},
});

theme = createTheme(theme, {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					"&:disabled": {
						cursor: "not-allowed"
					}
				},
			},
		},
	},
});

export default theme;