import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

export const ImageContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 600px;
  cursor: pointer;
  padding: 10px;
  border: 1px dashed #ccc;
  border-radius: 8px;
  background-color: #eee;
  position: relative;
	transition: all 2s ease-in-out, opacity 0.1s ease-in-out, background-color 0.1s ease-in-out;

  &:hover {
    border-color: #999;
    background-color: #ddd;
  }
	
	&:active {
    opacity: 0.6;
		cursor: progress;
  }
`;

export const ColorsList = styled.ul`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`;

export const ColorItem = styled.li`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Color = styled.div`
  width: 100px;
  height: 50px;
  background-color: ${props => props.color};
	cursor: pointer;
	border: 2px solid black;
	
	&:hover {
		opacity: 0.8;
  }
	
	&:active {
		opacity: 0.6;
  }
`;

export const ColorName = styled.p`
	user-select: all;
	font-weight: bold;
`;

export const Input = styled.input`
	display: none;
`;