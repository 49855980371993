import $ from "jquery";

var loginService = function () {
  this.login = function (login, password, environment) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: `https://${window.rdp_domain}/auth/v1.0/signin/uid`,
        type: "POST",
        dataType: "json",
        data: JSON.stringify({
          login: login,
          password: password,
        }),
        headers: {
          "Project-Key": window.rdp_project_key,
          "Api-Env": environment,
        },
      }).then(
        function (data) {
          window.localStorage.setItem("rdp-super-admin-token-" + window.location.host, JSON.stringify(data));
          resolve(data);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  this.logout = function () {
    return new Promise(function (resolve, reject) {
      window.localStorage.removeItem("rdp-dam-user-data");
      window.localStorage.removeItem("rdp-dam-projects");
      window.localStorage.removeItem("rdp-dam-active-project");
      window.localStorage.removeItem("rdp-dam-data");
      resolve({ success: "ok" });
    });
  };

  return this;
};

var LoginService = new loginService();

export { LoginService };
