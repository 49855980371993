import React, {Component} from 'react';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {Chip, ListItem, Select} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import {Add, Delete} from "@mui/icons-material";
import Box from "@mui/material/Box";

class SchemaFormFields extends Component {
	state = {
		tags: this.props.values.tags || [],
		properties: this.props.values.properties || [],
		tag: "",
		errors: {},
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.values !== prevProps.values) {
			this.setState({
				tags: this.props.values.tags || [],
				properties: this.props.values.properties || [],
			});
		}
	}

	getFieldValue = (fieldName) => {
		return this.props.values[fieldName] || "";
	}

	addProperty = () => {
		this.setState({
			properties: [
				...this.state.properties,
				{
					name: "",
					regex: "",
					type: "text",
				}
			]
		});
	}

	removeProperty = (index) => {
		this.setState({
			properties: this.state.properties.filter((_, i) => i !== index)
		});
	}

	removeTag = (index) => {
		let removedTag = this.state.tags[index];
		if (this.props.onDeleteTag) {
			this.props.onDeleteTag(removedTag);
		}
		this.setState({
			tags: this.state.tags.filter((_, i) => i !== index)
		});
	}

	addTag = () => {
		if (this.state.tag.length) {
			let newErrors = {...this.state.errors};

			delete(newErrors.tag);

			if (this.props.onCreateTag) {
				this.props.onCreateTag({
					name: this.state.tag,
				});
			}

			this.setState({
				tags: [
					...this.state.tags,
					{
						uid: null,
						name: this.state.tag,
					}
				],
				tag: "",
				errors: newErrors
			});
		} else {
			this.setState({
				errors: {...this.state.errors, tag: "Preencha este campo para adicionar uma tag"}
			})
		}
	}

	handleTagNameChange = (event) => {
		let newErrors = {...this.state.errors};

		if (this.state.errors.tag) {
			delete(newErrors.tag);
		}

		this.setState({tag: event.target.value, errors: newErrors})
	}

	render() {
		return (
			<div>
				<FormControl
					fullWidth
					sx={{minWidth: "500px"}}
				>
					<TextField
						required
						label="Nome"
						name="name"
						sx={{width: "100%", marginBottom: "15px"}}
						defaultValue={this.getFieldValue("name")}
						disabled={this.props.editForm}
					/>
				</FormControl>

				<FormControl
					fullWidth
					sx={{minWidth: "500px", marginBottom: "15px"}}
					required
				>
					<InputLabel id="schema-type-label">Tipo</InputLabel>
					<Select
						labelId="schema-type-label"
						defaultValue={this.getFieldValue("type").toLowerCase()}
						label="Tipo"
						name="type"
						required
						disabled={this.props.editForm}
					>
						<MenuItem value="category">Category</MenuItem>
						<MenuItem value="security">Security</MenuItem>
					</Select>
				</FormControl>

				<FormControl
					fullWidth
					sx={{minWidth: "500px", marginBottom: "15px"}}
				>
					<TextField
						required
						label="Limitar visibilidade da entidade sobre seus filhos"
						name="limit_depth_in_hierarchy"
						type="number"
						fullWidth
						defaultValue={this.getFieldValue("limit_depth_in_hierarchy")}
						disabled={this.props.editForm}
					/>
				</FormControl>

				<Typography component="p" sx={{marginBottom: "15px"}}>
					Tags
				</Typography>

				<Stack spacing={2} direction="row">
					<FormControl
						fullWidth
						sx={{minWidth: "500px", marginBottom: "15px"}}
					>
						<TextField
							sx={{width: "100%"}}
							value={this.state.tag}
							label="Nova tag"
							name={this.state.tags.length === 0 ? `tags[0][name]` : undefined}
							onChange={this.handleTagNameChange}
							error={!!this.state.errors.tag}
							helperText={this.state.errors.tag}
						/>
					</FormControl>

					<Box sx={{paddingBottom: "15px"}}>
						<Button
							variant="contained"
							color="success"
							onClick={this.addTag}
							sx={{height: "56px", marginBottom: "15px"}}
						>
							<Add/>
						</Button>
					</Box>
				</Stack>

				<Stack sx={{width: "100%", marginBottom: "15px"}} spacing={2} direction="row">
					{this.state.tags.map((tag, index) => (
						<div key={tag.uid || index}>
							<TextField
								sx={{display: "none"}}
								value={tag.uid || ""}
								name={`tags[${index}][uid]`}
							/>
							<TextField
								sx={{display: "none"}}
								value={tag.name}
								name={`tags[${index}][name]`}
							/>
							<Chip
								key={index}
								label={tag.name}
								variant="outlined"
								onDelete={() => this.removeTag(index)}
							/>
						</div>
					))}
				</Stack>

				<Typography component="p">
					Propriedades
				</Typography>

				<List sx={{width: "100%"}}>
					{this.state.properties.map((property, index) => (
						<ListItem key={index}>
							<Stack spacing={2} direction="row" sx={{width: "100%"}}>
								<FormControl
									fullWidth
									sx={{minWidth: "200px", marginBottom: "15px"}}
								>
									<TextField
										required
										label="Nome"
										name={`properties[${index}][name]`}
										fullWidth
										defaultValue={property.name || ""}
										disabled={this.props.editForm}
									/>
								</FormControl>

								<FormControl
									fullWidth
									sx={{minWidth: "200px", marginBottom: "15px"}}
								>
									<TextField
										label="Regex"
										name={`properties[${index}][regex_validation]`}
										fullWidth
										defaultValue={property.regex_validation || ""}
										disabled={this.props.editForm}
									/>
								</FormControl>

								<FormControl
									fullWidth
									sx={{minWidth: "200px", marginBottom: "15px"}}
								>
									<InputLabel id="schema-property-type-label">Tipo</InputLabel>
									<Select
										labelId="schema-property-type-label"
										label="Tipo"
										defaultValue={(property.type || "").toLowerCase()}
										name={`properties[${index}][type]`}
										disabled={this.props.editForm}
									>
										<MenuItem value="text" defaultValue>Texto</MenuItem>
									</Select>
								</FormControl>

								<Box sx={{paddingBottom: "15px"}}>
									<Button
										variant="outlined"
										color="error"
										onClick={() => this.removeProperty(index)}
										sx={{height: "100%", marginBottom: "15px"}}
										disabled={this.props.editForm}
									>
										<Delete/>
									</Button>
								</Box>
							</Stack>
						</ListItem>
					))}

					<ListItem>
						<Stack
							spacing={2}
							direction="row"
							sx={{width: "100%"}}
							justifyContent="center"
						>
							<Button
								variant="contained"
								color="success"
								onClick={this.addProperty}
								disabled={this.props.editForm}
							>
								<Add/> Adicionar propriedade
							</Button>
						</Stack>
					</ListItem>
				</List>

				<FormControl
					fullWidth
					sx={{minWidth: "500px"}}
				>
					<FormControlLabel
						control={
							<Checkbox
								name="public"
								defaultChecked={this.getFieldValue("public")}
								disabled={this.props.editForm}
							/>
						}
						label="Esquema público"
					/>
				</FormControl>
			</div>
		);
	}
}

export default SchemaFormFields;