import React, {Component} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {DirectoriesService} from "../../services/directories";
import {RDPUtils} from "../../utils";
import './directories.css';

import {Header, Preloader} from '../../components';
import Stack from "@mui/material/Stack";
import {Bolt, DeleteOutlineTwoTone, Folder} from "@mui/icons-material";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import NewDefaultEnviroment from "../../components/new-default-enviroment/new-default-enviroment";
import {Backdrop, Box, SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';


const actions = [
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
];

export default class ViewDirectories extends Component {
  constructor(props) {
    super(props);

    this.openDirectoryCreateModal = this.openDirectoryCreateModal.bind(this);
    this.closeDirectoryCreateModal = this.closeDirectoryCreateModal.bind(this);
    this.handleOpenSpeedDial = this.handleOpenSpeedDial.bind(this);
    this.handleCloseSpeedDial = this.handleCloseSpeedDial.bind(this);

    this.state = {
      "directories": null,
      "new_directory_name": null,
      "render_directory_create_modal": false,
      "render_poc_create_modal": false,
      "render_modal": {
        "create_directory": false,
        "create_poc": false,
      },
      "loading": false,
      "dialog_open": {
        "delete_directory": false,
      },
      "dialog_data": null,
      "dialog_loading": false,
      "open_speed_dial": false,
    }
  }

  componentWillMount() {
    console.log(RDPUtils);
    this.redirectToRoute();
    this.getDirectories();
  }

  redirectToRoute() {
    var session = window.localStorage.getItem("rdp-super-admin-token-" + window.location.host);
    if (session === null) {
      window.location = window.location.origin + window.location.pathname + "#/";
    }
  }

  getDirectories() {
    var _this = this;
    DirectoriesService.list().then(function (directories) {
      _this.setState({
        "directories": directories
      });
    }, function(error) {
      window.localStorage.removeItem("rdp-super-admin-token-" + window.location.host);
      _this.redirectToRoute();
    });
  }

  openDirectoryCreateModal() {
    this.setState({
      "render_directory_create_modal": true
    });
  };

  openModal(modal) {
    this.setState({
      "render_modal": {
        ...this.state.render_modal,
        [modal]: true
      }
    });
  };

  closeModal(modal) {
    this.setState({
      "render_modal": {
        ...this.state.render_modal,
        [modal]: false
      }
    });
  };

  closeDirectoryCreateModal() {
    this.setState({
      "render_directory_create_modal": false
    });
  };

  onDirectoryNameChange(val) {
    this.setState({
      "new_directory_name": val === "" ? null : val
    })
  }

  async createDirectory() {
    this.setState({
      "directories": null
    })
    if (this.state.new_directory_name) {
      var directoryObj = await DirectoriesService.directoryCreate(this.state.new_directory_name);
      await this.configDirectoryMainUser(directoryObj);
      this.closeDirectoryCreateModal();
      this.getDirectories();
    }
  }

  handleOpenSpeedDial() {
    this.setState({
      "open_speed_dial": true,
    })
  }

  handleCloseSpeedDial() {
    this.setState({
      "open_speed_dial": false,
    })
  }

  getEnvironmentName() {
    return window.localStorage.getItem("rdp-super-admin-environment");
  }

  async configDirectoryMainUser(directory) {
    const user = await DirectoriesService.getDirectoryUser(directory.uid);
    if (user) {
      const credentials = JSON.parse(window.localStorage.getItem("rdp-super-admin-credentials-" + window.location.host))
      var directoryLoginData = await DirectoriesService.directoryLogin(directory.uid, credentials.login, credentials.password);
      window.localStorage.setItem("rdp-super-admin-directory-token-" + window.location.host, JSON.stringify(directoryLoginData));

      var userLoginData = await DirectoriesService.userVirtualLogin(directory.uid, credentials.login, credentials.password);
      window.localStorage.setItem("rdp-super-admin-user-token-" + window.location.host, JSON.stringify(userLoginData));

      const roles = user.roles || [];
      const annonymousRole = roles.find(role => role.type === "Anonymous");
      if (annonymousRole) {
        await DirectoriesService.removeRole(user.uid, annonymousRole.uid);
      }

      await DirectoriesService.setRole(user.uid, {
        "type": "AdministratorGlobal",
      });

      await DirectoriesService.setRole(user.uid, {
        "type": "ContributorGlobal",
      });
    }
  }

  handleOpenDialog(dialog, dialogData) {
    this.setState({
      "dialog_open": {
        ...this.state.dialog_open,
        [dialog]: true,
      },
      "dialog_data": dialogData,
    })
  }

  handleCloseDialog(dialog) {
    this.setState({
      "dialog_open": {
        ...this.state.dialog_open,
        [dialog]: false,
      },
      "dialog_data": null,
    })
  }

  async handleDeleteSubdomain() {
    if (this.state.dialog_loading) return;

    this.setState({
      "dialog_loading": true,
    });

    DirectoriesService.directoryDelete(this.state.dialog_data.uid)
      .then(result => {
        this.setState({
          "dialog_loading": false,
        });
        this.handleCloseDialog("delete_directory");
        this.getDirectories();
      })
      .catch(e => {
        this.setState({
          "dialog_loading": false,
        });
      });
  }

  render() {
    return (
      <>
        <Header/>

        <div className="rdp-super-admin-breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/#/directories"
            >
              Diretórios
            </Link>
          </Breadcrumbs>
        </div>

        <div className="rdp-super-admin-content">
          {this.state.directories === null &&
            <Preloader/>
          }

          {this.state.directories &&
            <>
              <List className="rdp-super-admin-directories-sidebar">
                {RDPUtils.orderAlphabetically(this.state.directories, "name").map((directory, index) => {
                  return <ListItem disablePadding key={index}>
                    <ListItemButton component="a" href={`/#/directory/${directory.uid}`}>
                      <ListItemText primary={directory.name} />
                    </ListItemButton>

                    {this.getEnvironmentName() !== "production" && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => this.handleOpenDialog("delete_directory", directory)}
                        disabled={directory.uid === "f2a57c01-46bf-ec11-997e-dc984041c95d"}
                      >
                        <DeleteOutlineTwoTone color="white" />
                      </Button>
                    )}
                  </ListItem>
                })}

                <ConfirmDialog
                  open={!!this.state.dialog_open.delete_directory}
                  loading={this.state.dialog_loading}
                  title="Exclusão de diretório"
                  description={`Deseja excluír o diretório ${this.state.dialog_data && this.state.dialog_data.name}?`}
                  onCancel={() => this.handleCloseDialog("delete_directory")}
                  onConfirm={() => this.handleDeleteSubdomain()}
                />
              </List>

                <SpeedDial
                  ariaLabel="Ações do diretório"
                  sx={{
                    position: "fixed",
                    right: "20px",
                    bottom: "20px"
                  }}
                  icon={<SpeedDialIcon />}
                  onClose={this.handleCloseSpeedDial}
                  onOpen={this.handleOpenSpeedDial}
                  open={this.state.open_speed_dial}
                >
                  <SpeedDialAction
                    icon={<Folder/>}
                    tooltipTitle="Novo diretório"
                    onClick={() => {
                      this.openModal("create_directory");
                      this.handleCloseSpeedDial();
                    }}
                  />
                  <SpeedDialAction
                    icon={<Bolt/>}
                    tooltipTitle="Novo ambiente padrão"
                    onClick={() => {
                      this.openModal("create_poc");
                      this.handleCloseSpeedDial();
                    }}
                  />
                </SpeedDial>

              <Dialog
                open={this.state.render_modal.create_directory}
                onClose={() => this.closeModal("create_directory")}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">Novo diretório</DialogTitle>
                <DialogContent dividers={true}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="outlined-required"
                      label="Nome do diretório"
                      style={{ width: "400px" }}
                      onChange={(e) => this.onDirectoryNameChange(e.target.value)}
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.closeModal("create_directory")}>Cancelar</Button>
                  <Button onClick={() => this.createDirectory()}>Criar diretório</Button>
                </DialogActions>
              </Dialog>

              <NewDefaultEnviroment
                open={this.state.render_modal.create_poc}
                onClose={() => this.closeModal("create_poc")}
                onCancel={() => this.closeModal("create_poc")}
                loading={this.state.loading}
              />
            </>
          }
        </div>
      </>
    )
  }
}
