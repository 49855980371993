import React, { Component } from 'react';
import {UserService} from '../../services/user';
import './rdp-dam-widget.css';

export default class RDPDamWidget extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onDamSelect = this.onDamSelect.bind(this);
    this.handler = this.handler.bind(this);

    this.state = {
      "images": [],
      "videos": [],
      "audios": [],
      "loading": true,
      "uploading": false,
      "progress": 0
    };
  }

  getProject() {
    var userData = UserService.getUser();
    var type = userData.type;
    var accessToken = userData.access_token;
    return {
      "user_data": userData,
      "active_project": this.props.project,
      "type": type,
      "access_token": accessToken
    };
  }

  onChange(type, asset) {
    this.props.onAssetChange(asset);
  }

  onDamSelect(contentType, uid, data) {
    var path = contentType === "digitalpages/video" ? "content/default/target" : "content";
    var asset = `https://${window.rdp_domain}/storage/${window.rdp_api_version}/${path}?uid=${uid}&project_key=${window.rdp_project_key}&api_env=${window.rdp_api_env}`

    window.removeEventListener("message", this.handler);
    this.props.onAssetChange(asset, data);
  }

  componentDidMount() {
    window.addEventListener("message", this.handler);
  }

  handler(event) {
    if (event.data.content_type !== undefined) {
      this.onDamSelect(event.data.content_type, event.data.uid, event.data);
    }
  }

  getUrl() {
    var based = "https://api.digitalpages.com.br";
    if (window.rdp_domain) based = "https://" + window.rdp_domain;
    return based;
  }


  getCurrentLanguage() {
    return window.localStorage.getItem(window.location.host + "rdp-composer-current-language") || "pt_br"
  }

  getEmbedType() {
    var type = this.props.type;
    if (type === "image") {
      return "images";
    } else if (type === "video") {
      return "videos";
    } else if (type === "audio") {
      return "audios";
    } else if (type === "attachments") {
      return "attachments";
    }
  }

  reloadIframe(e, url) {
    // e.target.src = url;
  }

  render() {
    const url = `${this.getUrl()}/product/dam/current/${window.rdp_project_key}/p/-/${window.rdp_api_env.charAt(0)}/#/auth-with-token/${this.getCurrentLanguage()}/${this.getEmbedType()}/${this.props.project}/${this.getProject().access_token}`;
    return (
      <div className="rdp-dam-widget-iframe-modal">
        <div className="rdp-dam-widget-iframe-overlay" onClick={() => this.props.onClose()}></div>
        <iframe
          className="rdp-dam-widget-iframe"
          src={url}
          onLoad={(e) => this.reloadIframe(e, url)}
        />
      </div>
    );
  }
}
