import React, { Component } from 'react';
import './no-results.css';

export default class NoResults extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { message = "Nenhum resultado encontrado." } = this.props;
    return (
      <div className="rdp-super-admin-component-no-results">
        <span className="rdp-super-admin-component-no-results-icon"></span>
        <span className="rdp-super-admin-component-no-results-message">{message}</span>
      </div>
    );
  }
}
