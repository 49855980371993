var rdpUtils = function () {
  this.orderAlphabetically = function (arr, attr) {
    return arr.sort(function(a, b) {
      if (a[attr] < b[attr]) { return -1; }
      if (a[attr] > b[attr]) { return 1; }
      return 0;
    })
  }

  return this;
}

var RDPUtils = new rdpUtils();

export {
  RDPUtils
};
