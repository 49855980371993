import React, { Component } from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import './reset.css';
import './App.css';

import {
  ViewHome,
  ViewDirectories,
  ViewDirectory,
  ViewProject,
  ViewMigration,
  ViewSchema,
  ViewCourse,
  ViewImageColors,
} from './views';

class App extends Component {
  render() {
    return (
      <div id="root" className="rdp-super-admin-root">
        <Route exact path="/" component={ViewHome}/>
        <Route exact path="/directories" component={ViewDirectories}/>
        <Route exact path="/directory/:uid" component={ViewDirectory}/>
        <Route exact path="/directory/:directoryUid/project/:projectUid" component={ViewProject}/>
        <Route exact path="/directory/:directoryUid/schema/:schemaUid" component={ViewSchema}/>
        <Route exact path="/directory/:directoryUid/project/:projectUid/schema/:schemaUid" component={ViewSchema}/>
        <Route exact path="/directory/:directoryUid/project/:projectUid/course/:courseUid" component={ViewCourse}/>
        <Route exact path="/migration" component={ViewMigration}/>
        <Route exact path="/image-colors" component={ViewImageColors}/>
        <Switch>
          <Redirect from="#/*" to="/" />
        </Switch>
      </div>
    );
  }
}

export default App;
